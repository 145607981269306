import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import Cutlery from '../assets/cutlery.svg';
import { Rating } from 'react-simple-star-rating'
import { useNavigate } from 'react-router-dom';
// Import Swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/pagination';
import { connect } from 'react-redux'
import { withSnackbar } from 'react-simple-snackbar'
import * as actions from '../store/actions/index';
import ReactPaginate from 'react-paginate';
// import required modules
import { Autoplay, Pagination } from 'swiper/modules';


const BusinessImg = [
    {
        id: 1,
        image: require('../assets/business-cleaning.jpg'),
    },
    {
        id: 2,
        image: require('../assets/business-repair.jpg'),
    },
    {
        id: 3,
        image: require('../assets/business-grocery.jpg'),
    },
    {
        id: 4,
        image: require('../assets/business-cafe.jpg'),
    }
];

const EcommerceBusiness = (props) => {
    let city = localStorage.getItem('city');
    let state = localStorage.getItem('state');
    const { getEcomPageBusinessList, eCommBusinessList } = props;
    useEffect(() => {
        document.documentElement.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant", // Optional if you want to skip the scrolling animation
        });
        async function getLocationforBusiness() {
            // You can await here
            let lat = await localStorage.getItem("latitude")
            let long = await localStorage.getItem("longitude")
            let data = {
                latitude: parseFloat(lat),
                longitude: parseFloat(long),
                type: 'ecommerce'
            }
            return await getEcomPageBusinessList(data)
        }
        getLocationforBusiness()
    }, [getEcomPageBusinessList])

    const navigate = useNavigate()
    let token = localStorage.getItem('token')
    let userInfo = JSON.parse(localStorage.getItem('userInfo'))

    const getRequiredAddress = (str, from) => {
        var arr = str.split(',');
        arr.splice(0, from);
        return arr.join(',');
    }
    var days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
    var d = new Date();
    var dayName = days[d.getDay()];
    var dayString = `is_${dayName}_Open`

    const [callDetails, setCallDetails] = useState({
        country_code: '',
        mobile_no: ''
    })
    const [rating, setRating] = useState(0)
    const [ratingComment, setRatingComment] = useState('')
    const [selectedReviewBusiness, setSelectedBusiness] = useState({
        businessName: '',
        businessId: ''
    })

    // pagination functionality start
    const [itemOffset, setItemOffset] = useState(0);
    const endOffset = itemOffset + 9;  //number here represent how many item you want to show on page
    const currentItems = eCommBusinessList.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(eCommBusinessList.length / 9);
    // Invoke when user click to request another page.
    const handlePageClick = (event) => {
        const newOffset = (event.selected * 9) % eCommBusinessList.length;
        setItemOffset(newOffset);
    };
    //pagination functionality end

    const handleCallClick = (business) => {
        setCallDetails({ country_code: business.country_code, mobile_no: business.mobile_no })
        let data = {
            businessId: business._id,
            clickType: 'call'
        }
        props.clickPerCount(data)
    }

    const handleEmailClick = (business) => {
        if (!token) {
            navigate('/login')
        } else {
            let businessInfo = {
                email: business.email,
                businessName: business.name,
                country_code: business.country_code,
                phone_no: business.phone_no,
                businessId: business._id
            }
            navigate(`/send-email-to-business/:${business.name.replaceAll(/ /g, "-").toLowerCase()}`, { state: businessInfo })
        }
    }

    const sendOnWebsite = (business, type, e) => {
        e.preventDefault()
        let data = {
            businessId: business._id,
            clickType: type
        }
        props.clickPerCount(data)
        window.open(`https://${business.website}`, '_blank', 'noopener,noreferrer')
    }

    //send on map page for routes
    const sendOnDirectionPage = (business) => {
        let addr = business.address ? getRequiredAddress(business.address, 3) : business.city
        let businessInfo = {
            destLat: business.latitude,
            desLong: business.longitude,
            businessName: business.name,
            address: addr
        }
        let data = {
            businessId: business._id,
            clickType: 'direction'
        }
        props.clickPerCount(data)
        navigate(`/direction-to-business/${addr.replaceAll(/ /g, "-").toLowerCase()}`, { state: businessInfo })
    }

    const handleBookAnAppointmentClick = (business) => {
        if (!token) {
            navigate('/login')
        } else {
            let businessInfo = {
                email: business.email,
                businessName: business.name,
                country_code: business.country_code,
                phone_no: business.phone_no,
                businessId: business._id
            }
            navigate(`/book-an-appointment/${business.name.replaceAll(/ /g, "-").toLowerCase()}`, { state: businessInfo })
        }
    }

    const checkRatingModal = (business) => {
        if (token !== null) {
            setSelectedBusiness({ businessName: business.name, businessId: business._id })
        } else {
            navigate('/login')
        }
    }

    const submitReview = () => {
        let creatorId = userInfo._id
        let data = {
            rating: rating,
            ratingFor: 'businessWebite',
            comment: ratingComment,
            creator_id: creatorId,
            receiver_id: selectedReviewBusiness.businessId
        }
        props.makeRating(data)
    }
    //handle business details on view details
    const handleBusinessDetails = (event, business) => {
        let addr = business.address ? getRequiredAddress(business.address, 1) : business.city ? business.city : business.name;
        let subcategoryname = business.subCategory.length > 0 ? business.subCategory[0].subCategoryName.replaceAll(/ /g, "-").toLowerCase() : "subcategory"
        navigate(`/business-details/${business.name.replaceAll(/ /g, "-").toLowerCase()}/${subcategoryname}/${addr.replaceAll(/ /g, "-").toLowerCase()}`, { state: { businessId: business._id } })
    }

    // handle request a quote
    const handleRequestAQuoteClick = (business) => {
        if (!token) {
            navigate('/login')
        } else {
            let businessInfo = {
                email: business.email,
                businessName: business.name,
                country_code: business.country_code,
                phone_no: business.mobile_no,
                businessId: business._id
            }
            navigate(`/request-a-quote/${business.name.replaceAll(/ /g, "-").toLowerCase()}`, { state: businessInfo })
        }
    }

    return (
        <div className='nearby-business-sec pt-4 pb-5'>
            <Helmet>
                <title>Top 20 best e-commerce businesses and online services</title>
            </Helmet>
            <div className='container'>
                <div className='section-header mw-100 text-center mb-4'>
                    <div className='section-label fs-3'>Top 20 best e-commerce businesses and online services</div>
                    <div className='section-sublabel text-dark text-opacity-75'>
                        Ready to grow your businesses online? Check out our top e-commerce marketplace for the latest products and online service. Connect with millions of customers from around the world today. Start selling your products with ease and join the thousands of other businesses that have found success with us.
                    </div>
                </div>
                <div className='row gx-3 gy-4'>
                    {currentItems.length > 0 ?
                        currentItems.map((business, index) => (
                            <div className='col-xl-4 col-lg-4 col-md-6' key={index}>
                                <div className='business-details shadow rounded -3 overflow-hidden' key={index}>
                                    <div className='business-img position-relative'>
                                        <Swiper slidesPerView={1} loop={true} modules={[Autoplay, Pagination]} className="mySwiper custom-dots"
                                            autoplay={{ delay: 2500, disableOnInteraction: false }} pagination={{ clickable: true }}
                                        >
                                            {
                                                business.businessPicture && business.businessPicture.length > 0 ?
                                                    business.businessPicture.map((item, idx) => (
                                                        <SwiperSlide><img src={item.pictureUrl} key={idx} className='img-fluid' alt='Cleaning' /></SwiperSlide>
                                                    ))
                                                    :
                                                    BusinessImg.map((item, idx) => (
                                                        <SwiperSlide><img src={item.image} key={idx} className='img-fluid' alt='Cleaning' /></SwiperSlide>
                                                    ))
                                            }
                                        </Swiper>
                                        {/* <div className='discount-tag bg-danger rounded-circle d-flex align-items-center justify-content-center text-white small position-absolute p-2 lh-sm'>20% OFF</div> */}
                                    </div>
                                    <div className="business-info bg-white text-center p-3 pb-4">
                                        <div className='name fs-18 fw-medium text-capitalize mb-1'>{business.name}</div>
                                        <div className='review-wrapper d-flex align-items-center justify-content-center gap-2 mb-1'>
                                            <div className='rating-number'>{business.rating > 0 ? business.rating.toFixed(1) : 5}</div>
                                            <div className='review-star d-flex justify-content-center gap-1'>
                                                {business.rating > 0 ?
                                                    <Rating initialValue={business.rating} size={"20px"} readonly={true} allowFraction={true} />
                                                    :
                                                    <Rating initialValue={5} size={"20px"} readonly={true} />
                                                }
                                            </div>
                                            <div className='rating-number'>{business.ratingCount > 0 ? `(${business.ratingCount})` : '(1)'}</div>
                                        </div>
                                        <div className='name text-muted text-capitalize mb-1'>{business.subCategory.length > 0 ? business.subCategory[0].subCategoryName : ''}</div>
                                        <div className='action d-flex flex-wrap justify-content-center gap-2 mt-2'>
                                            {business.isPhoneSelect ? <button type="button" data-bs-toggle="modal" data-bs-target="#infoDetails" className='bg-white small text-black text-decoration-none border rounded-pill d-flex align-items-center py-2 px-3' onClick={() => handleCallClick(business)}><i className="bi bi-telephone-fill text-primary me-2"></i>Call</button> : ''}
                                            {business.isEmailSelect ? <button type='button' className='bg-white small text-black text-decoration-none border rounded-pill d-flex align-items-center py-2 px-3' onClick={() => handleEmailClick(business)}><i className="bi bi-envelope-fill text-primary me-2"></i>Email</button> : ''}
                                            {business.isWebsiteSelect ? <button type="button" className='bg-white small text-black text-decoration-none border rounded-pill d-flex align-items-center py-2 px-3' onClick={(e) => sendOnWebsite(business, 'website', e)} ><i className="bi bi-globe-americas text-primary me-2"></i>Website</button> : ''}
                                            {business.isDirectionSelect ? <button type="button" className='bg-white small text-black text-decoration-none border rounded-pill d-flex align-items-center py-2 px-3' onClick={() => sendOnDirectionPage(business)}><i className="bi bi-reply-fill text-primary me-2"></i>Directions</button> : ''}
                                            {business.isOrderNowSelect ? <button className='bg-white small text-black text-decoration-none border rounded-pill d-flex align-items-center py-2 px-3' onClick={(e) => sendOnWebsite(business, 'order', e)}><img src={Cutlery} alt='cutlery' className="text-primary me-2" />Order</button> : ''}
                                            {business.isTextSelect ? <button className='bg-white small text-black text-decoration-none border rounded-pill d-flex align-items-center py-2 px-3'> <i className="bi bi-pencil-fill text-primary me-2"></i>Text</button> : ''}
                                        </div>
                                        {(business.isCallForQuoteSelect || business.isBookForAppointmentSelect) ?
                                            <div className='d-flex gap-2 mt-3 mb-2'>
                                                {business.isCallForQuoteSelect ? <div type='button' className='d-flex align-items-center justify-content-center text-white px-3 py-2 flex-fill lh-sm' style={{ background: 'linear-gradient(to bottom right, #00cc66, #FF9A5A)', minHeight: '55px', borderTopRightRadius: '20px', borderBottomLeftRadius: '20px' }} onClick={handleRequestAQuoteClick}>Request a quote</div> : ''}
                                                {business.isBookForAppointmentSelect ? <div type='button' className='d-flex align-items-center justify-content-center text-white px-3 py-2 flex-fill lh-sm' style={{ background: 'linear-gradient(to bottom right, #0073e6, #FF6666)', minHeight: '55px', borderTopRightRadius: '20px', borderBottomLeftRadius: '20px' }}
                                                    onClick={() => handleBookAnAppointmentClick(business)} >Book An Appointment</div> : ''}
                                            </div> : ''
                                        }
                                        <div className='write-review'>
                                            <button className='btn btn-danger w-100 d-inline-flex align-items-center justify-content-center py-2' data-bs-toggle={token ? "modal" : ''} data-bs-target={token ? "#reviewModal1" : ''} onClick={() => checkRatingModal(business)}><i className="bi bi-pencil me-2"></i>Write a review</button>
                                        </div>
                                        <button type='button' className='w-100 btn btn-primary mt-2' onClick={(event) => handleBusinessDetails(event, business)}>View Details</button>
                                    </div>
                                </div>
                            </div>
                        ))
                        :
                        <div className='fs-3 text-danger text-center'>
                            There is no business in your area!
                        </div>
                    }
                    {/* pagination section */}
                    <div className='pagination-wrapper pt-3'>
                        <ReactPaginate
                            className="pagination justify-content-center m-1"
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            breakLabel="..."
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            nextLabel="&raquo;"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={1}
                            marginPagesDisplayed={2}
                            pageCount={pageCount}
                            previousLabel="&laquo;"
                            renderOnZeroPageCount={null}
                            containerClassName="pagination"
                            activeClassName="active"
                        />
                    </div>
                </div>
            </div>
            {/* infoDetails Modal */}
            <div className="modal fade" id="infoDetails" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="infoDetailsLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-sm">
                    <div className="modal-content">
                        <div className="modal-body text-center p-4">
                            <div className="link-item mb-3">
                                <Link to={`tel:${callDetails.country_code + callDetails.mobile_no}`} className='fs-18 text-decoration-none text-primary'>
                                    <i className="bi bi-telephone-fill text-primary me-2"></i> {callDetails.country_code + callDetails.mobile_no}
                                </Link>
                            </div>
                            <button type="button" className="btn btn-secondary px-5" data-bs-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* Review Model Popup */}
            <div className="modal fade" id="reviewModal1" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="reviewModal1Label" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header bg-primary text-white px-sm-4">
                            <h1 className="modal-title fs-18 text-capitalize lh-sm" id="reviewModal1Label">{selectedReviewBusiness.businessName}</h1>
                            <button type="button" className="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close" onClick={() => setRating(0)}></button>
                        </div>
                        <div className="modal-body px-3 px-sm-4 py-4">
                            <div className="review-wrapper mb-2">
                                <div className="star-wrapper d-flex gap-2 fs-5 mb-1">
                                    <Rating emptySymbol="bi bi-star" fullSymbol="bi bi-star-fill"
                                        initialValue={rating}
                                        value={rating}
                                        onClick={(value) => setRating(value)}
                                    />
                                </div>
                                <p className='m-0 text-muted'>Write your review for <small className='fw-medium text-capitalize'>{selectedReviewBusiness.businessName}</small></p>
                            </div>
                            <div className="review-message">
                                <textarea className="form-control small" id="dealMessage" rows="5" placeholder='Enter message here...' required
                                    onChange={(event) => setRatingComment(event.target.value)}
                                ></textarea>
                                <div className='invalid-feedback'></div>
                            </div>
                            <button className='w-100 btn btn-primary py-2 mt-3' data-bs-dismiss="modal" onClick={() => submitReview()}>Submit Review</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


const mapPropsToState = (state) => {
    return {
        loading: state.business.loading,
        error: state.business.error,
        success: state.business.success,
        eCommBusinessList: state.business.eCommBusinessList,
        couponDetails: state.rewardAndCoupon.couponDetails
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getEcomPageBusinessList: (data) => dispatch(actions.getEcomPageBusinessList(data)),
        clickPerCount: (data) => dispatch(actions.clickPerCount(data)),
        makeRating: (data) => dispatch(actions.makeRating(data)),
        createAndGetCoupon: (data) => dispatch(actions.createAndGetCoupon(data))
    }
}

export default connect(
    mapPropsToState,
    mapDispatchToProps
)(withSnackbar(EcommerceBusiness))