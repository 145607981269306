import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Helmet } from "react-helmet";
import { withSnackbar } from 'react-simple-snackbar'
import * as actions from '../store/actions/index';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactLoading from 'react-loading';
import DatePicker from "react-datepicker";
import { useNavigate } from 'react-router-dom';
import Autocomplete from 'react-google-autocomplete';
import Geocode from "react-geocode";
import { getDistance } from 'geolib';
Geocode.setApiKey("AIzaSyB_KZHG7uYGsaMGdQLDRrV3Qw6xRvLqg_Q");
Geocode.enableDebug();

let Service = ['Moving services only', 'Packing services only', 'Loading services only', 'Packing, loading & moving services', 'Packing and loading services only', 'Loading & moving services only', 'Off loading services only', 'Unpacking service only', 'Off loading and unpacking services only', 'Need all services', 'Storage']
let MovingServiceOption = ['Long distance moving', 'International moving', 'Household moving', 'National moving', 'Corporate relocation', 'Residential moving', 'Interstate moving', 'Intrastate moving', 'Full service moving', 'Office moving', 'Professional movers', 'Moving supplies', 'Free moving quotes', 'Local moving', 'Cross country moving',
  'Not sure', 'Other'];
let AreaHouse = ['Outdoor furniture', 'Garage item', 'Living room and family room', 'Bathroom', 'Dinning room', 'Home office', 'Kitchen', 'Pantry', 'Bedroom', 'Office',
  'Storage room', 'Laundry room']
let ItemToMove = ['Area rugs', 'Armoire/Wardrobe', 'Bar BQ', 'King bed', 'Queen/full/twin bed', 'Trundle / bunk bed', 'Waterbed', 'Bicycles', 'Bookcase', 'Boxes',
  'Buffet service', 'Chairs (small)', 'Change table', 'Coffee/ sofa table', 'Chest of drawers', 'Computer pcs', 'Console tv/ stereo', 'Drafting table', 'Dresser',
  'End table', 'Entertainment center', 'Exercise bike', 'File cabinet', 'Deep fridge', 'Glass shelves', 'Large glass top', 'Home gym', 'Hutch', 'Ironing board /vacuum', 'Large plants', 'Lawnmower', 'Lamps /pictures', 'Touch / floor lamps', 'Loveseat', 'Piano', 'Portable tv', 'Refrigerator/freezer', 'Rowing machine',
  'Sewing machine', 'Sofa / couch', 'Stereo components', 'Stair stepper', 'Treadmill', 'Tv/stereo stand', 'Washer / dryer', 'Weight bench', 'Yard tools']

let StorageType = ['Self (private) storage', 'Vehicle (car) storage', 'RV storage', 'Boat storage', 'Business storage', 'Not sure', 'Other']
let StorageSize = ['Small', 'Medium', 'Large', 'Extra large', 'Not sure', 'Other']
let StorageUnit = ['1', '2', '3', '4', '5', '6', 'Not sure', 'Other']
let Amenities = ['Climate controlled units', 'Premium security', 'Drive -up', 'Vehicle storage']
let Budget = ["$100 - $500", "$501 - $1000", "$1001 - $1500", "Not Sure", "Other"];
let PaymentMethod = ["Cash app", "Zelle", "Cash", "Check", "Debit card", "Credit Card", "Not Sure", "Other"];
const ContactWay = ['Phone Call', 'Video call', 'Text', 'Message', 'Email', 'Chat', 'Other'];

const MovingService = (props) => {
  let customerInfo = JSON.parse(localStorage.getItem('userInfo'));
  let navigate = useNavigate();
  const [serviceNeed, setServiceNeed] = useState('');
  const [moveService, setMoveService] = useState('');
  const [otherMoveService, setOtherMoveService] = useState(false);
  const [packingArea, setPackingArea] = useState([]);
  const [itemMoved, setItemMoved] = useState([]);
  const [storageType, setStorageType] = useState('');
  const [otherStorageType, setOtherStorageType] = useState(false);
  const [storageSize, setStorageSize] = useState('');
  const [otherStorageSize, setOtherStorageSize] = useState(false);
  const [storageUnit, setStorageUnit] = useState('');
  const [otherStorageUnit, setOtherStorageUnit] = useState(false);
  const [amenities, setAmenities] = useState([]);
  const [payment, setPaymentMethod] = useState('');
  const [otherPaymentMethod, setOtherPaymentMethod] = useState(false);
  const [budgetForService, setBudget] = useState('');
  const [otherBudget, setOtherBudget] = useState(false);
  const [contactMode, setContactMode] = useState('');
  const [otherContactMode, setOtherContactMode] = useState(false);
  const [pickupLocation, setPickupLocation] = useState('');
  const [pickupLat, setPickupLat] = useState('');
  const [pickupLong, setPickupLong] = useState('');
  const [dropOffLocation, setDropOffLocation] = useState('');
  const [dropLat, setDropLat] = useState('');
  const [dropLong, setDropLong] = useState('');
  const [pickUpDate, setPickUpDate] = useState(new Date());
  const [pickupTime, setPickUpTime] = useState('');
  const [dropOffDate, setDropOffDate] = useState(new Date());
  const [dropOffTime, setDropOffTime] = useState('');
  const [otherInfo, setOtherInfo] = useState('');

  const handleMovingService = (value) => {
    if (value === 'Other') {
      setOtherMoveService(true)
      setMoveService('')
    } else {
      setMoveService(value)
      setOtherMoveService(false)
    }
  }

  const handlePackingArea = (value) => {
    let updatedPackingArea = [...packingArea]
    if (updatedPackingArea.includes(value)) {
      let valueIndex = updatedPackingArea.indexOf(value)
      updatedPackingArea.splice(valueIndex, 1)
      setPackingArea(updatedPackingArea)
    } else {
      updatedPackingArea.push(value)
      setPackingArea(updatedPackingArea)
    }
  }

  const handleItemMoved = (value) => {
    let updatedItemMoved = [...itemMoved]
    if (updatedItemMoved.includes(value)) {
      let valueIndex = updatedItemMoved.indexOf(value)
      updatedItemMoved.splice(valueIndex, 1)
      setItemMoved(updatedItemMoved)
    } else {
      updatedItemMoved.push(value)
      setItemMoved(updatedItemMoved)
    }
  }

  const handleStorageType = (value) => {
    if (value === 'Other') {
      setOtherStorageType(true)
      setStorageType('')
    } else {
      setStorageType(value)
      setOtherStorageType(false)
    }
  }
  const handleStorageSize = (value) => {
    if (value === 'Other') {
      setOtherStorageSize(true)
      setStorageSize('')
    } else {
      setStorageSize(value)
      setOtherStorageSize(false)
    }
  }

  const handleStorageUnit = (value) => {
    if (value === 'Other') {
      setOtherStorageUnit(true)
      setStorageUnit('')
    } else {
      setStorageUnit(value)
      setOtherStorageUnit(false)
    }
  }

  const handleAmenities = (value) => {
    let updatedAmenities = [...amenities]
    if (updatedAmenities.includes(value)) {
      let valueIndex = updatedAmenities.indexOf(value)
      updatedAmenities.splice(valueIndex, 1)
      setAmenities(updatedAmenities)
    } else {
      updatedAmenities.push(value)
      setAmenities(updatedAmenities)
    }
  }
  const handleBudget = (value) => {
    if (value === 'Other') {
      setOtherBudget(true)
      setBudget('')
    } else {
      setOtherBudget(false)
      setBudget(value)
    }
  }
  const handlePaymentMethod = (value) => {
    if (value === 'Other') {
      setOtherPaymentMethod(true)
      setPaymentMethod('')
    } else {
      setOtherPaymentMethod(false)
      setPaymentMethod(value)
    }
  }
  const handleContactMode = (value) => {
    if (value === 'Other') {
      setOtherContactMode(true)
      setContactMode('')
    } else {
      setOtherContactMode(false)
      setContactMode(value)
    }
  }

  const handleTimeChange = (event) => {
    var timeSplit = event.target.value.split(':'),
      hours,
      minutes,
      hours = timeSplit[0];
    minutes = timeSplit[1];
    // Check whether AM or PM
    let newformat = hours >= 12 ? 'PM' : 'AM';
    // Find current hour in AM-PM Format
    hours = hours % 12;

    // To display "0" as "12"
    hours = hours ? hours : 12;
    // minutes = minutes < 10 ? '0' + minutes : minutes;
    let selectedTime = hours + ':' + minutes + ' ' + newformat
    setPickUpTime(selectedTime)
  }
  const handleDropOffTimeChange = (event) => {
    var timeSplit = event.target.value.split(':'),
      hours,
      minutes,
      hours = timeSplit[0];
    minutes = timeSplit[1];
    // Check whether AM or PM
    let newformat = hours >= 12 ? 'PM' : 'AM';
    // Find current hour in AM-PM Format
    hours = hours % 12;

    // To display "0" as "12"
    hours = hours ? hours : 12;
    // minutes = minutes < 10 ? '0' + minutes : minutes;
    let selectedTime = hours + ':' + minutes + ' ' + newformat
    setDropOffTime(selectedTime)
  }
  const handleLocationSelection = (place, type) => {
    const lat = place.geometry.location.lat();
    const lng = place.geometry.location.lng();
    fetch('https://maps.googleapis.com/maps/api/geocode/json?latlng=' + lat + ',' + lng + '&key=AIzaSyB_KZHG7uYGsaMGdQLDRrV3Qw6xRvLqg_Q', {
      method: 'GET'
    }).then((response) => response.json())
      .then((data) => {
        if (data.results[1]) {
          const address = data.results[0].formatted_address
          if (type === 'pickup') {
            setPickupLocation(address)
            setPickupLat(lat)
            setPickupLong(lng)
          } else {
            setDropOffLocation(address)
            setDropLat(lat)
            setDropLong(lng)
          }
        }
      })
      .catch((error) => {
        toast.error(error, {
          autoClose: 700,
          hideProgressBar: true,
        })
      })
  };


  const handleSubmit = (e) => {
    e.preventDefault()
    if (serviceNeed && contactMode && budgetForService && payment) {
      // let UsDate = new Date(appointDate).toLocaleDateString("en-US")
      let quoteDetails = {
        "Requested Service": "Moving Compony & Storage facilities",
        "What is the best way to contact you?": contactMode,
        "What is your budget for this service?": budgetForService,
        "What is the method of payment?": payment,
        "Any other details": otherInfo
      }
      let mobileNo = customerInfo.country_code + customerInfo.mobile_no
      let customerName = customerInfo.name;
      let customerEmail = customerInfo.email;
      let data = {
        mobileNo: mobileNo,
        customerName: customerName,
        customerEmail: customerEmail,
        businessName: props.businessDetails.businessName,
        businessEmail: props.businessDetails.email,
        businessId: props.businessDetails.businessId,
        clickType: 'requestQuote',
        quoteDetails: quoteDetails
      }

      if (serviceNeed === 'Moving services only') {
        if (moveService && pickupLocation && dropOffLocation && pickUpDate
          && pickupTime && dropOffDate && dropOffTime) {
          let UsDatepickUp = new Date(pickUpDate).toLocaleDateString("en-US")
          let UsDatedropOff = new Date(dropOffDate).toLocaleDateString("en-US")
          quoteDetails['What type of moving services do you need?'] = moveService
          quoteDetails['Pickup Location'] = pickupLocation
          quoteDetails['DropOff Location'] = dropOffLocation
          quoteDetails['Pickup Date'] = UsDatepickUp
          quoteDetails['DropOff Date'] = UsDatedropOff
          quoteDetails['Pickup Time'] = pickupTime
          quoteDetails['DropOff Time'] = dropOffTime
          props.clickPerCount(data, toast, navigate)
        } else {
          toast.warning('Please Select All required field!', { autoClose: 700, hideProgressBar: true })
        }
      } else if (serviceNeed === 'Packing services only') {
        if (packingArea && pickupLocation && pickUpDate && pickupTime) {
          let UsDatepickUp = new Date(pickUpDate).toLocaleDateString("en-US")
          quoteDetails['Select the area of the house you want to pack?'] = packingArea
          quoteDetails['Pickup Location'] = pickupLocation
          quoteDetails['Pickup Date'] = UsDatepickUp
          quoteDetails['Pickup Time'] = pickupTime
          props.clickPerCount(data, toast, navigate)
        } else {
          toast.warning('Please Select All required field!', { autoClose: 700, hideProgressBar: true })
        }
      } else if (serviceNeed === 'Loading services only') {
        if (itemMoved && pickupLocation && pickUpDate && pickupTime) {
          let UsDatepickUp = new Date(pickUpDate).toLocaleDateString("en-US")
          quoteDetails['Items to be moved'] = itemMoved
          quoteDetails['Pickup Location'] = pickupLocation
          quoteDetails['Pickup Date'] = UsDatepickUp
          quoteDetails['Pickup Time'] = pickupTime
          props.clickPerCount(data, toast, navigate)
        } else {
          toast.warning('Please Select All required field!', { autoClose: 700, hideProgressBar: true })
        }
      } else if (serviceNeed === 'Packing, loading & moving services') {
        if (moveService && packingArea && itemMoved && pickupLocation && dropOffLocation && pickUpDate
          && pickupTime && dropOffDate && dropOffTime) {
          let UsDatepickUp = new Date(pickUpDate).toLocaleDateString("en-US")
          let UsDatedropOff = new Date(dropOffDate).toLocaleDateString("en-US")
          quoteDetails['What type of moving services do you need?'] = moveService;
          quoteDetails['Select the area of the house you want to pack?'] = packingArea;
          quoteDetails['Items to be moved'] = itemMoved;
          quoteDetails['Pickup Location'] = pickupLocation
          quoteDetails['DropOff Location'] = dropOffLocation
          quoteDetails['Pickup Date'] = UsDatepickUp
          quoteDetails['DropOff Date'] = UsDatedropOff
          quoteDetails['Pickup Time'] = pickupTime
          quoteDetails['DropOff Time'] = dropOffTime
          props.clickPerCount(data, toast, navigate)
        } else {
          toast.warning('Please Select All required field!', { autoClose: 700, hideProgressBar: true })
        }
      } else if (serviceNeed === 'Packing and loading services only') {
        if (packingArea && itemMoved && pickupLocation && pickUpDate && pickupTime) {
          let UsDatepickUp = new Date(pickUpDate).toLocaleDateString("en-US")
          quoteDetails['Select the area of the house you want to pack?'] = packingArea;
          quoteDetails['Items to be moved'] = itemMoved;
          quoteDetails['Pickup Location'] = pickupLocation
          quoteDetails['Pickup Date'] = UsDatepickUp
          quoteDetails['Pickup Time'] = pickupTime
          props.clickPerCount(data, toast, navigate)
        } else {
          toast.warning('Please Select All required field!', { autoClose: 700, hideProgressBar: true })
        }
      } else if (serviceNeed === 'Loading & moving services only') {
        if (moveService && itemMoved && pickupLocation && dropOffLocation && pickUpDate
          && pickupTime && dropOffDate && dropOffTime) {
          let UsDatepickUp = new Date(pickUpDate).toLocaleDateString("en-US")
          let UsDatedropOff = new Date(dropOffDate).toLocaleDateString("en-US")
          quoteDetails['What type of moving services do you need?'] = moveService;
          quoteDetails['Items to be moved'] = itemMoved;
          quoteDetails['Pickup Location'] = pickupLocation
          quoteDetails['DropOff Location'] = dropOffLocation
          quoteDetails['Pickup Date'] = UsDatepickUp
          quoteDetails['DropOff Date'] = UsDatedropOff
          quoteDetails['Pickup Time'] = pickupTime
          quoteDetails['DropOff Time'] = dropOffTime
          props.clickPerCount(data, toast, navigate)
        } else {
          toast.warning('Please Select All required field!', { autoClose: 700, hideProgressBar: true })
        }
      } else if (serviceNeed === 'Off loading services only') {
        if (itemMoved && pickupLocation && pickUpDate && pickupTime) {
          let UsDatepickUp = new Date(pickUpDate).toLocaleDateString("en-US")
          quoteDetails['Items to be moved'] = itemMoved
          quoteDetails['Pickup Location'] = pickupLocation
          quoteDetails['Pickup Date'] = UsDatepickUp
          quoteDetails['Pickup Time'] = pickupTime
          props.clickPerCount(data, toast, navigate)
        } else {
          toast.warning('Please Select All required field!', { autoClose: 700, hideProgressBar: true })
        }
      } else if (serviceNeed === 'Unpacking service only') {
        if (packingArea && pickupLocation && pickUpDate && pickupTime) {
          let UsDatepickUp = new Date(pickUpDate).toLocaleDateString("en-US")
          quoteDetails['Select the area of the house you want to unpack?'] = packingArea
          quoteDetails['Pickup Location'] = pickupLocation
          quoteDetails['Pickup Date'] = UsDatepickUp
          quoteDetails['Pickup Time'] = pickupTime
          props.clickPerCount(data, toast, navigate)
        } else {
          toast.warning('Please Select All required field!', { autoClose: 700, hideProgressBar: true })
        }
      } else if (serviceNeed === 'Off loading and unpacking services only') {
        if (packingArea && itemMoved && pickupLocation && pickUpDate && pickupTime) {
          let UsDatepickUp = new Date(pickUpDate).toLocaleDateString("en-US")
          quoteDetails['Select the area of the house you want to pack?'] = packingArea;
          quoteDetails['Items to be moved'] = itemMoved;
          quoteDetails['Pickup Location'] = pickupLocation
          quoteDetails['Pickup Date'] = UsDatepickUp
          quoteDetails['Pickup Time'] = pickupTime
          props.clickPerCount(data, toast, navigate)
        } else {
          toast.warning('Please Select All required field!', { autoClose: 700, hideProgressBar: true })
        }
      } else if (serviceNeed === 'Storage') {
        if (storageType && storageSize && storageUnit && amenities && dropOffLocation && dropOffDate && dropOffTime) {
          let UsDatedropOff = new Date(dropOffDate).toLocaleDateString("en-US")
          quoteDetails['What type of storage do you need?'] = storageType;
          quoteDetails['What is the size of the storage you need?'] = storageSize;
          quoteDetails['How many storage unit do you need?'] = storageUnit;
          quoteDetails['What type of Amenities do you need on a storage facility?'] = amenities;
          quoteDetails['DropOff Location'] = dropOffLocation
          quoteDetails['DropOff Date'] = UsDatedropOff
          quoteDetails['DropOff Time'] = dropOffTime
          props.clickPerCount(data, toast, navigate)
        } else {
          toast.warning('Please Select All required field!', { autoClose: 700, hideProgressBar: true })
        }
      } else if (serviceNeed === 'Need all services') {
        if (moveService && packingArea && itemMoved && pickupLocation && pickUpDate && pickupTime
          && storageType && storageSize && storageUnit && amenities && dropOffLocation && dropOffDate
          && dropOffTime) {
          let UsDatepickUp = new Date(pickUpDate).toLocaleDateString("en-US")
          let UsDatedropOff = new Date(dropOffDate).toLocaleDateString("en-US")
          quoteDetails['What type of moving services do you need?'] = moveService
          quoteDetails['Select the area of the house you want to pack?'] = packingArea
          quoteDetails['Items to be moved'] = itemMoved
          quoteDetails['What type of storage do you need?'] = storageType;
          quoteDetails['What is the size of the storage you need?'] = storageSize;
          quoteDetails['How many storage unit do you need?'] = storageUnit;
          quoteDetails['What type of Amenities do you need on a storage facility?'] = amenities;
          quoteDetails['Pickup Location'] = pickupLocation
          quoteDetails['DropOff Location'] = dropOffLocation
          quoteDetails['Pickup Date'] = UsDatepickUp
          quoteDetails['DropOff Date'] = UsDatedropOff
          quoteDetails['Pickup Time'] = pickupTime
          quoteDetails['DropOff Time'] = dropOffTime
          props.clickPerCount(data, toast, navigate)
        } else {
          toast.warning('Please Select All required field!', { autoClose: 700, hideProgressBar: true })
        }
      }
    } else {
      toast.warning('Please Select All required field!', { autoClose: 700, hideProgressBar: true })
    }
  }

  return (
    <div id="cleaningServices" className='card shadow-sm py-4 py-md-5 px-3 px-md-5 mt-4'>
      <Helmet>
        <title>Request Quote For Moving Compony</title>
      </Helmet>
      <ToastContainer />
      <div className="section-label fs-3 mb-4">Moving Compony & Storage facilities</div>

      <div className="mb-3">
        <label htmlFor="chooseService" className="form-label fs-6 mb-2">What services do you need?</label>
        <div className="d-flex flex-wrap g-2">
          {
            Service.map((value) => (
              <div className="form-check me-3" key={value}>
                <input className="form-check-input" type="radio" name="radioGroupForCleningProperty" id={value} required
                  onClick={() => setServiceNeed(value)} />
                <label className="form-check-label" htmlFor={value}>{value}</label>
              </div>
            ))
          }
        </div>
      </div>
      {
        (serviceNeed === 'Moving services only' || serviceNeed === 'Packing, loading & moving services' ||
          serviceNeed === 'Loading & moving services only' || serviceNeed === 'Need all services') ?
          <div className="mb-3">
            <label htmlFor="chooseService" className="form-label fs-6 mb-2">What type of moving services do you need?</label>
            <div className="d-flex flex-wrap g-2">
              {
                MovingServiceOption.map((option) => (
                  <div className="form-check me-3" key={option}>
                    <input className="form-check-input" type="radio" name="radioGroupForMovingService" id={option} required
                      onClick={() => handleMovingService(option)} />
                    <label className="form-check-label" htmlFor={option}>{option}</label>
                  </div>
                ))
              }
            </div>
            {
              otherMoveService ?
                <input type="text" className="form-control mt-2" id="OTH" placeholder='Other moving service...' required
                  onChange={(e) => setMoveService(e.target.value)} />
                : ''
            }
          </div>
          : ''
      }
      {
        (serviceNeed === 'Packing services only' || serviceNeed === 'Packing, loading & moving services' || serviceNeed === 'Unpacking service only' ||
          serviceNeed === 'Packing and loading services only' || serviceNeed === 'Need all services' || serviceNeed === 'Off loading and unpacking services only') ?
          <div className="mb-3">
            <label htmlFor="choosePackingArea" className="form-label fs-6 mb-2">Select the area of the house you want to pack? </label>
            <div className="d-flex flex-wrap gap-2">
              {
                AreaHouse.map((value) => (
                  <div className="form-check me-3" key={value}>
                    <input className="form-check-input" type="checkbox" name="checkboxPackingArea" id={value}
                      onChange={() => handlePackingArea(value)} />
                    <label className="form-check-label" htmlFor={value}>{value}</label>
                  </div>
                ))
              }
            </div>
          </div>
          : ''
      }

      {
        (serviceNeed === 'Loading services only' || serviceNeed === 'Packing, loading & moving services' || serviceNeed === 'Loading & moving services only' || serviceNeed === 'Off loading and unpacking services only' ||
          serviceNeed === 'Packing and loading services only' || serviceNeed === 'Off loading services only' || serviceNeed === 'Need all services') ?
          <div className="mb-3">
            <label htmlFor="chooseItemMoved" className="form-label fs-6 mb-2">Select items to be moved</label>
            <div className="d-flex flex-wrap gap-2">
              {
                ItemToMove.map((value) => (
                  <div className="form-check me-3" key={value}>
                    <input className="form-check-input" type="checkbox" name="checkboxItemMoved" id={value}
                      onChange={() => handleItemMoved(value)} />
                    <label className="form-check-label" htmlFor={value}>{value}</label>
                  </div>
                ))
              }
            </div>
          </div>
          : ''
      }
      {
        (serviceNeed === 'Storage') ?
          <div>
            <div className="mb-3">
              <label htmlFor="chooseStorageType" className="form-label fs-6 mb-2">What type of storage do you need?</label>
              <div className="d-flex flex-wrap g-2">
                {
                  StorageType.map((option) => (
                    <div className="form-check me-3" key={option}>
                      <input className="form-check-input" type="radio" name="radioGroupForStorageType" id={option} required
                        onClick={() => handleStorageType(option)} />
                      <label className="form-check-label" htmlFor={option}>{option}</label>
                    </div>
                  ))
                }
              </div>
              {
                otherStorageType ?
                  <input type="text" className="form-control mt-2" id="OTHStorageType" placeholder='Other...' required
                    onChange={(e) => setStorageType(e.target.value)} />
                  : ''
              }
            </div>
            <div className="mb-3">
              <label htmlFor="chooseStorageSize" className="form-label fs-6 mb-2">What is the size of the storage you need?</label>
              <div className="d-flex flex-wrap g-2">
                {
                  StorageSize.map((option) => (
                    <div className="form-check me-3" key={option}>
                      <input className="form-check-input" type="radio" name="radioGroupForStorageSize" id={option} required
                        onClick={() => handleStorageSize(option)} />
                      <label className="form-check-label" htmlFor={option}>{option}</label>
                    </div>
                  ))
                }
              </div>
              {
                otherStorageSize ?
                  <input type="text" className="form-control mt-2" id="OTHStorageSize" placeholder='Other...' required
                    onChange={(e) => setStorageSize(e.target.value)} />
                  : ''
              }
            </div>
            <div className="mb-3">
              <label htmlFor="chooseStorageUnit" className="form-label fs-6 mb-2">How many storage unit do you need?</label>
              <div className="d-flex flex-wrap g-2">
                {
                  StorageUnit.map((unit) => (
                    <div className="form-check me-3" key={unit}>
                      <input className="form-check-input" type="radio" name="radioGroupForStorageUnit" id={unit} required
                        onClick={() => handleStorageUnit(unit)} />
                      <label className="form-check-label" htmlFor={unit}>{unit}</label>
                    </div>
                  ))
                }
              </div>
              {
                otherStorageUnit ?
                  <input type="text" className="form-control mt-2" id="OTHStorageUnit" placeholder='Other...' required
                    onChange={(e) => setStorageUnit(e.target.value)} />
                  : ''
              }
            </div>
            <div className="mb-3">
              <label htmlFor="chooseAmenities" className="form-label fs-6 mb-2">What type of Amenities do you need on a storage facility?</label>
              <div className="d-flex flex-wrap gap-2">
                {
                  Amenities.map((value) => (
                    <div className="form-check me-3" key={value}>
                      <input className="form-check-input" type="checkbox" name="checkboxAmenities" id={value}
                        onChange={() => handleAmenities(value)} />
                      <label className="form-check-label" htmlFor={value}>{value}</label>
                    </div>
                  ))
                }
              </div>
            </div>
          </div> : ''
      }

      <div className="mb-3">
        <label htmlFor="chooseContactmode" className="form-label fs-6 mb-2">What is the best way to contact you?</label>
        <div className="d-flex flex-wrap g-2">
          {
            ContactWay.map((cntct) => (
              <div className="form-check me-3" key={cntct}>
                <input className="form-check-input" type="radio" name="radioGroupForcontact" id={cntct}
                  onClick={() => handleContactMode(cntct)} />
                <label className="form-check-label" htmlFor={cntct}>{cntct}</label>
              </div>
            ))
          }
        </div>
        {
          otherContactMode ?
            <input type="text" className="form-control mt-2" id="otherContactMode" placeholder='Other contact...' required
              onChange={(e) => setContactMode(e.target.value)} />
            : ''
        }
      </div>
      <div className="mb-3">
        <label htmlFor="chooseBudget" className="form-label fs-6 mb-2">What is your budget for this service?</label>
        <div className="d-flex flex-wrap g-2">
          {
            Budget.map((budge) => (
              <div className="form-check me-3" key={budge}>
                <input className="form-check-input" type="radio" name="radioGroupBudget" id={budge} required
                  onClick={() => handleBudget(budge)} />
                <label className="form-check-label" htmlFor={budge}>{budge}</label>
              </div>
            ))
          }

        </div>
        {
          otherBudget ?
            <input type="text" className="form-control mt-2" id="otherBudget" placeholder='Other budget...' required
              onChange={(e) => setBudget(e.target.value)} />
            : ''
        }
      </div>
      <div className="mb-3">
        <label htmlFor="choosePayment" className="form-label fs-6 mb-2">What is your payment method for this service?</label>
        <div className="d-flex flex-wrap g-2">
          {
            PaymentMethod.map((pay) => (
              <div className="form-check me-3" key={pay}>
                <input className="form-check-input" type="radio" name="radioGroupPay" id={pay} required
                  onClick={() => handlePaymentMethod(pay)} />
                <label className="form-check-label" htmlFor={pay}>{pay}</label>
              </div>
            ))
          }
        </div>
        {
          otherPaymentMethod ?
            <input type="text" className="form-control mt-2" id="otherPayment" placeholder='Other payment method...' required
              onChange={(e) => setPaymentMethod(e.target.value)} />
            : ''
        }
      </div>

      {
        serviceNeed !== 'Storage' ?
          <div className="mb-3">
            <label htmlFor="pickupLocation" className="form-label fs-6 mb-2">Pickup Location (packing) location address</label>
            <Autocomplete
              className="form-control"
              placeholder='Please enter pickup location...'
              onPlaceSelected={(e) => handleLocationSelection(e, 'pickup')}
              options={{
                fields: ["formatted_address", "geometry", "name"],
                strictBounds: false,
                types: [],
              }}
            />
          </div> : ''
      }
      {/* Packing, loading & moving services */}
      {(serviceNeed === 'Storage' || serviceNeed === 'Moving services only' || serviceNeed === 'Packing, loading & moving services' ||
        serviceNeed === 'Need all services' || serviceNeed === 'Loading & moving services only') ?
        <div className="mb-3">
          <label htmlFor="DropLocation" className="form-label fs-6 mb-2">Drop Off Location address</label>
          <Autocomplete
            className="form-control"
            placeholder='Please enter dropoff location...'
            onPlaceSelected={(e) => handleLocationSelection(e, 'dropOff')}
            options={{
              fields: ["formatted_address", "geometry", "name"],
              strictBounds: false,
              types: [],
            }}
          />
        </div>
        : ''}
      {
        serviceNeed !== 'Storage' ?
          <div className='pickup_time_date'>
            <div className="mb-3">
              <label htmlFor="pickupDate" className="form-label fs-6 mb-2">Pickup (packing) Date</label>
              <div className='date-picker-wrapper'>
                <DatePicker showIcon className="form-control w-100" id="appointmentDate" selected={pickUpDate}
                  onChange={(value) => setPickUpDate(value)} minDate={new Date()}
                />
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="pickupTime" className="form-label fs-6 mb-2">Pickup (packing) Time</label>
              <input type="time" id="pickupTime" className="form-control" min="09:00" max="18:00" required
                onChange={(e) => handleTimeChange(e)} />
            </div>
          </div>
          : ''}
      {(serviceNeed === 'Storage' || serviceNeed === 'Moving services only' || serviceNeed === 'Packing, loading & moving services'
        || serviceNeed === 'Need all services' || serviceNeed === 'Loading & moving services only') ?
        <div className='Doff_off_time_date'>
          <div className="mb-3">
            <label htmlFor="pickupDate" className="form-label fs-6 mb-2">Drop off date</label>
            <div className='date-picker-wrapper'>
              <DatePicker showIcon className="form-control w-100" id="appointmentDropOffDate" selected={dropOffDate}
                onChange={(value) => setDropOffDate(value)} minDate={new Date()}
              />
            </div>
          </div>
          <div className="mb-3">
            <label htmlFor="pickupTime" className="form-label fs-6 mb-2">Drop Off time</label>
            <input type="time" id="pickupTime" className="form-control" min="09:00" max="18:00" required
              onChange={(e) => handleDropOffTimeChange(e)} />
          </div>
        </div>
        : ''
      }
      <div className="mb-4">
        <label htmlFor="otherInfo" className="form-label fs-6 mb-2">Any other information</label>
        <textarea className="form-control" id="otherInfo" rows="4" placeholder='Write your description here...' required
          onChange={(e) => setOtherInfo(e.target.value)}></textarea>
      </div>
      <div className="btn-wrapper">
        <button type="submit" className="w-100 btn btn-primary" onClick={(e) => handleSubmit(e)}>Submit</button>
      </div>

      {props.loading ?
        <div className='position-fixed top-0 start-0 bottom-0 end-0 d-flex align-items-center justify-content-center w-100 h-100 bg-black bg-opacity-50'
          style={{ zIndex: 1030 }}>
          <ReactLoading type={'spin'} color={'#fff'} height={64} width={64} />
        </div>
        : ''}
    </div>
  )
}

const mapPropsToState = (state) => {
  return {
    loading: state.business.loading,
    error: state.business.error,
    success: state.business.success,
  }
}
const mapDispatchToProps = dispatch => {
  return {
    clickPerCount: (data, toast, navigate) => dispatch(actions.clickPerCount(data, toast, navigate)),
  }
}

export default connect(
  mapPropsToState,
  mapDispatchToProps
)(withSnackbar(MovingService))