import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from "react-helmet";
import { useLocation, useNavigate } from 'react-router-dom';
import Banner3 from '../assets/bg-banner3.jpg';
import RealState from '../components/RealState';
import AutoDealership from '../components/AutoDealership';
import Photography from '../components/Photography';
import { OtherServices } from '../components/OtherServices';
import CateringServices from '../components/CateringServices';
import RideTaxi from '../components/RideTaxi';
import DeliveryService from '../components/DeliveryService'
import CleaningServices from '../components/CleaningServices';
import AutoRepair from '../components/AutoRepair';
import { Insurance } from '../components/Insurance';
import { AutoDetailing } from '../components/AutoDetailing';
import Remodeling from '../components/Remodeling';
import RestaurantAndEvent from '../components/RestaurantAndEvent';
import { Plumbing } from '../components/Plumbing';
import { Electrician } from '../components/Electrician';
import { HeatingCoolingVentilation } from '../components/HeatingCoolingVentilation';
import { Painting } from '../components/Painting';
import { Roofing } from '../components/Roofing';
import { Handyman } from '../components/Handyman';
import Entertainment from '../components/Entertainment';
import Landscaping from '../components/Landscaping';
import Accordion from 'react-bootstrap/Accordion';
import FlowerAndPlant from '../components/FlowerAndPlant';
import MovingCompony from '../components/MovingCompony';

export const RequestQuote = () => {
    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
    }, []);
    const ref = useRef(null);
    const { state } = useLocation();
    let requestQuoteService = state.requestQuoteService;
    const [requestCategory, setRequestCategory] = useState('')
    const [service, setService] = useState('')

    const handleService = (value) => {
        ref.current?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: "nearest" });
        setService(value)
    }
    const handleRequestCategory = (value) => {
        ref.current?.scrollIntoView({ behavior: 'smooth', block: 'end', inline: "nearest" });
        setRequestCategory(value)
        setService('')
    }



    const renderServicePage = () => {
        switch (requestQuoteService) {
            case 'Auto Repair':
                return <AutoRepair businessDetails={state} />;
            case 'Catering Service':
                return <CateringServices businessDetails={state} />;
            case 'Restaurant & Event Venue':
                return <RestaurantAndEvent businessDetails={state} />;
            // case 'Bakery & Caffe':
            //     return <CateringServices businessDetails={state} />;
            case 'Entertainment & Event Planning':
                return <Entertainment businessDetails={state} />;
            case 'Photography & videography':
                return <Photography businessDetails={state} />;
            case 'Flower & plant':
                return <FlowerAndPlant businessDetails={state} />;
            // case 'Beauty salon':
            //     return <CateringServices businessDetails={state} />;
            // case 'Pet care':
            //     return <CateringServices businessDetails={state} />;
            case 'Auto Dealership':
                return <AutoDealership businessDetails={state} />;
            case 'Ride service':
                return <RideTaxi businessDetails={state} />;
            case 'Delivery Service':
                return <DeliveryService businessDetails={state} />;
            case 'Cleaning Service':
                return <CleaningServices businessDetails={state} />;
            case 'Moving Companies & Storage facilities':
                return <MovingCompony businessDetails={state} />;
            // case 'Duct cleaning':
            //     return <CateringServices businessDetails={state} />;
            // case 'Power/Pressure washing':
            //     return <CateringServices businessDetails={state} />;
            // case 'Dry cleaning and alternations':
            //     return <CateringServices businessDetails={state} />;
            // case 'Care Services':
            //     return <CateringServices businessDetails={state} />;
            // case 'Tutoring (Lesson) services':
            //     return <CateringServices businessDetails={state} />;
            // case 'Counseling Services':
            //     return <CateringServices businessDetails={state} />;
            // case 'Estate Planning (Lawyer & Real Estate)':
            //     return <CateringServices businessDetails={state} />;
            // case 'Financial & Accounting Services':
            //     return <CateringServices businessDetails={state} />;
            // case 'Insurance':
            //     return <Insurance  businessDetails={state} />;
            // case 'Real Estate (Apartment Rental)':
            //     return <CateringServices businessDetails={state} />;
            case 'Landscaping (Lawn Mow) Services':
                return <Landscaping businessDetails={state} />;
            case 'Remodeling':
                return <Remodeling businessDetails={state} />;
            // case 'Electrical Services':
            //     return <CateringServices businessDetails={state} />;
            // case 'Plumbing':
            //     return <Plumbing businessDetails={state} />;
            // case 'Heating, Ventilation & Air conditioning':
            //     return <HeatingCoolingVentilation businessDetails={state} />;
            // case 'Painting':
            //     return <Painting  businessDetails={state} />;
            // case 'Roofing':
            //     return <Roofing  businessDetails={state} />;
            // case 'Tree and Shrubs Services':
            //     return <CateringServices businessDetails={state} />;
            // case 'Locksmiths':
            //     return <CateringServices businessDetails={state} />;
            // case 'Grocery':
            //     return <CateringServices businessDetails={state} />;
            // case 'Home Maintenance store':
            //     return <CateringServices businessDetails={state} />;
            // case 'Furniture Store':
            //     return <CateringServices businessDetails={state} />;
            // case 'Wine Store':
            //     return <CateringServices businessDetails={state} />;
            // case 'Shopping':
            //     return <CateringServices businessDetails={state} />;
            // case 'Cell phone repair':
            //     return <CateringServices businessDetails={state} />;
            // case 'Personal shopper':
            //     return <CateringServices businessDetails={state} />;

            // Add more cases for other services
            default:
                // return <ComingSoon />;
                return <div className='userinfo-quote-service py-4'>
                    <div className='container'>
                        <div className='text-content-center'>
                            <h1>Coming soon....</h1>
                        </div>
                    </div>
                </div>
        }
    };

    return (
        <>
            <Helmet>
                <title>Request Quote | Couponranking</title>
            </Helmet>
            <div className='requestQuote-page section-bg-image pt-4 pb-5' style={{ backgroundImage: `url(${Banner3})` }}>
                <div className="container">
                    <div className="form-wrapper mx-auto" style={{ maxWidth: '900px' }}>
                        <form className='card border-0 shadow p-3 p-lg-5'>
                            {/* All Radio Button Checks */}
                            {/* <div className='card shadow-sm py-4 px-3 px-md-5'>
                                <div className="section-label fs-3 mb-4">Request A Quote</div>
                                <div className='request-quote-category'>
                                    <Accordion defaultActiveKey="0">
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header onClick={() => setService('')}>PARTY & EVENTS</Accordion.Header>
                                            <Accordion.Body>
                                                <div className="wrapper" ref={ref}>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="radio" name="radioGroup" id="cateringService"
                                                            checked={service === 'catering_service' ? true : false}
                                                            onChange={() => handleService('catering_service')} />
                                                        <label className="form-check-label" htmlFor="cateringService">Catering Service</label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="radio" name="radioGroup" id="restaurant"
                                                            checked={service === 'restaurant' ? true : false}
                                                            onChange={() => handleService('restaurant')} />
                                                        <label className="form-check-label" htmlFor="restaurant">Restaurant & Event Venue</label>
                                                    </div>

                                                    <div className="form-check">
                                                        <input className="form-check-input" type="radio" name="radioGroup" id="backery_caffe"
                                                            checked={service === 'backery_caffe' ? true : false}
                                                            onChange={() => handleService('backery_caffe')} />
                                                        <label className="form-check-label" htmlFor="backery_caffe">Bakery & Caffe</label>
                                                    </div>

                                                    <div className="form-check">
                                                        <input className="form-check-input" type="radio" name="radioGroup" id="entertainment_eventplaning"
                                                            checked={service === 'entertainment_eventplaning' ? true : false}
                                                            onChange={() => handleService('entertainment_eventplaning')} />
                                                        <label className="form-check-label" htmlFor="entertainment_eventplaning">Entertainment & Event Planning</label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="radio" name="radioGroup" id="photography_videography"
                                                            checked={service === 'photography_videography' ? true : false}
                                                            onChange={() => handleService('photography_videography')} />
                                                        <label className="form-check-label" htmlFor="photography_videography">Photography & videography</label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="radio" name="radioGroup" id="flowerPlant"
                                                            checked={service === 'flower_plant' ? true : false}
                                                            onChange={() => handleService('flower_plant')} />
                                                        <label className="form-check-label" htmlFor="flowerPlant">Flower & plant</label>
                                                    </div>
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="1">
                                            <Accordion.Header onClick={() => setService('')}>AUTOMOTIVE & AUTO DEALERSHIP</Accordion.Header>
                                            <Accordion.Body>
                                                <div className="wrapper" ref={ref}>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="radio" name="radioGroup" id="autoRepair"
                                                            checked={service === 'auto_repair' ? true : false}
                                                            onChange={() => handleService('auto_repair')} />
                                                        <label className="form-check-label" htmlFor="autoRepair">Auto Repair</label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="radio" name="radioGroup" id="autoDealership"
                                                            checked={service === 'auto_dealership' ? true : false}
                                                            onChange={() => handleService('auto_dealership')} />
                                                        <label className="form-check-label" htmlFor="autoDealership">Auto Dealership</label>
                                                    </div>
                                                </div>

                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="2">
                                            <Accordion.Header onClick={() => setService('')}>RIDES & DELIVERY SERVICE</Accordion.Header>
                                            <Accordion.Body>
                                                <div className="wrapper" ref={ref}>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="radio" name="radioGroup" id="ride_service"
                                                            checked={service === 'rideService' ? true : false}
                                                            onChange={() => handleService('rideService')} />
                                                        <label className="form-check-label" htmlFor="ride_service">Ride(taxi) service</label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="radio" name="radioGroup" id="delivery_service"
                                                            checked={service === 'deliveryService' ? true : false}
                                                            onChange={() => handleService('deliveryService')} />
                                                        <label className="form-check-label" htmlFor="delivery_service">Delivery Service(towing, food, couriers)</label>
                                                    </div>
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="3">
                                            <Accordion.Header onClick={() => setService('')}>CLEANING SERVICE, MOVING COMPANIES & STORAGE FACILITIES</Accordion.Header>
                                            <Accordion.Body>
                                                <div className='wrapper'>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="radio" name="radioGroup" id="cleaningService"
                                                            checked={service === 'cleaning_service' ? true : false}
                                                            onChange={() => handleService('cleaning_service')} />
                                                        <label className="form-check-label" htmlFor="cleaningService">Cleaning Service</label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="radio" name="radioGroup" id="moving_companies"
                                                            checked={service === 'moving_companies' ? true : false}
                                                            onChange={() => handleService('moving_companies')} />
                                                        <label className="form-check-label" htmlFor="moving_companies">Moving Companies & Storage facilities</label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="radio" name="radioGroup" id="duct_cleaning"
                                                            checked={service === 'duct_cleaning' ? true : false}
                                                            onChange={() => handleService('duct_cleaning')} />
                                                        <label className="form-check-label" htmlFor="duct_cleaning">Duct cleaning</label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="radio" name="radioGroup" id="power_pressure"
                                                            checked={service === 'power_pressure' ? true : false}
                                                            onChange={() => handleService('power_pressure')} />
                                                        <label className="form-check-label" htmlFor="power_pressure">Power/Pressure washing</label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="radio" name="radioGroup" id="dryCleaningAlternation"
                                                            checked={service === 'dry_cleaning_alternation' ? true : false}
                                                            onChange={() => handleService('dry_cleaning_alternation')} />
                                                        <label className="form-check-label" htmlFor="dryCleaningAlternation">Dry cleaning and alternations</label>
                                                    </div>
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="4">
                                            <Accordion.Header onClick={() => setService('')}>CARE & TUTORING SERVICES</Accordion.Header>
                                            <Accordion.Body>
                                                <div className='wrapper'>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="radio" name="radioGroup" id="care_services"
                                                            checked={service === 'care_services' ? true : false}
                                                            onChange={() => handleService('care_services')} />
                                                        <label className="form-check-label" htmlFor="care_services">Care Services</label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="radio" name="radioGroup" id="tutoring"
                                                            checked={service === 'tutoring' ? true : false}
                                                            onChange={() => handleService('tutoring')} />
                                                        <label className="form-check-label" htmlFor="tutoring">Tutoring (Lesson) services</label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="radio" name="radioGroup" id="counseling"
                                                            checked={service === 'counseling' ? true : false}
                                                            onChange={() => handleService('counseling')} />
                                                        <label className="form-check-label" htmlFor="counseling">Counseling Services</label>
                                                    </div>
                                                </div>

                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="5">
                                            <Accordion.Header onClick={() => setService('')}>BUSINESS SERVICE & REAL ESTATE</Accordion.Header>
                                            <Accordion.Body>
                                                <div className='wrapper'>
                                                    <div className="col-12 form-check">
                                                        <input className="form-check-input" type="radio" name="radioGroup" id="estatePlanning"
                                                            checked={service === 'estate_planning' ? true : false}
                                                            onChange={() => handleService('estate_planning')} />
                                                        <label className="form-check-label" htmlFor="estatePlanning">Estate Planning (Lawyer & Real Estate)</label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="radio" name="radioGroup" id="financing"
                                                            checked={service === 'financing' ? true : false}
                                                            onChange={() => handleService('financing')} />
                                                        <label className="form-check-label" htmlFor="financing">Financial & Accounting Services</label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="radio" name="radioGroup" id="insurance"
                                                            checked={service === 'insurance' ? true : false}
                                                            onChange={() => handleService('insurance')} />
                                                        <label className="form-check-label" htmlFor="insurance">Insurance</label>
                                                    </div>
                                                    <div className="col-12 form-check">
                                                        <input className="form-check-input" type="radio" name="radioGroup" id="realEstate"
                                                            checked={service === 'real_state' ? true : false}
                                                            onChange={() => handleService('real_state')} />
                                                        <label className="form-check-label" htmlFor="realEstate">Real Estate (Apartment Rental)</label>
                                                    </div>
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="6">
                                            <Accordion.Header onClick={() => setService('')}>CONSTRUCTION & REMODELING</Accordion.Header>
                                            <Accordion.Body>
                                                <div className='wrapper'>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="radio" name="radioGroup" id="landScaping"
                                                            checked={service === 'landscaping' ? true : false}
                                                            onChange={() => handleService('landscaping')} />
                                                        <label className="form-check-label" htmlFor="landScaping">Landscaping (Lawn Mow) Services</label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="radio" name="radioGroup" id="remodeling"
                                                            checked={service === 'remodling' ? true : false}
                                                            onChange={() => handleService('remodling')} />
                                                        <label className="form-check-label" htmlFor="remodeling">Remodeling</label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="radio" name="radioGroup" id="electricalService"
                                                            checked={service === 'electrical' ? true : false}
                                                            onChange={() => handleService('electrical')} />
                                                        <label className="form-check-label" htmlFor="electricalService">Electrical Services</label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="radio" name="radioGroup" id="plumbing"
                                                            checked={service === 'plumbing' ? true : false}
                                                            onChange={() => handleService('plumbing')} />
                                                        <label className="form-check-label" htmlFor="plumbing">Plumbing</label>
                                                    </div>
                                                    <div className="col-12 form-check">
                                                        <input className="form-check-input" type="radio" name="radioGroup" id="heatingVentilation"
                                                            checked={service === 'heating_ventilation' ? true : false}
                                                            onChange={() => handleService('heating_ventilation')} />
                                                        <label className="form-check-label" htmlFor="heatingVentilation">Heating, Ventilation & Air conditioning</label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="radio" name="radioGroup" id="Painting"
                                                            checked={service === 'painting' ? true : false}
                                                            onChange={() => handleService('painting')} />
                                                        <label className="form-check-label" htmlFor="Painting">Painting</label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="radio" name="radioGroup" id="Roofing"
                                                            checked={service === 'roofing' ? true : false}
                                                            onChange={() => handleService('roofing')} />
                                                        <label className="form-check-label" htmlFor="Roofing">Roofing</label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="radio" name="radioGroup" id="concrete"
                                                            checked={service === 'concrete' ? true : false}
                                                            onChange={() => handleService('concrete')} />
                                                        <label className="form-check-label" htmlFor="concrete">Concrete</label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="radio" name="radioGroup" id="tree_shrubs"
                                                            checked={service === 'tree_shrubs' ? true : false}
                                                            onChange={() => handleService('tree_shrubs')} />
                                                        <label className="form-check-label" htmlFor="tree_shrubs">Tree and Shrubs Services</label>
                                                    </div>
                                                </div>

                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="7">
                                            <Accordion.Header onClick={() => setService('')}>SHOPPING</Accordion.Header>
                                            <Accordion.Body>
                                                <div className='wrapper'>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="radio" name="radioGroup" id="grocery"
                                                            checked={service === 'grocery' ? true : false}
                                                            onChange={() => handleService('grocery')} />
                                                        <label className="form-check-label" htmlFor="grocery">Grocery</label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="radio" name="radioGroup" id="homeMaintenace"
                                                            checked={service === 'home_maintenace' ? true : false}
                                                            onChange={() => handleService('home_maintenace')} />
                                                        <label className="form-check-label" htmlFor="homeMaintenace">Home Maintenance store</label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="radio" name="radioGroup" id="furnitureStore"
                                                            checked={service === 'furniture_store' ? true : false}
                                                            onChange={() => handleService('furniture_store')} />
                                                        <label className="form-check-label" htmlFor="furnitureStore">Furniture Store</label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="radio" name="radioGroup" id="wineStore"
                                                            checked={service === 'wine_store' ? true : false}
                                                            onChange={() => handleService('wine_store')} />
                                                        <label className="form-check-label" htmlFor="wineStore">Wine Store</label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="radio" name="radioGroup" id="shopping"
                                                            checked={service === 'shopping' ? true : false}
                                                            onChange={() => handleService('shopping')} />
                                                        <label className="form-check-label" htmlFor="shopping">Shopping</label>
                                                    </div>
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </div>
                            </div> */}
                            {/* request a quote subcategory section */}
                            {renderServicePage()}
                            <div className="element-wrapper mt-3" ref={ref}>

                                {/* Auto Repair Section */}
                                {service === 'auto_repair' ?
                                    <AutoRepair businessDetails={state} /> : ''
                                }
                                {/* Auto Dealership Section */}
                                {service === 'auto_dealership' ?
                                    <AutoDealership businessDetails={state} /> : ''
                                }

                                {/* Auto Detailing Section */}
                                {/* {service === 'auto_detailing' ?
                                    <AutoDetailing businessDetails={state}/> : ''
                                } */}
                                {/* Catering Service Section */}
                                {requestQuoteService === 'catering_service' ?
                                    <CateringServices businessDetails={state} />
                                    : ''}
                                {/* Restaurant And Event Service Section */}
                                {service === 'restaurant' ?
                                    <RestaurantAndEvent businessDetails={state} /> : ''
                                }

                                {/* Entertainment and Eventplaning Service Section */}
                                {service === 'entertainment_eventplaning' ?
                                    <Entertainment businessDetails={state} /> : ''
                                }

                                {/* Photography and videography Section */}
                                {service === 'photography_videography' ?
                                    <Photography businessDetails={state} />
                                    : ''
                                }

                                {/* Photography and videography Section */}
                                {service === 'flower_plant' ?
                                    <FlowerAndPlant businessDetails={state} />
                                    : ''
                                }

                                {/* Cleaning Services Section */}
                                {
                                    service === 'cleaning_service' ?
                                        <CleaningServices businessDetails={state} /> : ''
                                }
                                {/* moving service */}
                                {
                                    service === 'moving_companies' ?
                                        <MovingCompony businessDetails={state} /> : ''
                                }



                                {/* Landscaping Section */}
                                {
                                    service === 'landscaping' ?
                                        <Landscaping businessDetails={state} /> : ''
                                }
                                {/* Remodling Section */}
                                {
                                    service === 'remodling' ?
                                        <Remodeling businessDetails={state} /> : ''
                                }

                                {/* Ride Taxi Service Section */}
                                {
                                    service === 'rideService' ?
                                        <RideTaxi businessDetails={state} />
                                        : ''
                                }
                                {/* Delivery Service Section */}
                                {
                                    service === 'deliveryService' ?
                                        <DeliveryService businessDetails={state} />
                                        : ''
                                }

                                {/* Real State Section */}
                                {service === 'real_state' ?
                                    <RealState businessDetails={state} />
                                    : ''
                                }

                                {/* Other Services Section */}
                                {
                                    service === 'other' ?
                                        <OtherServices businessDetails={state} />
                                        : ''
                                }
                                {/* <OtherServices /> */}



                                {/* Insurance Section */}
                                {/* <Insurance /> */}



                                {/* Plumbing Section */}
                                {/* <Plumbing /> */}

                                {/* Electrician Section */}
                                {/* <Electrician /> */}

                                {/* Heating Cooling & Ventilation Section */}
                                {/* <HeatingCoolingVentilation /> */}

                                {/* Painting Section */}
                                {/* <Painting /> */}

                                {/* Roofing Section */}
                                {/* <Roofing /> */}

                                {/* Roofing Section */}
                                {/* <Handyman /> */}
                            </div>

                        </form>
                    </div>
                </div >
            </div>
        </>
    )
}
