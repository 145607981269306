import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Helmet } from "react-helmet";
import { withSnackbar } from 'react-simple-snackbar'
import * as actions from '../store/actions/index';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactLoading from 'react-loading';
import DatePicker from "react-datepicker";
import { useNavigate } from 'react-router-dom';
import Autocomplete from 'react-google-autocomplete';
import Geocode from "react-geocode";
Geocode.setApiKey("AIzaSyB_KZHG7uYGsaMGdQLDRrV3Qw6xRvLqg_Q");
Geocode.enableDebug();


const PartyType = ['Wedding', 'Birthday', 'Shower', 'Party', 'Anniversary', 'Get-together', 'Other'];
const LocationForEvent = ['Conference hall', 'Bar and nightclub', 'Banquet hall', 'Breweries and wineries', 'Hotel', 'sport clubs', 'Other'];
const ServiceType = ['Videography', 'DJ', 'Hair & makeup artist', 'Aerial drone video', 'Wedding officiant', 'Floral arrangement', 'Wedding planning',
    'Photography', 'Dancer', 'Comedy', 'Slot machine'];
const EquipmentNeeded = ['Arch/Arbor', 'Plates', 'Chairs', 'Tents', 'Drape', 'Lighting', 'Limousine', 'Linens', 'Lounge furniture collections',
    'Monograms', 'Photo booths', 'Tables', 'Trash can', 'Tableware', 'Glassware', 'Barbecue equipment', 'Silverware', 'Kneeling bench', 'Fans', 'Heater',
    'Gaming equipment', 'Oven', 'Not sure'];
const GuestNumber = ['Less than 150', 'Between 151 to 300', 'Above 301', 'Other']
const ContactWay = ['Phone Call', 'Video call', 'Text', 'Message', 'Email', 'Chat', 'Other'];

export const Entertainment = (props) => {
    let customerInfo = JSON.parse(localStorage.getItem('userInfo'));
    let navigate = useNavigate();
    const [typeOfParty, setTypeOfParty] = useState('');
    const [otherTypeOfParty, setOtherTypeOfParty] = useState(false);
    const [eventLocation, setEventLocation] = useState('');
    const [otherEventLocation, setOtherEventLocation] = useState(false);
    const [bringFoodStatus, setBringFoodStatus] = useState('');
    const [service, setService] = useState([]);
    const [equipment, setEquipment] = useState([]);
    const [guest, setGuest] = useState('');
    const [otherGuest, setOtherGuest] = useState(false);
    const [appointDate, setAppointDate] = useState(new Date());
    const [startingTime, setStartingTime] = useState('');
    const [closingTime, setClosingTime] = useState('');
    const [contactMode, setContactMode] = useState('');
    const [otherContactMode, setOtherContactMode] = useState(false);
    const [otherInfo, setOtherInfo] = useState('');
    const [eventVenueLocation, setEventVenueLocation] = useState('');

    const handlePartType = (value) => {
        if (value === 'Other') {
            setOtherTypeOfParty(true);
            setTypeOfParty('')
        } else {
            setOtherTypeOfParty(false);
            setTypeOfParty(value)
        }
    }
    const handleEventLocation = (value) => {
        if (value === 'Other') {
            setOtherEventLocation(true);
            setEventLocation('')
        } else {
            setOtherEventLocation(false);
            setEventLocation(value)
        }
    }
    const handleService = (value) => {
        let updatedService = [...service]
        let IndexValue = updatedService.indexOf(value)
        if (updatedService.includes(value)) {
            updatedService.splice(IndexValue, 1)
            setService(updatedService)
        } else {
            updatedService.push(value)
            setService(updatedService)
        }
    }
    const handleEquipment = (value) => {
        let updatedEquipment = [...equipment]
        let IndexValue = updatedEquipment.indexOf(value)
        if (updatedEquipment.includes(value)) {
            updatedEquipment.splice(IndexValue, 1)
            setEquipment(updatedEquipment)
        } else {
            updatedEquipment.push(value)
            setEquipment(updatedEquipment)
        }
    }
    const handleGuest = (value) => {
        if (value === 'Other') {
            setOtherGuest(true);
            setGuest('')
        } else {
            setOtherGuest(false);
            setGuest(value)
        }
    }

    const handleTimeChange = (event, type) => {
        var timeSplit = event.target.value.split(':'),
            hours,
            minutes,
            hours = timeSplit[0];
        minutes = timeSplit[1];
        // Check whether AM or PM
        let newformat = hours >= 12 ? 'PM' : 'AM';
        // Find current hour in AM-PM Format
        hours = hours % 12;

        // To display "0" as "12"
        hours = hours ? hours : 12;
        // minutes = minutes < 10 ? '0' + minutes : minutes;
        let selectedTime = hours + ':' + minutes + ' ' + newformat
        if (type === 'start') {
            setStartingTime(selectedTime)
        } else {
            setClosingTime(selectedTime)
        }
    }
    const handleLocationSelection = (place, type) => {
        const lat = place.geometry.location.lat();
        const lng = place.geometry.location.lng();
        fetch('https://maps.googleapis.com/maps/api/geocode/json?latlng=' + lat + ',' + lng + '&key=AIzaSyB_KZHG7uYGsaMGdQLDRrV3Qw6xRvLqg_Q', {
            method: 'GET'
        }).then((response) => response.json())
            .then((data) => {
                if (data.results[1]) {
                    const address = data.results[0].formatted_address
                    setEventVenueLocation(address)
                }
            })
            .catch((error) => {
                toast.error(error, {
                    autoClose: 700,
                    hideProgressBar: true,
                })
            })
    };
    const handleContactMode = (value) => {
        if (value === 'Other') {
            setOtherContactMode(true)
            setContactMode('')
        } else {
            setOtherContactMode(false)
            setContactMode(value)
        }
    }
    //  submit
    const handleSubmit = (e) => {
        e.preventDefault()
        if (typeOfParty && bringFoodStatus && service.length !== 0 && guest && eventLocation &&
            startingTime && closingTime && appointDate && eventVenueLocation && contactMode) {
            let UsDate = new Date(appointDate).toLocaleDateString("en-US")
            let quoteDetails = {
                "Requested Service": "Entertainment And Events Planning",
                "What type of event are you planning?": typeOfParty,
                "Select the event location?": eventLocation,
                "Will you be bringing outside food?": bringFoodStatus,
                "Select Your service": service,
                "Numbers of guest attending": guest,
                "Where is the event venue Address?": eventVenueLocation,
                'What is the best way to contact you?':contactMode,
                "When do you need the service?": UsDate,
                "Starting time": startingTime,
                "Closing time": closingTime
            }
            if (equipment.length > 0) {
                quoteDetails['Equipment needed '] = equipment
            }
            if (otherInfo) {
                quoteDetails["Any other details"] = otherInfo
            }
            let mobileNo = customerInfo.country_code + customerInfo.mobile_no
            let customerName = customerInfo.name;
            let customerEmail = customerInfo.email;
            let data = {
                mobileNo: mobileNo,
                customerName: customerName,
                customerEmail: customerEmail,
                businessName: props.businessDetails.businessName,
                businessEmail: props.businessDetails.email,
                businessId: props.businessDetails.businessId,
                clickType: 'requestQuote',
                quoteDetails: quoteDetails
            }
            props.clickPerCount(data, toast, navigate)

        } else {
            toast.warning('Please Select All required field!', {
                autoClose: 700,
                hideProgressBar: true,
            })
        }
    }
    return (
        <div id="Entertainment" className='card shadow-sm py-4 py-md-5 px-3 px-md-5 mt-4'>
            <Helmet>
                <title>Request Quote For Entertainment And Events Planning</title>
            </Helmet>
            <ToastContainer />
            <div className="section-label fs-3 mb-4">Entertainment and Events Planning</div>
            <div className="mb-3">
                <label className="form-label fs-6 mb-2">What type of event are you planning?</label>
                <div className="d-flex flex-wrap g-2">
                    {
                        PartyType.map((partyType) => (
                            <div className="form-check me-3" key={partyType}>
                                <input className="form-check-input" type="radio" name="radioGroupForPartyType" id={partyType} required
                                    onClick={() => handlePartType(partyType)} />
                                <label className="form-check-label" htmlFor={partyType}>{partyType}</label>
                            </div>
                        ))
                    }
                </div>
                {
                    otherTypeOfParty ?
                        <input type="text" className="form-control mt-2" id="otherParty" placeholder='Other type of party...' required />
                        : ''
                }
            </div>
            <div className="mb-3">
                <label className="form-label fs-6 mb-2">Select the event location?</label>
                <div className="d-flex flex-wrap g-2">
                    {
                        LocationForEvent.map((event) => (
                            <div className="form-check me-3" key={event}>
                                <input className="form-check-input" type="radio" name="radioGroupForEventLocation" id={event} required
                                    onClick={() => handleEventLocation(event)} />
                                <label className="form-check-label" htmlFor={event}>{event}</label>
                            </div>
                        ))
                    }
                </div>
                {
                    otherEventLocation ?
                        <input type="text" className="form-control mt-2" id="otherEventLocation" placeholder='Other event location...' required />
                        : ''
                }
            </div>
            <div className="mb-3">
                <label htmlFor="chooseBringingFood" className="form-label fs-6 mb-2">Will you be bringing outside food?</label>
                <div className="d-flex flex-wrap g-2">
                    <div className="form-check me-3">
                        <input className="form-check-input" type="radio" name="radioGroupForBringingFood" id='bringingFoodYes' required
                            onClick={() => setBringFoodStatus('Yes')} />
                        <label className="form-check-label" htmlFor='bringingFoodYes'>Yes</label>
                    </div>
                    <div className="form-check me-3">
                        <input className="form-check-input" type="radio" name="radioGroupForBringingFood" id='bringingFoodNo' required
                            onClick={() => setBringFoodStatus('No')} />
                        <label className="form-check-label" htmlFor='bringingFoodNo'>No</label>
                    </div>
                </div>
            </div>
            <div className="mb-3">
                <label className="form-label fs-6 mb-2">Select your service</label>
                <div className="d-flex flex-wrap g-2">
                    {
                        ServiceType.map((service) => (
                            <div className="form-check me-3" key={service}>
                                <input className="form-check-input" type="checkbox" name="checkGroupService" id={service} required
                                    onChange={() => handleService(service)} />
                                <label className="form-check-label" htmlFor={service}>{service}</label>
                            </div>
                        ))
                    }

                </div>
            </div>
            <div className="mb-3">
                <label className="form-label fs-6 mb-2">Select your equipment needed (optional)</label>
                <div className="d-flex flex-wrap g-2">
                    {
                        EquipmentNeeded.map((equipment) => (
                            <div className="form-check me-3" key={equipment}>
                                <input className="form-check-input" type="checkbox" name="checkGroupEquipment" id={equipment} required
                                    onChange={() => handleEquipment(equipment)} />
                                <label className="form-check-label" htmlFor={equipment}>{equipment}</label>
                            </div>
                        ))
                    }

                </div>
            </div>
            <div className="mb-3">
                <div className="form-label fs-6 mb-2">Numbers of guest attending</div>
                <div className="d-flex flex-wrap g-2">
                    {
                        GuestNumber.map((guest) => (
                            <div className="form-check me-3" key={guest}>
                                <input className="form-check-input" type="radio" name="radioGroupFoeGuest" id={guest} required
                                    onClick={() => handleGuest(guest)} />
                                <label className="form-check-label" htmlFor={guest}>{guest}</label>
                            </div>
                        ))
                    }
                </div>
                {
                    otherGuest ?
                        <input type="text" className="form-control mt-2" id="otherGuest" placeholder='Other...' required
                            onChange={(e) => setGuest(e.target.value)} /> : ''
                }

            </div>
            <div className="mb-3">
                <label htmlFor="eventVenueLocation" className="form-label fs-6 mb-2">Where is the event venue location?</label>
                <Autocomplete
                    className="form-control"
                    placeholder='Please enter event venue address...'
                    onPlaceSelected={(e) => handleLocationSelection(e)}
                    options={{
                        fields: ["formatted_address", "geometry", "name"],
                        strictBounds: false,
                        types: [],
                    }}
                />
            </div>
            <div className="mb-3">
                <label htmlFor="chooseContactmode" className="form-label fs-6 mb-2">What is the best way to contact you?</label>
                <div className="d-flex flex-wrap g-2">
                    {
                        ContactWay.map((cntct) => (
                            <div className="form-check me-3" key={cntct}>
                                <input className="form-check-input" type="radio" name="radioGroupForcontact" id={cntct}
                                    onClick={() => handleContactMode(cntct)} />
                                <label className="form-check-label" htmlFor={cntct}>{cntct}</label>
                            </div>
                        ))
                    }
                </div>
                {
                    otherContactMode ?
                        <input type="text" className="form-control mt-2" id="otherContactMode" placeholder='Other contact...' required
                            onChange={(e) => setContactMode(e.target.value)} />
                        : ''
                }
            </div>
            <div className="mb-3">
                <label htmlFor="functionDate" className="form-label mb-2">When do you need the service?</label>
                <div className='date-picker-wrapper'>
                    <DatePicker showIcon className="form-control w-100" id="appointmentDate" selected={appointDate}
                        onChange={(value) => setAppointDate(value)} minDate={new Date()}
                    />
                </div>
            </div>
            <div className='row'>
                <div className="col-6 mb-3">
                    <label htmlFor="startingTime" className="form-label fs-6 mb-2">Select starting time</label>
                    <input type="time" id="startingTime" className="form-control" min="09:00" max="18:00" required
                        onChange={(e) => handleTimeChange(e, 'start')} />
                </div>
                <div className="col-6 mb-3">
                    <label htmlFor="closingTime" className="form-label fs-6 mb-2">Select Closing time</label>
                    <input type="time" id="closingTime" className="form-control" min="09:00" max="18:00" required
                        onChange={(e) => handleTimeChange(e, 'close')} />
                </div>
            </div>
            <div className="mb-4">
                <label htmlFor="otherInfo" className="form-label mb-2">Any other information</label>
                <textarea className="form-control" id="otherInfo" rows="4" placeholder='Write your other info...' required
                    onChange={(e) => setOtherInfo(e.target.value)}></textarea>
            </div>
            <div className="btn-wrapper mt-2">
                <button type="submit" className="w-100 btn btn-primary" onClick={(e) => handleSubmit(e)}>Submit</button>
            </div>
            {
                props.loading ?
                    <div className='position-fixed top-0 start-0 bottom-0 end-0 d-flex align-items-center justify-content-center w-100 h-100 bg-black bg-opacity-50'
                        style={{ zIndex: 1030 }}>
                        <ReactLoading type={'spin'} color={'#fff'} height={64} width={64} />
                    </div>
                    : ''
            }
        </div>
    )
}


const mapPropsToState = (state) => {
    return {
        loading: state.business.loading,
        error: state.business.error,
        success: state.business.success,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        clickPerCount: (data, toast, navigate) => dispatch(actions.clickPerCount(data, toast, navigate)),
    }
}

export default connect(
    mapPropsToState,
    mapDispatchToProps
)(withSnackbar(Entertainment))
