import React, { useState } from 'react'
import { useEffect, useRef } from 'react';
import { Helmet } from "react-helmet";
import { connect } from 'react-redux'
import { withSnackbar } from 'react-simple-snackbar'
import * as actions from '../store/actions/index';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactLoading from 'react-loading';
import DatePicker from "react-datepicker";
import { useNavigate } from 'react-router-dom';
import { Card } from 'react-bootstrap';

const Option = ['Flower', 'Plant', 'Gift', 'Cards'];
const FlowerArrangeType = ['Flower bouquets', 'Roses', 'Plant', 'Gift', 'Vases', 'not sure', 'Other'];
const Occasion = ['Birthday', 'Anniversary', 'Romance', 'Thank you', 'Congratulations', 'Get well', 'Wedding', 'Thinking of you',
    'Sympathy & Funeral', 'Not sure', 'Other'];
const Flower = ['Sunflower', 'Tulips', 'Mums', 'Alstroemerias', 'Hydrangeas', 'Roses', 'Lilies', 'Baby’s breath', 'Carnations', 'Greenery', 'Mixed bouquet', 'Artificial flowers', 'Not sure', 'Other']
const Service = ['Flower bar', 'Party Favors', 'Selfie wall', 'Not sure', 'Other']
const Cards = ['Achievement cards', 'Business birthday cards', 'Anniversary cards', 'Thank you cards', 'Retirement Cards', 'Get Well cards', 'Sympathy Cards', 'Not Sure', 'Other']
const Plants = ['Sympathy Plant', 'Orchids', 'Easter Plants', 'Succulents', 'Flowering Plants', 'Bonsai Plants', 'Green Plants', 'Dish Plants', 'Not sure', 'Other']
const GiftBasket = ['Wine Gift baskets', 'Baked Goods', 'Teddy Bears', 'Balloon Bouquets', 'Kosher gifts', 'Chocolate covered gift', 'Gourmet gift baskets', 'Easter gift basket', 'Fruit gift basket', 'Not sure', 'Other']
const ContactWay = ['Phone Call', 'Video call', 'Text', 'Message', 'Email', 'Chat', 'Other'];


const FlowerAndPlant = (props) => {
    let customerInfo = JSON.parse(localStorage.getItem('userInfo'));
    let navigate = useNavigate();
    const [chooseOption, setChooseOption] = useState([]);
    const [otherChooseOption, setOtherChooseOption] = useState(false)
    const [flowerArrange, setFlowerArrange] = useState('');
    const [otherFlowerArrange, setOtherFlowerArrange] = useState(false)
    const [occasionType, setOccasionType] = useState('');
    const [otherOccasionType, setOtherOccasionType] = useState(false)
    const [flowerType, setFlowerType] = useState('');
    const [otherFlowerType, setOtherFlowerType] = useState(false)
    const [serviceType, setServiceType] = useState('');
    const [otherServiceType, setOtherServiceType] = useState(false)
    const [cardType, setCardType] = useState('');
    const [otherCardType, setOtherCardType] = useState(false)
    const [plantType, setPlantType] = useState('');
    const [otherPlantType, setOtherPlantType] = useState(false);
    const [giftBasketType, setGiftbasketType] = useState('');
    const [otherGiftBasketType, setOtherGiftBasketType] = useState(false);
    const [appointDate, setAppointDate] = useState(new Date());
    const [startingTime, setStartingTime] = useState('');
    const [closingTime, setClosingTime] = useState('');
    const [contactMode, setContactMode] = useState('');
    const [deliveryPickupStatus, setDeliveryPickupStatus] = useState('');
    const [otherContactMode, setOtherContactMode] = useState(false);
    const [otherInfo, setOtherInfo] = useState('');

    const handleOption = (value) => {
        // if (value === 'Other') {
        //     setChooseOption(true)
        //     setOtherChooseOption('')
        // } else {
        //     setChooseOption(false)
        //     setOtherChooseOption(value)
        // }
        let updatedOption = [...chooseOption]
        let IndexValue = updatedOption.indexOf(value)
        if (updatedOption.includes(value)) {
            updatedOption.splice(IndexValue, 1)
            setChooseOption(updatedOption)
        } else {
            updatedOption.push(value)
            setChooseOption(updatedOption)
        }
    }

    const handleflowerArrangeType = (value) => {
        if (value === 'Other') {
            setOtherFlowerArrange(true)
            setFlowerArrange('')
        } else {
            setOtherFlowerArrange(false)
            setFlowerArrange(value)
        }
    }

    const handleOccasionType = (value) => {
        if (value === 'Other') {
            setOtherOccasionType(true)
            setOccasionType('')
        } else {
            setOtherOccasionType(false)
            setOccasionType(value)
        }
    }

    const handleFlowerType = (value) => {
        if (value === 'Other') {
            setOtherFlowerType(true)
            setFlowerType('')
        } else {
            setOtherFlowerType(false)
            setFlowerType(value)
        }
    }

    const handleServiceType = (value) => {
        if (value === 'Other') {
            setOtherServiceType(true)
            setServiceType('')
        } else {
            setOtherServiceType(false)
            setServiceType(value)
        }
    }

    const handleCardType = (value) => {
        if (value === 'Other') {
            setOtherCardType(true)
            setCardType('')
        } else {
            setOtherCardType(false)
            setCardType(value)
        }
    }

    const handlePlantType = (value) => {
        if (value === 'Other') {
            setOtherPlantType(true)
            setPlantType('')
        } else {
            setOtherPlantType(false)
            setPlantType(value)
        }
    }
    const handleGiftbasketType = (value) => {
        if (value === 'Other') {
            setOtherGiftBasketType(true)
            setGiftbasketType('')
        } else {
            setOtherGiftBasketType(false)
            setGiftbasketType(value)
        }
    }


    const handleContactMode = (value) => {
        if (value === 'Other') {
            setOtherContactMode(true)
            setContactMode('')
        } else {
            setOtherContactMode(false)
            setContactMode(value)
        }
    }
    const handleTimeChange = (event, type) => {
        var timeSplit = event.target.value.split(':'),
            hours,
            minutes,
            hours = timeSplit[0];
        minutes = timeSplit[1];
        // Check whether AM or PM
        let newformat = hours >= 12 ? 'PM' : 'AM';
        // Find current hour in AM-PM Format
        hours = hours % 12;

        // To display "0" as "12"
        hours = hours ? hours : 12;
        // minutes = minutes < 10 ? '0' + minutes : minutes;
        let selectedTime = hours + ':' + minutes + ' ' + newformat
        if (type === 'start') {
            setStartingTime(selectedTime)
        } else {
            setClosingTime(selectedTime)
        }
    }

    //  submit
    const handleSubmit = (e) => {
        e.preventDefault()
        if (chooseOption.length > 0 && occasionType && appointDate && contactMode && deliveryPickupStatus && serviceType) {
            let UsDate = new Date(appointDate).toLocaleDateString("en-US")
            let quoteDetails = {
                "Requested Service": "Flower and Plant",
                "Select the one you need": chooseOption,
                "Select the occasion you want to celebrate?": occasionType,
                "Are you interested in any of these services?": serviceType
            }

            if (chooseOption.includes('Flower')) {
                if (flowerArrange && flowerType) {
                    quoteDetails['How do you want the flower arrangement?'] = flowerArrange;
                    quoteDetails['Select the type of flower'] = flowerType
                } else {
                    toast.warning('Please Select All required field!', {
                        autoClose: 700,
                        hideProgressBar: true,
                    })
                }
            }
            if (chooseOption.includes('Cards')) {
                if (cardType) {
                    quoteDetails['What type of card do you need for this occasion?'] = cardType;
                } else {
                    toast.warning('Please Select All required field!', {
                        autoClose: 700,
                        hideProgressBar: true,
                    })
                }
            }
            if (chooseOption.includes('Plant')) {
                if (plantType) {
                    quoteDetails['What type of Plant do you need for your occasion?'] = plantType;
                } else {
                    toast.warning('Please Select All required field!', {
                        autoClose: 700,
                        hideProgressBar: true,
                    })
                }
            }
            if (chooseOption.includes('Gift')) {
                if (giftBasketType) {
                    quoteDetails['What type of gift basket do you need for this occasion?'] = giftBasketType;
                } else {
                    toast.warning('Please Select All required field!', {
                        autoClose: 700,
                        hideProgressBar: true,
                    })
                }
            }
            quoteDetails['How do we contact you?'] = contactMode
            quoteDetails['Delivery or pick up'] = deliveryPickupStatus
            quoteDetails["When do you need the service?"] = UsDate
            quoteDetails['What time do you start the occasion?'] = startingTime
            quoteDetails['What time do you end the occasion?'] = closingTime

            if (otherInfo) {
                quoteDetails["Any other details"] = otherInfo
            }
            let mobileNo = customerInfo.country_code + customerInfo.mobile_no
            let customerName = customerInfo.name;
            let customerEmail = customerInfo.email;
            let data = {
                mobileNo: mobileNo,
                customerName: customerName,
                customerEmail: customerEmail,
                businessName: props.businessDetails.businessName,
                businessEmail: props.businessDetails.email,
                businessId: props.businessDetails.businessId,
                clickType: 'requestQuote',
                quoteDetails: quoteDetails
            }
            props.clickPerCount(data, toast, navigate)

        } else {
            toast.warning('Please Select All required field!', {
                autoClose: 700,
                hideProgressBar: true,
            })
        }
    }


    return (
        <div id="photography" className='photography-sec card shadow-sm py-4 py-md-5 px-3 px-md-5 mt-4'>
            <Helmet>
                <title>Request Quote For Flower and Plant</title>
            </Helmet>
            <ToastContainer />
            <div className="section-label fs-3 mb-4">Flower and Plant</div>
            <div className="mb-3">
                <label className="form-label fs-6 mb-2">Select the one you need</label>
                <div className="d-flex flex-wrap">
                    {
                        Option.map((option) => (
                            <div className="form-check form-check-inline" key={option}>
                                <input className="form-check-input" type="checkbox" name="checkGroupForGuest" id={option} required
                                    onClick={() => handleOption(option)} />
                                <label className="form-check-label" htmlFor={option}>{option}</label>
                            </div>
                        ))
                    }
                </div>
                {/* {
                    otherChooseOption ?
                        <input type="text" className="form-control mt-2" id="otherOption" placeholder='Other type of service...' required
                            onChange={(e) => setChooseOption(e.target.value)} />
                        : ''
                } */}
            </div>
            <div className="mb-3">
                <label className="form-label fs-6 mb-2">Select the occasion you want to celebrate?</label>
                <div className="d-flex flex-wrap">
                    {Occasion.map((occasion) => (
                        <div className="form-check form-check-inline" key={occasion}>
                            <input className="form-check-input" type="radio" name="radioGroupOccasion" id={occasion} required
                                onClick={() => handleOccasionType(occasion)} />
                            <label className="form-check-label" htmlFor={occasion}>{occasion}</label>
                        </div>
                    ))}
                </div>
                {
                    otherOccasionType ?
                        <input type="text" id="otherOccasion" className="form-control mt-2" placeholder='Enter other occasion...' required
                            onChange={(e) => setOccasionType(e.target.value)}
                        />
                        : ''
                }
            </div>
            {
                chooseOption.includes('Flower') ?
                    <div className="mb-3">
                        <label className="form-label fs-6 mb-2">How do you want the flower arrangement?</label>
                        <div className="d-flex flex-wrap">
                            {
                                FlowerArrangeType.map((flowerArrange) => (
                                    <div className="form-check form-check-inline" key={flowerArrange}>
                                        <input className="form-check-input" type="radio" name="radioGroupForflowerArrange" id={flowerArrange} required
                                            onClick={() => handleflowerArrangeType(flowerArrange)} />
                                        <label className="form-check-label" htmlFor={flowerArrange}>{flowerArrange}</label>
                                    </div>
                                ))
                            }
                        </div>
                        {
                            otherFlowerArrange ?
                                <input type="text" className="form-control mt-2" id="otherFlowerArrange" placeholder='Other type of flower arrange type...' required
                                    onChange={(e) => setFlowerArrange(e.target.value)} />
                                : ''
                        }
                    </div> : ''
            }
            {
                chooseOption.includes('Flower') ?
                    <div className="mb-3">
                        <label className="form-label fs-6 mb-2">Select the type of flower</label>
                        <div className="d-flex flex-wrap">
                            {Flower.map((flower) => (
                                <div className="form-check form-check-inline" key={flower}>
                                    <input className="form-check-input" type="radio" name="radioGroupflower" id={flower} required
                                        onClick={() => handleFlowerType(flower)} />
                                    <label className="form-check-label" htmlFor={flower}>{flower}</label>
                                </div>
                            ))}
                        </div>
                        {
                            otherFlowerType ?
                                <input type="text" id="otherflower" className="form-control mt-2" placeholder='Enter other flower...' required
                                    onChange={(e) => setFlowerType(e.target.value)}
                                />
                                : ''
                        }
                    </div>
                    : ''}
            <div className="mb-3">
                <label className="form-label fs-6 mb-2">Are you interested in any of these services?</label>
                <div className="d-flex flex-wrap">
                    {Service.map((service) => (
                        <div className="form-check form-check-inline" key={service}>
                            <input className="form-check-input" type="radio" name="radioGroupservice" id={service} required
                                onClick={() => handleServiceType(service)} />
                            <label className="form-check-label" htmlFor={service}>{service}</label>
                        </div>
                    ))}
                </div>
                {
                    otherServiceType ?
                        <input type="text" id="otherservice" className="form-control mt-2" placeholder='Enter other service...' required
                            onChange={(e) => setServiceType(e.target.value)}
                        />
                        : ''
                }
            </div>

            {
                chooseOption.includes('Cards') ?
                    <div className="mb-3">
                        <label className="form-label fs-6 mb-2">What type of card do you need for this occasion?</label>
                        <div className="d-flex flex-wrap">
                            {Cards.map((card) => (
                                <div className="form-check form-check-inline" key={card}>
                                    <input className="form-check-input" type="radio" name="radioGroupcard" id={card} required
                                        onClick={() => handleCardType(card)} />
                                    <label className="form-check-label" htmlFor={card}>{card}</label>
                                </div>
                            ))}
                        </div>
                        {
                            otherCardType ?
                                <input type="text" id="othercard" className="form-control mt-2" placeholder='Enter other card...' required
                                    onChange={(e) => setCardType(e.target.value)}
                                />
                                : ''
                        }
                    </div>
                    : ''}
            {
                chooseOption.includes('Plant') ?
                    <div className="mb-3">
                        <label className="form-label fs-6 mb-2">What type of Plant do you need for your occasion?</label>
                        <div className="d-flex flex-wrap">
                            {Plants.map((plant) => (
                                <div className="form-check form-check-inline" key={plant}>
                                    <input className="form-check-input" type="radio" name="radioGroupplant" id={plant} required
                                        onClick={() => handlePlantType(plant)} />
                                    <label className="form-check-label" htmlFor={plant}>{plant}</label>
                                </div>
                            ))}
                        </div>
                        {
                            otherPlantType ?
                                <input type="text" id="otherplant" className="form-control mt-2" placeholder='Enter other plant...' required
                                    onChange={(e) => setPlantType(e.target.value)}
                                />
                                : ''
                        }
                    </div>
                    : ''
            }
            {
                chooseOption.includes('Gift') ?
                    <div className="mb-3">
                        <label className="form-label fs-6 mb-2">What type of gift basket do you need for this occasion?</label>
                        <div className="d-flex flex-wrap">
                            {GiftBasket.map((giftbasket) => (
                                <div className="form-check form-check-inline" key={giftbasket}>
                                    <input className="form-check-input" type="radio" name="radioGroupgiftbasket" id={giftbasket} required
                                        onClick={() => handleGiftbasketType(giftbasket)} />
                                    <label className="form-check-label" htmlFor={giftbasket}>{giftbasket}</label>
                                </div>
                            ))}
                        </div>
                        {
                            otherGiftBasketType ?
                                <input type="text" id="othergiftbasket" className="form-control mt-2" placeholder='Enter other giftbasket...' required
                                    onChange={(e) => setGiftbasketType(e.target.value)}
                                />
                                : ''
                        }
                    </div>
                    : ''}

            <div className="mb-3">
                <label htmlFor="chooseContactmode" className="form-label fs-6 mb-2">How do we contact you?</label>
                <div className="d-flex flex-wrap g-2">
                    {
                        ContactWay.map((cntct) => (
                            <div className="form-check me-3" key={cntct}>
                                <input className="form-check-input" type="radio" name="radioGroupForcontact" id={cntct}
                                    onClick={() => handleContactMode(cntct)} />
                                <label className="form-check-label" htmlFor={cntct}>{cntct}</label>
                            </div>
                        ))
                    }
                </div>
                {
                    otherContactMode ?
                        <input type="text" className="form-control mt-2" id="otherContactMode" placeholder='Other contact...' required
                            onChange={(e) => setContactMode(e.target.value)} />
                        : ''
                }
            </div>
            <div className="mb-3">
                <label htmlFor="chooseDeliveryPickup" className="form-label fs-6 mb-2">Delivery or pick up</label>
                <div className="d-flex flex-wrap g-2">
                    {
                        ['Delivery', 'Pickup'].map((del) => (
                            <div className="form-check me-3" key={del}>
                                <input className="form-check-input" type="radio" name="radioGroupForDelivery" id={del}
                                    onClick={() => setDeliveryPickupStatus(del)} />
                                <label className="form-check-label" htmlFor={del}>{del}</label>
                            </div>
                        ))
                    }
                </div>
            </div>
            <div className="mb-3">
                <label htmlFor="functionDate" className="form-label mb-2">When do you need the service?</label>
                <div className='date-picker-wrapper'>
                    <DatePicker showIcon className="form-control w-100" id="appointmentDate" selected={appointDate}
                        onChange={(value) => setAppointDate(value)} minDate={new Date()}
                    />
                </div>
            </div>

            <div className='row'>
                <div className="col-6 mb-3">
                    <label htmlFor="startingTime" className="form-label fs-6 mb-2">What is the starting time for the occasion?</label>
                    <input type="time" id="startingTime" className="form-control" min="09:00" max="18:00" required
                        onChange={(e) => handleTimeChange(e, 'start')} />
                </div>
                <div className="col-6 mb-3">
                    <label htmlFor="closingTime" className="form-label fs-6 mb-2">What is the closing time for the occasion?</label>
                    <input type="time" id="closingTime" className="form-control" min="09:00" max="18:00" required
                        onChange={(e) => handleTimeChange(e, 'close')} />
                </div>
            </div>
            <div className="mb-4">
                <label htmlFor="otherInfo" className="form-label mb-2">Any other information</label>
                <textarea className="form-control" id="otherInfo" rows="4" placeholder='Write your other info...' required
                    onChange={(e) => setOtherInfo(e.target.value)}></textarea>
            </div>
            <div className="btn-wrapper mt-2">
                <button type="submit" className="w-100 btn btn-primary" onClick={(e) => handleSubmit(e)}>Submit</button>
            </div>
            {
                props.loading ?
                    <div className='position-fixed top-0 start-0 bottom-0 end-0 d-flex align-items-center justify-content-center w-100 h-100 bg-black bg-opacity-50'
                        style={{ zIndex: 1030 }}>
                        <ReactLoading type={'spin'} color={'#fff'} height={64} width={64} />
                    </div>
                    : ''
            }
        </div>
    )
}


const mapPropsToState = (state) => {
    return {
        loading: state.business.loading,
        error: state.business.error,
        success: state.business.success,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        clickPerCount: (data, toast, navigate) => dispatch(actions.clickPerCount(data, toast, navigate)),
    }
}

export default connect(
    mapPropsToState,
    mapDispatchToProps
)(withSnackbar(FlowerAndPlant))