import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Helmet } from "react-helmet";
import { withSnackbar } from 'react-simple-snackbar'
import * as actions from '../store/actions/index';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactLoading from 'react-loading';
import DatePicker from "react-datepicker";
import { useNavigate } from 'react-router-dom';
let Budget = ["$20.90 - $49.99", "$50.00 - $99.99", "$100 - $149.99", "$150 - $199.99", "Not Sure", "Other"];
let PaymentMethod = ["Cash app", "Zelle", "Cash", "Check", "Debit card", "Credit Card", "Not Sure", "Other"];
const ContactWay = ['Phone Call', 'Video call', 'Text', 'Message', 'Email', 'Chat', 'Other'];
const Services = ['Cleaning service', 'Floor cleaning', 'Window cleaning', 'Carpet and rug cleaning',
    'Furniture and upholstery cleaning', 'Commercial cleaning'];
const ServiceNo = ['1', '2', '3', '4', '5', 'Not sure', 'Other'];
const ServiceFrequency = ['One time only', 'Once a week', 'Bi- weekly', 'once a monthly', "Not Sure", "Other"];
const CleaningServices = (props) => {
    let customerInfo = JSON.parse(localStorage.getItem('userInfo'));
    let navigate = useNavigate();
    const [service, setService] = useState('');
    const [serviceNumber, setServiceNumber] = useState('')
    const [otherServiceNumber, setOtherServiceNumber] = useState(false)
    const [propertyCleaning, setPropertyCleaning] = useState('');
    const [otherProperty, setOtherProperty] = useState(false);
    const [cleaningService, setCleaningService] = useState('');
    const [bedroomStatus, setBedroomStatus] = useState();
    const [areaRequired, setAreaRequired] = useState('');
    const [serviceFrequency, setServiceFrequency] = useState('');
    const [otherServiceFrequency, setOtherServiceFrequency] = useState(false);
    const [cleanServiceStatus, setCleanServiceStatus] = useState('');
    const [otherCleanServiceStatus, setOtherCleanServiceStatus] = useState(false);
    const [extraCleanService, setExtraCleanService] = useState('');
    const [otherExtraCleanService, setOtherExtraCleanService] = useState(false);
    const [cleaningProduct, setCleaningProduct] = useState([]);
    const [appointDate, setAppointDate] = useState(new Date());
    const [payment, setPaymentMethod] = useState('');
    const [otherPaymentMethod, setOtherPaymentMethod] = useState(false);
    const [budgetForService, setBudget] = useState('');
    const [otherBudget, setOtherBudget] = useState(false);
    const [contactMode, setContactMode] = useState('');
    const [otherContactMode, setOtherContactMode] = useState(false);
    const [otherInfo, setOtherInfo] = useState('')

    const handleCleaningProperty = (value) => {
        if (value === 'other') {
            setOtherProperty(true)
            setPropertyCleaning('')
        } else {
            setPropertyCleaning(value)
            setOtherProperty(false)
        }
    }
    const handleCleanService = (value) => {
        setCleaningService(value)
        setOtherCleanServiceStatus(false)
    }
    const handleCleanServiceStatus = (value) => {
        if (value === 'other') {
            setOtherCleanServiceStatus(true)
            setCleanServiceStatus('')
        } else {
            setCleanServiceStatus(value)
            setOtherCleanServiceStatus(false)
        }
    }

    const handleExtraCleanService = (value) => {
        if (value === 'other') {
            setOtherExtraCleanService(true)
            setExtraCleanService('')
        } else {
            setExtraCleanService(value)
            setOtherExtraCleanService(false)
        }
    }
    const handleCleaningProduct = (value) => {
        let updatedCleaningProd = [...cleaningProduct]
        if (updatedCleaningProd.includes(value)) {
            let valueIndex = updatedCleaningProd.indexOf(value)
            updatedCleaningProd.splice(valueIndex, 1)
            setCleaningProduct(updatedCleaningProd)
        } else {
            updatedCleaningProd.push(value)
            setCleaningProduct(updatedCleaningProd)
        }
    }
    const handleServiceFrequency = (value) => {
        if (value === 'Other') {
            setOtherServiceFrequency(true)
            setServiceFrequency('')
        } else {
            setOtherServiceFrequency(false)
            setServiceFrequency(value)
        }
    }
    const handleBudget = (value) => {
        if (value === 'Other') {
            setOtherBudget(true)
            setBudget('')
        } else {
            setOtherBudget(false)
            setBudget(value)
        }
    }
    const handlePaymentMethod = (value) => {
        if (value === 'Other') {
            setOtherPaymentMethod(true)
            setPaymentMethod('')
        } else {
            setOtherPaymentMethod(false)
            setPaymentMethod(value)
        }
    }
    const handleContactMode = (value) => {
        if (value === 'Other') {
            setOtherContactMode(true)
            setContactMode('')
        } else {
            setOtherContactMode(false)
            setContactMode(value)
        }
    }
    const handleServiceNo = (value) => {
        if (value === 'Other') {
            setOtherServiceNumber(true)
            setServiceNumber('')
        } else {
            setOtherServiceNumber(false)
            setServiceNumber(value)
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if (service && propertyCleaning && cleaningService && cleanServiceStatus && appointDate && payment
            && budgetForService && contactMode) {
            let UsDate = new Date(appointDate).toLocaleDateString("en-US")
            let quoteDetails = {
                "Requested Service": "Cleaning Service",
                'What type of service do you want?': service,
                "What type of property needs cleaning?": propertyCleaning,
                "What is the best way to contact you?": contactMode,
                "What is your budget for this service?": budgetForService,
                "What is the method of payment?": payment,
                "When do you require this service?": UsDate,
                "Any other details": otherInfo
            }
            let mobileNo = customerInfo.country_code + customerInfo.mobile_no
            let customerName = customerInfo.name;
            let customerEmail = customerInfo.email;
            let data = {
                mobileNo: mobileNo,
                customerName: customerName,
                customerEmail: customerEmail,
                businessName: props.businessDetails.businessName,
                businessEmail: props.businessDetails.email,
                businessId: props.businessDetails.businessId,
                clickType: 'requestQuote',
                quoteDetails: quoteDetails
            }

            if (service === 'Cleaning service') {
                if (cleaningService && extraCleanService && cleaningProduct.length > 0) {
                    quoteDetails["What kind of cleaning service are you looking for?"] = cleaningService
                    quoteDetails["Do you need any of these other services?"] = extraCleanService
                    quoteDetails["Do you require any of these cleaning product types?"] = cleaningProduct
                    if (cleaningService === 'Post Construction Cleaning') {
                        if (propertyCleaning === 'Home') {
                            if (bedroomStatus) {
                                quoteDetails["How many bedrooms are in your home?"] = bedroomStatus
                                props.clickPerCount(data, toast, navigate)
                            } else {
                                toast.warning('Bedroom numbers required!', { autoClose: 700, hideProgressBar: true })
                            }
                        } else {
                            props.clickPerCount(data, toast, navigate)
                        }
                    } else {
                        if (cleanServiceStatus) {
                            quoteDetails[`Service looking for ${cleaningService}`] = cleanServiceStatus;
                            if (propertyCleaning === 'Home') {
                                if (bedroomStatus) {
                                    quoteDetails["How many bedrooms are in your home?"] = bedroomStatus
                                    props.clickPerCount(data, toast, navigate)
                                } else {
                                    toast.warning('Bedroom numbers required!', { autoClose: 700, hideProgressBar: true })
                                }
                            } else {
                                props.clickPerCount(data, toast, navigate)
                            }
                        } else {
                            toast.warning(`${cleaningService} required!`, { autoClose: 700, hideProgressBar: true })
                        }

                    }
                } else {
                    toast.warning('All field required!', { autoClose: 700, hideProgressBar: true })
                }

            } else if (service === 'Window cleaning' || service === 'Carpet and rug cleaning' || service === 'Furniture and upholstery cleaning') {
                if (serviceNumber && serviceFrequency) {
                    quoteDetails["How many in numbers"] = serviceNumber;
                    quoteDetails['How often do you need the service?'] = serviceFrequency;
                    if (propertyCleaning === 'Home') {
                        if (bedroomStatus) {
                            quoteDetails["How many bedrooms are in your home?"] = bedroomStatus
                            props.clickPerCount(data, toast, navigate)
                        } else {
                            toast.warning('Bedroom numbers required!', { autoClose: 700, hideProgressBar: true })
                        }
                    } else {
                        props.clickPerCount(data, toast, navigate)
                    }
                } else {
                    toast.warning('Numbers field and how often service required!', { autoClose: 700, hideProgressBar: true })
                }
            } else if (service === 'Commercial cleaning') {
                if (cleaningService && extraCleanService && cleaningProduct.length > 0 && areaRequired) {
                    quoteDetails["What kind of cleaning service are you looking for?"] = cleaningService
                    quoteDetails["Do you need any of these other services?"] = extraCleanService
                    quoteDetails["Do you require any of these cleaning product types?"] = cleaningProduct;
                    quoteDetails["For commercial cleaning, approximately what is the square footage of the space that needs cleaning?"] = areaRequired;
                    if (cleaningService === 'Post Construction Cleaning') {
                        props.clickPerCount(data, toast, navigate)
                    } else {
                        if (cleanServiceStatus) {
                            quoteDetails[`Service looking for ${cleaningService}`] = cleanServiceStatus;
                            props.clickPerCount(data, toast, navigate)
                        } else {
                            toast.warning(`${cleaningService} required!`, { autoClose: 700, hideProgressBar: true })
                        }
                    }
                } else {
                    toast.warning('All field required!', { autoClose: 700, hideProgressBar: true })
                }
            } else if (service === 'Floor cleaning') {
                if (serviceFrequency) {
                    quoteDetails['How often do you need the service?'] = serviceFrequency;
                    if (propertyCleaning === 'Home') {
                        if (bedroomStatus) {
                            quoteDetails["How many bedrooms are in your home?"] = bedroomStatus
                            props.clickPerCount(data, toast, navigate)
                        } else {
                            toast.warning('Bedroom numbers required!', { autoClose: 700, hideProgressBar: true })
                        }
                    } else {
                        props.clickPerCount(data, toast, navigate)
                    }
                } else {
                    toast.warning('How often need service required!', { autoClose: 700, hideProgressBar: true })
                }

            }
        } else {
            toast.warning('Please Select All required field!', { autoClose: 700, hideProgressBar: true })
        }
    }

    return (
        <div id="cleaningServices" className='card shadow-sm py-4 py-md-5 px-3 px-md-5 mt-4'>
            <Helmet>
                <title>Request Quote For Cleaning Service</title>
            </Helmet>
            <ToastContainer />
            <div className="section-label fs-3 mb-4">Cleaning Service</div>
            <div className="mb-3">
                <label htmlFor="chooseService" className="form-label fs-6 mb-2">What type of service do you want?</label>
                <div className="d-flex flex-wrap g-2">
                    {
                        Services.map((service) => (
                            <div className="form-check me-3" key={service}>
                                <input className="form-check-input" type="radio" name="radioGroupForservices" id={service}
                                    onClick={() => setService(service)} />
                                <label className="form-check-label" htmlFor={service}>{service}</label>
                            </div>
                        ))
                    }
                </div>
            </div>
            {
                (service === 'Window cleaning' || service === 'Carpet and rug cleaning' || service === 'Furniture and upholstery cleaning') ?
                    <div className="mb-3">
                        <label htmlFor="chooseServiceNo" className="form-label fs-6 mb-2">How many in numbers</label>
                        <div className="d-flex flex-wrap g-2">
                            {
                                ServiceNo.map((serviceno) => (
                                    <div className="form-check me-3" key={serviceno}>
                                        <input className="form-check-input" type="radio" name="radioGroupForServiceno" id={serviceno}
                                            onClick={() => handleServiceNo(serviceno)} />
                                        <label className="form-check-label" htmlFor={serviceno}>{serviceno}</label>
                                    </div>
                                ))
                            }
                        </div>
                        {
                            otherServiceNumber ?
                                <input type="text" className="form-control mt-2" id="otherServiceNo" placeholder='Other...' required
                                    onChange={(e) => setServiceNumber(e.target.value)} />
                                : ''
                        }
                    </div>
                    : ""
            }
            {
                (service === 'Floor cleaning' || service === 'Window cleaning' || service === 'Carpet and rug cleaning' || service === 'Furniture and upholstery cleaning') ?
                    <div className="mb-3">
                        <label htmlFor="chooseServiceTime" className="form-label fs-6 mb-2">How often do you need the service?</label>
                        <div className="d-flex flex-wrap g-2">
                            {
                                ServiceFrequency.map((freq) => (
                                    <div className="form-check me-3" key={freq}>
                                        <input className="form-check-input" type="radio" name="radioGroupServiceFrequency" id={freq} required
                                            onClick={() => handleServiceFrequency(freq)} />
                                        <label className="form-check-label" htmlFor={freq}>{freq}</label>
                                    </div>
                                ))
                            }

                        </div>
                        {
                            otherServiceFrequency ?
                                <input type="text" className="form-control mt-2" id="otherServiceFrequency" placeholder='Other service frequency...' required
                                    onChange={(e) => setServiceFrequency(e.target.value)} />
                                : ''
                        }
                    </div>
                    : ''
            }


            <div className="mb-3">
                <label htmlFor="chooseOccasion" className="form-label fs-6 mb-2">What type of property needs cleaning?</label>
                <div className="d-flex flex-wrap g-2">
                    {
                        service !== 'Commercial cleaning' ?
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupForCleningProperty" id="Home" required
                                    onClick={() => handleCleaningProperty('Home')} />
                                <label className="form-check-label" htmlFor="Home">Home</label>
                            </div>
                            : ''
                    }

                    <div className="form-check me-3">
                        <input className="form-check-input" type="radio" name="radioGroupForCleningProperty" id="Office"
                            onClick={() => handleCleaningProperty('Office')} />
                        <label className="form-check-label" htmlFor="Office">Office</label>
                    </div>
                    <div className="form-check me-3">
                        <input className="form-check-input" type="radio" name="radioGroupForCleningProperty" id="Restaurant"
                            onClick={() => handleCleaningProperty('Restaurant')} />
                        <label className="form-check-label" htmlFor="Restaurant">Restaurant</label>
                    </div>
                    <div className="form-check me-3">
                        <input className="form-check-input" type="radio" name="radioGroupForCleningProperty" id="retailShop"
                            onClick={() => handleCleaningProperty('Retail shop')} />
                        <label className="form-check-label" htmlFor="retailShop">Retail shop</label>
                    </div>
                    <div className="form-check me-3">
                        <input className="form-check-input" type="radio" name="radioGroupForCleningProperty" id="other"
                            onClick={() => handleCleaningProperty('other')} />
                        <label className="form-check-label" htmlFor="other">Other</label>
                    </div>
                </div>
                {
                    otherProperty ?
                        <input type="text" className="form-control mt-2" id="chooseDrink" placeholder='Other property for cleaning...' required
                            onChange={(e) => setPropertyCleaning(e.target.value)} />
                        : ''
                }
            </div>
            {
                propertyCleaning === 'Home' ?
                    <div className="mb-3">
                        <label className="form-label fs-6 mb-2">How many bedrooms are in your home?</label>
                        <div className="d-flex flex-wrap g-2">
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupForBedroom" id="1B" required
                                    onClick={() => setBedroomStatus('1 Bedroom')} />
                                <label className="form-check-label" htmlFor="1B">1 Bedroom</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupForBedroom" id="2B"
                                    onClick={() => setBedroomStatus('2 Bedrooms')} />
                                <label className="form-check-label" htmlFor="2B">2 Bedrooms</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupForBedroom" id="3B"
                                    onClick={() => setBedroomStatus('3 Bedrooms')} />
                                <label className="form-check-label" htmlFor="3B">3 Bedrooms</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupForBedroom" id="4B"
                                    onClick={() => setBedroomStatus('4 Bedrooms')} />
                                <label className="form-check-label" htmlFor="4B">4 Bedrooms</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupForBedroom" id="MB"
                                    onClick={() => setBedroomStatus('More bedrooms')} />
                                <label className="form-check-label" htmlFor="MB">More bedrooms</label>
                            </div>
                        </div>
                    </div> : ''
            }


            {
                (service === 'Cleaning service' || service === 'Commercial cleaning') ?
                    <div className="mb-3">
                        <label className="form-label fs-6 mb-2">What kind of cleaning service are you looking for?</label>
                        <div className="d-flex flex-wrap g-2">
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupForCleaningService" id="deepCleaning" required
                                    onClick={() => handleCleanService('Deep cleaning')} />
                                <label className="form-check-label" htmlFor="deepCleaning">Deep cleaning</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupForCleaningService" id="maidService"
                                    onClick={() => handleCleanService('Maid Service')} />
                                <label className="form-check-label" htmlFor="maidService">Maid Service</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupForCleaningService" id="regularCleaning "
                                    onClick={() => handleCleanService('Regular Cleaning')} />
                                <label className="form-check-label" htmlFor="regularCleaning ">Regular Cleaning</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupForCleaningService" id="janitorialService"
                                    onClick={() => handleCleanService('Janitorial Service')} />
                                <label className="form-check-label" htmlFor="janitorialService">Janitorial Service</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupForCleaningService" id="MOC"
                                    onClick={() => handleCleanService('Move-in or Move out cleaning')} />
                                <label className="form-check-label" htmlFor="MOC">Move-in or Move out cleaning</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupForCleaningService" id="PCC"
                                    onClick={() => handleCleanService('Post Construction Cleaning')} />
                                <label className="form-check-label" htmlFor="PCC">Post Construction Cleaning</label>
                            </div>
                        </div>

                        <div className="element-wrapper">
                            {/* If Choosen Deep Cleaning */}
                            {
                                cleaningService === 'Deep cleaning' ?
                                    <div id="Deep_Cleaning" className="mt-3">
                                        <div className="form-label fs-6 mb-2">Deep cleaning</div>
                                        <div className="d-flex flex-wrap g-2">
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForDeepClean" id="Just_once" required
                                                    onClick={() => handleCleanServiceStatus('Just once')} />
                                                <label className="form-check-label" htmlFor="Just_once">Just once</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForDeepClean" id="every_week"
                                                    onClick={() => handleCleanServiceStatus('Every week')} />
                                                <label className="form-check-label" htmlFor="every_week">Every week</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForDeepClean" id="every_2_week"
                                                    onClick={() => handleCleanServiceStatus('Every 2 week')} />
                                                <label className="form-check-label" htmlFor="every_2_week">Every 2 week</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForDeepClean" id="once_a_month"
                                                    onClick={() => handleCleanServiceStatus('Once a month')} />
                                                <label className="form-check-label" htmlFor="once_a_month">Once a month</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForDeepClean" id="not_sure"
                                                    onClick={() => handleCleanServiceStatus('Not sure')} />
                                                <label className="form-check-label" htmlFor="not_sure">Not sure</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForDeepClean" id="OTH"
                                                    onClick={() => handleCleanServiceStatus('other')} />
                                                <label className="form-check-label" htmlFor="OTH">Other</label>
                                            </div>
                                        </div>
                                        {
                                            otherCleanServiceStatus ?
                                                <input type="text" className="form-control mt-2" id="OTH" placeholder='Other Deep Clean...' required
                                                    onChange={(e) => setCleanServiceStatus(e.target.value)} />
                                                : ""
                                        }

                                    </div>
                                    : ''
                            }

                            {/* If Choosen Maid Service  */}
                            {
                                cleaningService === 'Maid Service' ?
                                    <div id="Maid_Service" className="mt-3">
                                        <div className="form-label fs-6 mb-2">Maid Service</div>
                                        <div className="d-flex flex-wrap g-2">
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForMaid" id="Just_once" required
                                                    onClick={() => handleCleanServiceStatus('Just once')} />
                                                <label className="form-check-label" htmlFor="Just_once">Just once</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForMaid" id="every_week"
                                                    onClick={() => handleCleanServiceStatus('Every week')} />
                                                <label className="form-check-label" htmlFor="every_week">Every week</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForMaid" id="every_2_week"
                                                    onClick={() => handleCleanServiceStatus('Every 2 week')} />
                                                <label className="form-check-label" htmlFor="every_2_week">Every 2 week</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForMaid" id="once_a_month"
                                                    onClick={() => handleCleanServiceStatus('Once a month')} />
                                                <label className="form-check-label" htmlFor="once_a_month">Once a month</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForMaid" id="not_sure"
                                                    onClick={() => handleCleanServiceStatus('Not sure')} />
                                                <label className="form-check-label" htmlFor="not_sure">Not sure</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForMaid" id="OTH"
                                                    onClick={() => handleCleanServiceStatus('other')} />
                                                <label className="form-check-label" htmlFor="OTH">Other</label>
                                            </div>
                                        </div>
                                        {
                                            otherCleanServiceStatus ?
                                                <input type="text" className="form-control mt-2" id="OTH" placeholder='Other maid service...' required
                                                    onChange={(e) => setCleanServiceStatus(e.target.value)} />
                                                : ''
                                        }

                                    </div>
                                    : ''
                            }
                            {/* If Choosen Regular Cleaning */}
                            {
                                cleaningService === 'Regular Cleaning' ?
                                    <div id="Regular_Cleaning" className="mt-3">
                                        <div className="form-label fs-6 mb-2">Regular Cleaning</div>
                                        <div className="d-flex flex-wrap g-2">
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForRegular" id="Just_once" required
                                                    onClick={() => handleCleanServiceStatus('Just once')} />
                                                <label className="form-check-label" htmlFor="Just_once">Just once</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForRegular" id="every_week"
                                                    onClick={() => handleCleanServiceStatus('Every week')} />
                                                <label className="form-check-label" htmlFor="every_week">Every week</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForRegular" id="every_2_week"
                                                    onClick={() => handleCleanServiceStatus('Every 2 week')} />
                                                <label className="form-check-label" htmlFor="every_2_week">Every 2 week</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForRegular" id="once_a_month"
                                                    onClick={() => handleCleanServiceStatus('Once a month')} />
                                                <label className="form-check-label" htmlFor="once_a_month">Once a month</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForRegular" id="not_sure"
                                                    onClick={() => handleCleanServiceStatus('Not sure')} />
                                                <label className="form-check-label" htmlFor="not_sure">Not sure</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForRegular" id="OTH"
                                                    onClick={() => handleCleanServiceStatus('other')} />
                                                <label className="form-check-label" htmlFor="OTH">Other</label>
                                            </div>
                                        </div>
                                        {
                                            otherCleanServiceStatus ?
                                                <input type="text" className="form-control mt-2" id="OTH" placeholder='Other regular cleaning...' required
                                                    onChange={(e) => setCleanServiceStatus(e.target.value)} />
                                                : ''
                                        }

                                    </div>
                                    : ''
                            }

                            {/* If Choosen Janitorial Service */}
                            {
                                cleaningService === 'Janitorial Service' ?
                                    <div id="Janitorial_Service" className="mt-3">
                                        <div className="form-label fs-6 mb-2">Janitorial Service</div>
                                        <div className="d-flex flex-wrap g-2">
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForJanitor" id="Apartment" required
                                                    onClick={() => handleCleanServiceStatus('Apartment')} />
                                                <label className="form-check-label" htmlFor="Apartment">Apartment</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForJanitor" id="Condo"
                                                    onClick={() => handleCleanServiceStatus('Condo')} />
                                                <label className="form-check-label" htmlFor="Condo">Condo</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForJanitor" id="farm" required
                                                    onClick={() => handleCleanServiceStatus('Farm')} />
                                                <label className="form-check-label" htmlFor="farm">Farm</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForJanitor" id="House"
                                                    onClick={() => handleCleanServiceStatus('House')} />
                                                <label className="form-check-label" htmlFor="House">House</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForJanitor" id="Land" required
                                                    onClick={() => handleCleanServiceStatus('Land')} />
                                                <label className="form-check-label" htmlFor="Land">Land</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForJanitor" id="mobile_home"
                                                    onClick={() => handleCleanServiceStatus('Mobile home')} />
                                                <label className="form-check-label" htmlFor="mobile_home">Mobile home</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForJanitor" id="multi_family" required
                                                    onClick={() => handleCleanServiceStatus('Multi - family')} />
                                                <label className="form-check-label" htmlFor="multi_family">Multi - family</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForJanitor" id="Town_house"
                                                    onClick={() => handleCleanServiceStatus('Town house')} />
                                                <label className="form-check-label" htmlFor="Town_house">Town house</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForJanitor" id="Not_sure" required
                                                    onClick={() => handleCleanServiceStatus('Not sure')} />
                                                <label className="form-check-label" htmlFor="Not_sure">Not sure</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForJanitor" id="Other"
                                                    onClick={() => handleCleanServiceStatus('other')} />
                                                <label className="form-check-label" htmlFor="Other">Other</label>
                                            </div>
                                        </div>
                                        {
                                            otherCleanServiceStatus ?
                                                <input type="text" className="form-control mt-2" id="chooseDrink" placeholder='Other Janitorial service...' required
                                                    onChange={(e) => setCleanServiceStatus(e.target.value)} />
                                                : ''
                                        }
                                    </div>
                                    : ''
                            }
                            {/* If Choosen Move-in or Move out cleaning */}
                            {
                                cleaningService === 'Move-in or Move out cleaning' ?
                                    <div id="MIMOC" className="mt-3">
                                        <div className="form-label fs-6 mb-2">Move-in or Move out cleaning</div>
                                        <div className="d-flex flex-wrap g-2">
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForMove" id="Apartment" required
                                                    onClick={() => handleCleanServiceStatus('Apartment')} />
                                                <label className="form-check-label" htmlFor="Apartment">Apartment</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForMove" id="Condo"
                                                    onClick={() => handleCleanServiceStatus('Condo')} />
                                                <label className="form-check-label" htmlFor="Condo">Condo</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForMove" id="farm" required
                                                    onClick={() => handleCleanServiceStatus('Farm')} />
                                                <label className="form-check-label" htmlFor="farm">Farm</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForMove" id="House"
                                                    onClick={() => handleCleanServiceStatus('House')} />
                                                <label className="form-check-label" htmlFor="House">House</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForMove" id="Land" required
                                                    onClick={() => handleCleanServiceStatus('Land')} />
                                                <label className="form-check-label" htmlFor="Land">Land</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForMove" id="mobile_home"
                                                    onClick={() => handleCleanServiceStatus('Mobile home')} />
                                                <label className="form-check-label" htmlFor="mobile_home">Mobile home</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForMove" id="multi_family" required
                                                    onClick={() => handleCleanServiceStatus('Multi - family')} />
                                                <label className="form-check-label" htmlFor="multi_family">Multi - family</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForMove" id="Town_house"
                                                    onClick={() => handleCleanServiceStatus('Town house')} />
                                                <label className="form-check-label" htmlFor="Town_house">Town house</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForMove" id="Not_sure" required
                                                    onClick={() => handleCleanServiceStatus('Not sure')} />
                                                <label className="form-check-label" htmlFor="Not_sure">Not sure</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForMove" id="Other"
                                                    onClick={() => handleCleanServiceStatus('other')} />
                                                <label className="form-check-label" htmlFor="Other">Other</label>
                                            </div>
                                        </div>
                                        {
                                            otherCleanServiceStatus ?
                                                <input type="text" className="form-control mt-2" id="chooseDrink" placeholder='' required
                                                    onChange={(e) => setCleanServiceStatus(e.target.value)}
                                                />
                                                : ''
                                        }
                                    </div>
                                    : ''
                            }
                            {/* If Choosen Post Construction Cleaning */}
                            {
                                service === 'Commercial cleaning' ?
                                    <div id="PCC" className="mt-3">
                                        <label className="form-label fs-6 mb-2">For commercial cleaning, approximately what is the square footage of the space that needs cleaning?</label>
                                        <div className="d-flex flex-wrap g-2">
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForConstruction" id="11" required
                                                    onClick={() => setAreaRequired('less than 1000 sq.ft')} />
                                                <label className="form-check-label" htmlFor="11">less than 1000 sq.ft</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForConstruction" id="111"
                                                    onClick={() => setAreaRequired('1000 - 1500 sq.ft')} />
                                                <label className="form-check-label" htmlFor="111">1000 - 1500 sq.ft</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForConstruction" id="22"
                                                    onClick={() => setAreaRequired('1500 - 2000 sq.ft')} />
                                                <label className="form-check-label" htmlFor="22">1500 - 2000 sq.ft</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForConstruction" id="222"
                                                    onClick={() => setAreaRequired('2000 - 2500 sq.ft')} />
                                                <label className="form-check-label" htmlFor="222">2000 - 2500 sq.ft</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForConstruction" id="33"
                                                    onClick={() => setAreaRequired('2500 - 3000 sq.ft')} />
                                                <label className="form-check-label" htmlFor="33">2500 - 3000 sq.ft</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForConstruction" id="333"
                                                    onClick={() => setAreaRequired('More than 3000 sq.ft')} />
                                                <label className="form-check-label" htmlFor="333">More than 3000 sq.ft</label>
                                            </div>
                                            <div className="form-check me-3">
                                                <input className="form-check-input" type="radio" name="radioGroupForConstruction" id="not_sure"
                                                    onClick={() => setAreaRequired('not sure')} />
                                                <label className="form-check-label" htmlFor="not_sure">Not sure</label>
                                            </div>
                                        </div>
                                    </div>
                                    : ''
                            }
                        </div>
                    </div>
                    : ""
            }

            {
                (service === 'Cleaning service' || service === 'Commercial cleaning') ?
                    <div className="mb-3">
                        <label className="form-label fs-6 mb-2">Do you need any of these other services?</label>
                        <div className="d-flex flex-wrap g-2">
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupForOtherClean" id="cc" required
                                    onClick={() => handleExtraCleanService('Carpet Cleaning')} />
                                <label className="form-check-label" htmlFor="cc">Carpet Cleaning</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupForOtherClean" id="OC"
                                    onClick={() => handleExtraCleanService('Oven cleaning')} />
                                <label className="form-check-label" htmlFor="OC">Oven cleaning</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupForOtherClean" id="PT"
                                    onClick={() => handleExtraCleanService('Pet odor treatment')} />
                                <label className="form-check-label" htmlFor="PT">Pet odor treatment</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupForOtherClean" id="RC"
                                    onClick={() => handleExtraCleanService('Refrigerator cleaning')} />
                                <label className="form-check-label" htmlFor="RC">Refrigerator cleaning</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupForOtherClean" id="RC"
                                    onClick={() => handleExtraCleanService('Rug cleaning')} />
                                <label className="form-check-label" htmlFor="RC">Rug cleaning</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupForOtherClean" id="TGC"
                                    onClick={() => handleExtraCleanService('Tile and grout cleaning')} />
                                <label className="form-check-label" htmlFor="TGC">Tile and grout cleaning</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupForOtherClean" id="UC"
                                    onClick={() => handleExtraCleanService('Upholstering cleaning')} />
                                <label className="form-check-label" htmlFor="UC">Upholstering cleaning</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupForOtherClean" id="WC"
                                    onClick={() => handleExtraCleanService('Window cleaning')} />
                                <label className="form-check-label" htmlFor="WC">Window cleaning</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupForOtherClean" id="NS"
                                    onClick={() => handleExtraCleanService('Not Sure')} />
                                <label className="form-check-label" htmlFor="NS">Not sure</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupForOtherClean" id="OTH"
                                    onClick={() => handleExtraCleanService('other')} />
                                <label className="form-check-label" htmlFor="OTH">Other</label>
                            </div>
                        </div>
                        {
                            otherExtraCleanService ?
                                <input type="text" className="form-control mt-2" id="chooseDrink" placeholder='Other Cleaning services...' required
                                    onChange={(e) => setExtraCleanService(e.target.value)} />
                                : ''
                        }

                    </div> : ''
            }

            {
                (service === 'Cleaning service' || service === 'Commercial cleaning') ?
                    <div className="mb-3">
                        <label className="form-label fs-6 mb-2">Do you require any of these cleaning product types?</label>
                        <div className="d-flex flex-wrap g-2">
                            <div className="form-check me-3">
                                <input className="form-check-input" type="checkbox" name="checkboxGroupForProd" id="CS" required
                                    onClick={() => handleCleaningProduct('Child safe')} />
                                <label className="form-check-label" htmlFor="CS">Child safe</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="checkbox" name="checkboxGroupForProd" id="EF"
                                    onClick={() => handleCleaningProduct('Eco friendly')} />
                                <label className="form-check-label" htmlFor="EF">Eco friendly </label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="checkbox" name="checkboxGroupForProd" id="Green"
                                    onClick={() => handleCleaningProduct('Green')} />
                                <label className="form-check-label" htmlFor="Green">Green</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="checkbox" name="checkboxGroupForProd" id="NT"
                                    onClick={() => handleCleaningProduct('Non toxic')} />
                                <label className="form-check-label" htmlFor="NT">Non toxic</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="checkbox" name="checkboxGroupForProd" id="PS"
                                    onClick={() => handleCleaningProduct('Pet safe')} />
                                <label className="form-check-label" htmlFor="PS">Pet safe</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="checkbox" name="checkboxGroupForProd" id="NS"
                                    onClick={() => handleCleaningProduct('Not sure')} />
                                <label className="form-check-label" htmlFor="NS">Not sure</label>
                            </div>
                            {/* <div className="form-check me-3">
            <input className="form-check-input" type="checkbox" name="checkboxGroup" id="OTHH" />
            <label className="form-check-label" htmlFor="OTHH">Other</label>
        </div> */}
                        </div>
                        {/* <input type="text" className="form-control mt-2" id="chooseDrink" placeholder='' required /> */}
                    </div>
                    : ''
            }


            <div className="mb-3">
                <label htmlFor="chooseContactmode" className="form-label fs-6 mb-2">What is the best way to contact you?</label>
                <div className="d-flex flex-wrap g-2">
                    {
                        ContactWay.map((cntct) => (
                            <div className="form-check me-3" key={cntct}>
                                <input className="form-check-input" type="radio" name="radioGroupForcontact" id={cntct}
                                    onClick={() => handleContactMode(cntct)} />
                                <label className="form-check-label" htmlFor={cntct}>{cntct}</label>
                            </div>
                        ))
                    }
                </div>
                {
                    otherContactMode ?
                        <input type="text" className="form-control mt-2" id="otherContactMode" placeholder='Other contact...' required
                            onChange={(e) => setContactMode(e.target.value)} />
                        : ''
                }
            </div>
            <div className="mb-3">
                <label htmlFor="chooseBudget" className="form-label fs-6 mb-2">What is your budget for this service?</label>
                <div className="d-flex flex-wrap g-2">
                    {
                        Budget.map((budge) => (
                            <div className="form-check me-3" key={budge}>
                                <input className="form-check-input" type="radio" name="radioGroupBudget" id={budge} required
                                    onClick={() => handleBudget(budge)} />
                                <label className="form-check-label" htmlFor={budge}>{budge}</label>
                            </div>
                        ))
                    }

                </div>
                {
                    otherBudget ?
                        <input type="text" className="form-control mt-2" id="otherBudget" placeholder='Other budget...' required
                            onChange={(e) => setBudget(e.target.value)} />
                        : ''
                }
            </div>
            <div className="mb-3">
                <label htmlFor="choosePayment" className="form-label fs-6 mb-2">What is your payment method for this service?</label>
                <div className="d-flex flex-wrap g-2">
                    {
                        PaymentMethod.map((pay) => (
                            <div className="form-check me-3" key={pay}>
                                <input className="form-check-input" type="radio" name="radioGroupPay" id={pay} required
                                    onClick={() => handlePaymentMethod(pay)} />
                                <label className="form-check-label" htmlFor={pay}>{pay}</label>
                            </div>
                        ))
                    }
                </div>
                {
                    otherPaymentMethod ?
                        <input type="text" className="form-control mt-2" id="otherPayment" placeholder='Other payment method...' required
                            onChange={(e) => setPaymentMethod(e.target.value)} />
                        : ''
                }
            </div>

            <div className="mb-3">
                <label className="form-label fs-6 mb-2">When do you require this service?</label>
                <div className='date-picker-wrapper'>
                    <DatePicker showIcon className="form-control w-100" id="appointmentDate" selected={appointDate}
                        onChange={(value) => setAppointDate(value)} minDate={new Date()}
                    />
                </div>

            </div>

            <div className="mb-4">
                <label htmlFor="otherInfo" className="form-label fs-6 mb-2">Are there any other details you d like to share?</label>
                <textarea className="form-control" id="otherInfo" rows="4" placeholder='Write your description here...' required
                    onChange={(e) => setOtherInfo(e.target.value)}></textarea>
            </div>

            <div className="btn-wrapper">
                <button type="submit" className="w-100 btn btn-primary" onClick={(e) => handleSubmit(e)}>Submit</button>
            </div>
            {props.loading ?
                <div className='position-fixed top-0 start-0 bottom-0 end-0 d-flex align-items-center justify-content-center w-100 h-100 bg-black bg-opacity-50'
                    style={{ zIndex: 1030 }}>
                    <ReactLoading type={'spin'} color={'#fff'} height={64} width={64} />
                </div>
                : ''}
        </div>
    )
}


const mapPropsToState = (state) => {
    return {
        loading: state.business.loading,
        error: state.business.error,
        success: state.business.success,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        clickPerCount: (data, toast, navigate) => dispatch(actions.clickPerCount(data, toast, navigate)),
    }
}

export default connect(
    mapPropsToState,
    mapDispatchToProps
)(withSnackbar(CleaningServices))