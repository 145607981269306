import React, { useEffect } from 'react'
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom'

export const CouponrankingForCustomers = () => {
    useEffect(() => {
        document.documentElement.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant", // Optional if you want to skip the scrolling animation
        });
    }, [])
    return (
        <div className="cr_for_customers mt-4 mb-5">
            {/* title head */}
            <Helmet>
                <title>Couponranking for Customers</title>
            </Helmet>
            <div className='container'>
                <div className='section-header text-center mb-4 pb-lg-2' style={{ maxWidth: '1000px' }}>
                    <div className='section-label fs-3 mb-2'>Best solution for businesses looking to connect with customers</div>
                    <div className='section-sublabel text-dark text-opacity-75'>couponranking is the best solution for businesses looking to connect with their target audience and grow their customer base. We have the tools and expertise too connect you with the right audience.</div>
                </div>

                <div className='section-label fs-4 mb-4'>Couponranking for customers</div>

                {/* Coupons section */}
                <div className="coupons_sec bg-primary bg-opacity-10 mb-3 mb-md-0">
                    <div className="row g-0">
                        <div className="col-md-6">
                            <div className="img-wrapper ratio ratio-16x9 h-100">
                                <img src={require('../assets/grocery-store.jpg')} className='h-100 w-100 img-fluid object-fit-cover' alt='Grocery Store' />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="info-wrapper p-4 p-md-5 h-100 d-flex flex-column justify-content-center">
                                <div className="fs-5 fw-semibold lh-sm mb-2">Coupons</div>
                                <ul className='m-0 ps-3'>
                                    <li>Save money on your purchase</li>
                                    <li>Easy to use</li>
                                    <li>Wide selection</li>
                                    <li>Encourage repeat purchase</li>
                                    <li>More bang for your buck</li>
                                    <li>Increase purchasing power</li>
                                    <li>Executive discount</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Reward program section */}
                <div className="reward_program_sec bg-primary bg-opacity-10 mb-3 mb-md-0">
                    <div className="row g-0 flex-column-reverse flex-md-row">
                        <div className="col-md-6">
                            <div className="info-wrapper p-4 p-md-5 h-100 d-flex flex-column justify-content-center">
                                <div className="fs-5 fw-semibold lh-sm mb-2">Rewards Program</div>
                                <ul className='m-0 ps-3'>
                                    <li>Easy Redemption</li>
                                    <li>Personalized deals</li>
                                    <li>Loyalty rewards</li>
                                    <li>Unlock valuable rewards</li>
                                    <li>Save money with ease</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="img-wrapper ratio ratio-16x9 h-100">
                                <img src={require('../assets/coffee-shop.jpg')} className='h-100 w-100 img-fluid object-fit-cover' alt='Beauty Salon' />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Redeem Coupon section */}
                <div className="redeem_coupon_sec bg-primary bg-opacity-10 mb-3 mb-md-0">
                    <div className="row g-0">
                        <div className="col-md-6">
                            <div className="img-wrapper ratio ratio-16x9 h-100">
                                <img src={require('../assets/restaurants.jpg')} className='h-100 w-100 img-fluid object-fit-cover' alt='Restaurant' />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="info-wrapper p-4 p-md-5 h-100 d-flex flex-column justify-content-center">
                                <div className="fs-5 fw-semibold lh-sm mb-2">How to redeem your coupon</div>
                                <ul className='m-0 ps-3'>
                                    <li>Sign up or login on couponranking website</li>
                                    <li>Click on promo code</li>
                                    <li>Coupon code appears</li>
                                    <li>Visit the business location</li>
                                    <li>Present coupon code to the cashier</li>
                                    <li>Cashier will apply the discount</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Redeem Reward section */}
                <div className="redeem_reward_sec bg-primary bg-opacity-10 mb-3 mb-md-0">
                    <div className="row flex-column-reverse flex-md-row g-0">
                        <div className="col-md-6">
                            <div className="info-wrapper p-4 p-md-5 h-100 d-flex flex-column justify-content-center">
                                <div className="fs-5 fw-semibold lh-sm mb-2">How to redeem your rewards</div>
                                <ul className='m-0 ps-3'>
                                    <li>sign up or login on couponranking website</li>
                                    <li>Click on rewards</li>
                                    <li>Visit business location</li>
                                    <li>Present the rewards to the cashier to earn a point or star</li>
                                    <li>Cashier clicks on earn button</li>
                                    <li>You receive a point or star increase</li>
                                    <li>5 visits earns rewards for your sixth visit</li>
                                    <li>Sixth visit redeem your reward</li>
                                    <li>Cashier will apply the discount</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="img-wrapper ratio ratio-16x9 h-100">
                                <img src={require('../assets/bakery-store.jpg')} className='h-100 w-100 img-fluid object-fit-cover' alt='Bakery Store' />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Video Business section */}
                <div className="video_sec bg-primary bg-opacity-10 mb-3 mb-md-0">
                    <div className="row g-0">
                        <div className="col-md-6">
                            <div className="video-wrapper ratio ratio-16x9">
                                <video autoPlay={false} controls id="video1" className='w-100 object-fit-cover' muted>
                                    <source src="https://advertisement-videos.s3.us-west-2.amazonaws.com/1706633813125-02+%281%29.mp4" type="video/mp4" />
                                    <source src="https://advertisement-videos.s3.us-west-2.amazonaws.com/1706633813125-02+%281%29.mp4" type="video/ogg" />
                                    Your browser does not support HTML video.
                                </video>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="info-wrapper p-4 p-md-5 h-100 d-flex flex-column justify-content-center">
                                <p className='m-0'>Couponranking is a platform for connecting customers with businesses</p>
                                <div className="btn-wrapper text-start mt-4">
                                    <Link to={'/user-register'} className="btn btn-primary d-inline-flex align-items-center justify-content-center">Join couponranking today <i className="bi bi-arrow-right ms-1"></i></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Deals section */}
                <div className="deals_sec bg-primary bg-opacity-10 mb-3 mb-md-0">
                    <div className="row flex-column-reverse flex-md-row g-0">
                        <div className="col-md-6">
                            <div className="info-wrapper p-4 p-md-5 h-100 d-flex flex-column justify-content-center">
                                <div className="fs-5 fw-semibold">Deals</div>
                                <ul className='m-0 ps-3'>
                                    <li>Save money</li>
                                    <li>Quality product at affordable prices</li>
                                    <li>Personalized saving</li>
                                    <li>Easy and convenient</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="img-wrapper ratio ratio-16x9 h-100">
                                <img src={require('../assets/clothing-boutique.jpg')} className='h-100 w-100 img-fluid object-fit-cover' alt='Clothing Boutique' />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Request quote section */}
                <div className="request_quote_sec bg-primary bg-opacity-10 mb-3 mb-md-0">
                    <div className="row g-0">
                        <div className="col-md-6">
                            <div className="img-wrapper ratio ratio-16x9 h-100">
                                <img src={require('../assets/landscaping.jpg')} className='h-100 w-100 img-fluid object-fit-cover' alt='Landscaping' />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="info-wrapper p-4 p-md-5 h-100 d-flex flex-column justify-content-center">
                                <div className="fs-5 fw-semibold lh-sm mb-2">Request a quote</div>
                                <ul className='m-0 ps-3'>
                                    <li>Fast & competitive pricing</li>
                                    <li>Personalized service</li>
                                    <li>Customized options for your needs</li>
                                    <li>Quick response</li>
                                    <li>Hassel free pricing</li>
                                    <li>Request a qoute in minutes</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Book Appointment section */}
                <div className="book_appointment_sec bg-primary bg-opacity-10 mb-3 mb-md-0">
                    <div className="row flex-column-reverse flex-md-row g-0">
                        <div className="col-md-6">
                            <div className="info-wrapper p-4 p-md-5 h-100 d-flex flex-column justify-content-center">
                                <div className="fs-5 fw-semibold lh-sm mb-2">Book Appointment</div>
                                <ul className='m-0 ps-3'>
                                    <li>Secure your spot</li>
                                    <li>Save time and effort</li>
                                    <li>Personalized attention</li>
                                    <li>Hassle free experience</li>
                                    <li>Hassle-free scheduling</li>
                                    <li>Book your appointment easily</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="img-wrapper ratio ratio-16x9 h-100">
                                <img src={require('../assets/beauty-salon.jpg')} className='h-100 w-100 img-fluid object-fit-cover' alt='Beauty Salon' />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Two block section */}
                <div className="two_picture_sec mt-md-3">
                    <div className="row gx-3">
                        <div className="col-md-6 mb-3 mb-md-0">
                            <div className="details-cover bg-primary bg-opacity-10 h-100">
                                <div className="img-wrapper ratio ratio-16x9">
                                    <img src={require('../assets/photography.jpg')} className='w-100 img-fluid object-fit-cover' alt='Photography' />
                                </div>
                                <div className="info-wrapper p-4 p-md-5">
                                    <div className="fs-5 fw-semibold">Rating & Review</div>
                                    <ul className='m-0 ps-3'>
                                        <li>Make informed choices</li>
                                        <li>Get authentic feedback</li>
                                        <li>Get valuable insights from real customers</li>
                                        <li>Save time and effort</li>
                                        <li>Transparent & unbiased</li>
                                        <li>Increase confidence in purchase</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="details-cover bg-primary bg-opacity-10 h-100">
                                <div className="img-wrapper ratio ratio-16x9">
                                    <img src={require('../assets/roofing.jpg')} className='w-100 img-fluid object-fit-cover' alt='Roofing' />
                                </div>
                                <div className="info-wrapper p-4 p-md-5">
                                    <div className="fs-5 fw-semibold lh-sm mb-2">Email & text messages</div>
                                    <ul className='m-0 ps-3'>
                                        <li>Instant communication</li>
                                        <li>Personalized interactions</li>
                                        <li>Trackable performance</li>
                                        <li>Budget friendly solution</li>
                                        <li>Boost customer engagement</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
