import * as actionTypes from "../actions/actionTypes";
import axios from "../../axios-config";
import { createSearchParams } from "react-router-dom";
import { AES } from 'crypto-js';
const secretKey = 'philipCoupon';
 

export const startsearchBussinessAction = () => {
  return {
    type: actionTypes.SEARCH_START_BUSSINESS_ACTION
  };
};

export const searchedBussinessActionFail = message => {
  return {
    type: actionTypes.SEARCH_BUSSINESS_ACTION_FAIL,
    message
  };
};

export const getsearchSuggestionList = (list, message = '') => {
  return {
    type: actionTypes.SEARCH_SUGGESTION,
    listing: list,
    message: message ? message : ''
  };
};

export const getsearchSuggestion = (data) => {
  return dispatch => {
    dispatch(startsearchBussinessAction());
    const authCode = "Bearer " + localStorage.getItem("token");
    axios
      .post("/couponranking/search-suggestion", data, {
        headers: { Authorization: authCode }
      })
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) {
            dispatch(getsearchSuggestionList(response.data.data));
          } else {
            dispatch(searchedBussinessActionFail(response.data.message));
          }
        } else {
          dispatch(searchedBussinessActionFail(response.message));
        }
      })
      .catch(err => {
        dispatch(searchedBussinessActionFail(err.message));
      });
  };
};

export const getsearcedBusiness = (list, message = '') => {
  return {
    type: actionTypes.SEARCH_BUSINESS_LIST,
    listing: list,
    message: message ? message : ''
  };
};

export const getsearchBusinessList = (data, navigate) => {
  return dispatch => {
    dispatch(startsearchBussinessAction());
    const authCode = "Bearer " + localStorage.getItem("token");
    axios
      .post("/couponranking/search-business", data, {
        headers: { Authorization: authCode }
      })
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) {
            dispatch(getsearcedBusiness(response.data.data));
            let param = {
              searchKeyword: data.searchKeyword,
              latitude: data.latitude,
              longitude: data.longitude,
              type: data.type,
              searchedCity: data.searchedCity,
              searchedState: data.searchedState
            }
            if (data.type === 'subcategory') {
              param.subCategoryId = AES.encrypt(data.subCategoryId, secretKey).toString();
            }
            if (data.goingThrough === 'page') {
              navigate({ pathname: '/searched-business-list', search: `?${createSearchParams(param)}` },
                {
                  state: {
                    keyword: data.searchKeyword, type: data.type,
                    searchedCity: data.searchedCity, searchedState: data.searchedState
                  }
                })
            }
            // searched-business-list
          } else {
            dispatch(searchedBussinessActionFail(response.data.message));
          }
        } else {
          dispatch(searchedBussinessActionFail(response.message));
        }
      })
      .catch(err => {
        dispatch(searchedBussinessActionFail(err.message));
      });
  };
};