import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { Helmet } from "react-helmet";
import { connect } from 'react-redux'
import { withSnackbar } from 'react-simple-snackbar'
import * as actions from '../store/actions/index';
import { useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const AllCategories = (props) => {
    const { getCategoryList, categoryList } = props;
    const navigate = useNavigate()
    const [subCategoryModalInfo, setSubCategoryModalInfo] = useState([]);
    const [show, setShow] = useState(false);

    useEffect(() => {
        // for loading page from top after re-render from any section
        document.documentElement.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant", // Optional if you want to skip the scrolling animation
        });
        getCategoryList()
    }, [getCategoryList])

    const handleSubCategoryClick = (e, info) => {
        e.preventDefault();
        let city = localStorage.getItem('city');
        let state = localStorage.getItem('state');
        let categoryBusinessUrl = `/get-business-by-category/top-10-best-${info.name.replaceAll(/ /g, "-").toLowerCase()}-in-${city && state ? `${city}-${state}` : 'near me'}`
        navigate(categoryBusinessUrl, { state: info })
    }

    // modal hooks
    const handleClose = () => setShow(false);
    const handleModal = (info) => {
        setShow(true)
        setSubCategoryModalInfo(info)
    }
    return (
        <div className='all-categories-page categories-sec py-4'>
            {/* title head */}
            <Helmet>
                <title>CouponRanking Category list</title>
            </Helmet>
            <div className='container'>
                <div className='section-header text-center mb-4 pb-md-2'>
                    <div className='section-label fs-1'>All Category</div>
                    <div className='section-sublabel text-dark text-opacity-75'>Elevate your business to the next level with our business category solutions. Unlock potential and take advantage of tailored services today.</div>
                </div>
                <div className="row">
                    {
                        categoryList && categoryList.length > 0 ?
                            categoryList.map((categoryInfo, index) => (
                                <div className="col-md-6 col-lg-4 col-xl-3 mb-4" key={categoryInfo._id}>
                                    <div className="category-info-wrapper bg-primary bg-opacity-10 d-flex justify-content-between flex-column border shadow rounded-3 overflow-hidden h-100">
                                        <div className="coverr">
                                            <div className="category-header bg-white d-flex align-items-center gap-2 px-3 py-2">
                                                <img src={categoryInfo.category_images} className="img-fluid" alt="{info.image}" />
                                                <div className="category-title fw-semibold">{categoryInfo.name}</div>
                                            </div>
                                            {
                                                window.innerWidth >= '768' ?
                                                    <div className="category-body p-3">
                                                        {
                                                            categoryInfo.subcategoryInfo.slice(0, 3).map((info, i) => (
                                                                <div type='button' className="category-item nav-link d-flex gap-2 mb-2" key={info._id} onClick={(e) => handleSubCategoryClick(e, info)}><i className="bi bi-asterisk small text-success"></i>{info.name}</div>
                                                            ))
                                                        }
                                                        <span type='button' className='btn-link text-primary fs-18 ms-3 ps-1' onClick={() => handleModal(categoryInfo.subcategoryInfo)}>See more...</span>
                                                    </div>
                                                    :
                                                    <div className="category-body p-3">
                                                        {
                                                            categoryInfo.subcategoryInfo.map((info, i) => (
                                                                <div type='button' className="category-item nav-link d-flex gap-2 mb-2" key={info._id} onClick={(e) => handleSubCategoryClick(e, info)}><i className="bi bi-asterisk small text-success"></i>{info.name}</div>
                                                            ))
                                                        }
                                                    </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            ))
                            : ''
                    }
                </div>
            </div>
            
            {/* sub category modal data */}
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>All Subcategories</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        subCategoryModalInfo.map((info, i) => (
                            <div type='button' className="category-item nav-link d-flex gap-2 mb-2" key={info._id} onClick={(e) => handleSubCategoryClick(e, info)}><i className="bi bi-asterisk small text-success"></i>{info.name}</div>
                        ))
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}


const mapPropsToState = (state) => {
    return {
        loading: state.business.loading,
        error: state.business.error,
        success: state.business.success,
        categoryList: state.business.categoryList,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getCategoryList: () => dispatch(actions.getCategoryList()),
    }
}

export default connect(
    mapPropsToState,
    mapDispatchToProps
)(withSnackbar(AllCategories))