import React, { useState, useRef } from 'react';
import { Helmet } from "react-helmet";
import Banner3 from '../assets/bg-banner3.jpg';
import { connect } from 'react-redux'
import { withSnackbar } from 'react-simple-snackbar'
import * as actions from '../store/actions/index';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Autocomplete from 'react-google-autocomplete';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Accordion from 'react-bootstrap/Accordion';
import Geocode from "react-geocode";
Geocode.setApiKey("AIzaSyB_KZHG7uYGsaMGdQLDRrV3Qw6xRvLqg_Q");
Geocode.enableDebug();
const PaymentMethod = ["Cash app", "Zelle", "Cash", "Check", "Debit card", "Credit Card", "Not Sure", "Other"];
const ContactWay = ['Phone Call', 'Video call', 'Text', 'Message', 'Email', 'Chat', 'Other'];

const BookAppointment = (props) => {
    let navigate = useNavigate();
    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
    }, []);
    const { clickPerCount } = props
    const { state } = useLocation();
    const ref = useRef(null);
    const [appointDate, setAppointDate] = useState(new Date());
    const [appointTime, setAppointTime] = useState('');
    const [appointService, setAppointService] = useState('');
    const [isOtherService, setIsOtherService] = useState(false)
    const [subService, setSubService] = useState([]);
    const [isOtherSubService, setIsOtherSubService] = useState(false)
    const [salonOtherAns, setSalonOtherAns] = useState('')
    const [massageOtherAns, massageSalonOtherAns] = useState('');
    const [massageSalonOtherAnsOther, setMassageSalonOtherAnsOther] = useState(false);
    const [physicalTherapyOtherAns1, setPhysicalTherapyOtherAns1] = useState('');
    const [physicalTherapyOtherAnsOther1, setPhysicalTherapyAnsOther1] = useState(false);
    const [physicalTherapyOtherAns2, setPhysicalTherapyOtherAns2] = useState('');
    const [physicalTherapyTreatmentNeed, setPhysicalTherapyTreatmentNeed] = useState('');
    const [physicalTherapyTreatmentAddress, setPhysicalTherapyTreatmentAddress] = useState('');
    const [physicalTherapyOtherAnsOther2, setPhysicalTherapyAnsOther2] = useState(false);
    const [autoDetailingotherans1, setAutoDetailsAns1] = useState('');
    const [autoDetailingotherans2, setAutoDetailsAns2] = useState('');
    const [homeService, setHomeService] = useState('');
    const [customerAddress, setCustomerAddress] = useState('');
    const [payment, setPaymentMethod] = useState('');
    const [otherPaymentMethod, setOtherPaymentMethod] = useState(false);
    const [contactMode, setContactMode] = useState('');
    const [otherContactMode, setOtherContactMode] = useState(false);
    const [otherInfo, setOtherInfo] = useState('');
    let customerInfo = JSON.parse(localStorage.getItem('userInfo'));

    const handleDateChange = (value) => {
        setAppointDate(value)
    }
    const handleService = (value) => {
        ref.current?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: "center" });
        if (value === 'other') {
            setIsOtherService(true)
            setAppointService('')
        } else {
            setIsOtherService(false)
            setSubService([])
            setAppointService(value)
        }
        setIsOtherSubService(false)
    }
    const handleTimeChange = (event) => {
        var timeSplit = event.target.value.split(':'),
            hours,
            minutes,
            hours = timeSplit[0];
        minutes = timeSplit[1];
        // Check whether AM or PM
        let newformat = hours >= 12 ? 'PM' : 'AM';

        // Find current hour in AM-PM Format
        hours = hours % 12;

        // To display "0" as "12"
        hours = hours ? hours : 12;
        // minutes = minutes < 10 ? '0' + minutes : minutes;
        let selectedTime = hours + ':' + minutes + ' ' + newformat
        setAppointTime(selectedTime)
    }

    const handleSubserviceChange = (value, type) => {
        if (value !== 'Other') {
            setIsOtherSubService(false)
            if (type === 'radio') {
                setSubService([value])
            } else if (type === 'check') {
                let valueCheck = subService.includes(value)
                if (valueCheck) {
                    let getIndex = subService.indexOf(value)
                    let updatedSubService = [...subService]
                    updatedSubService.splice(getIndex, 1)
                    setSubService(updatedSubService)
                } else {
                    let updatedSubService = [...subService]
                    updatedSubService.push(value)
                    setSubService(updatedSubService)
                }
            }
        } else {
            setSubService([])
            setIsOtherSubService(true)
        }
    }

    const handleOtherSubServiceChange = (e) => {
        e.target.value !== '' ? setSubService([e.target.value]) : setSubService([])
    }

    const handleOtherService = (e) => {
        setAppointService(e.target.value)
        setSubService([])
    }

    const handleMassageSalonOtherAns = (value) => {
        if (value === 'Other') {
            setMassageSalonOtherAnsOther(true)
        } else {
            setMassageSalonOtherAnsOther(false)
            massageSalonOtherAns(value)
        }
    }
    const handlePhysicalTherapyOtherAns1 = (value) => {
        if (value === 'Other') {
            setPhysicalTherapyAnsOther1(true)
        } else {
            setPhysicalTherapyAnsOther1(false)
            setPhysicalTherapyOtherAns1(value)
        }
    }
    const handlePhysicalTherapyOtherAns2 = (value) => {
        if (value === 'Other') {
            setPhysicalTherapyAnsOther2(true)
        } else {
            setPhysicalTherapyAnsOther2(false)
            setPhysicalTherapyOtherAns2(value)
        }
    }
    const handlePaymentMethod = (value) => {
        if (value === 'Other') {
            setOtherPaymentMethod(true)
            setPaymentMethod('')
        } else {
            setOtherPaymentMethod(false)
            setPaymentMethod(value)
        }
    }
    const handleContactMode = (value) => {
        if (value === 'Other') {
            setOtherContactMode(true)
            setContactMode('')
        } else {
            setOtherContactMode(false)
            setContactMode(value)
        }
    }
    const handleLocationSelection = (place) => {
        const lat = place.geometry.location.lat();
        const lng = place.geometry.location.lng();
        fetch('https://maps.googleapis.com/maps/api/geocode/json?latlng=' + lat + ',' + lng + '&key=AIzaSyB_KZHG7uYGsaMGdQLDRrV3Qw6xRvLqg_Q', {
            method: 'GET'
        }).then((response) => response.json())
            .then((data) => {
                if (data.results[1]) {
                    const address = data.results[0].formatted_address
                    setCustomerAddress(address)
                }
            })
            .catch((error) => {
                toast.error(error, {
                    autoClose: 700,
                    hideProgressBar: true,
                })
            })
    };

    const handleSubmit = () => {
        if (!appointDate || !appointTime || !appointService || (subService.length === 0 && !isOtherService)
        || !contactMode || !payment || !homeService || (homeService==='Yes' && !customerAddress)) {
            toast.warning('Please Select All required field!', {
                autoClose: 700,
                hideProgressBar: true,
            })
        } else {
            let mobileNo = customerInfo.country_code + customerInfo.mobile_no;
            let customerName = customerInfo.name;
            let customerEmail = customerInfo.email;
            var appointOtherQuestion1, appointOtherQuestion2, appointOtherQuestion3, appointOtherQuestion4 = '';
            var appointOtherAnswer1, appointOtherAnswer2, appointOtherAnswer3, appointOtherAnswer4 = '';
            if (appointService === 'beauty_salon') {
                appointOtherQuestion1 = 'Name of your beauty specialist if applicable'
                appointOtherAnswer1 = salonOtherAns
            } else if (appointService === 'massage') {
                appointOtherQuestion1 = 'How long do you want the session to last?'
                appointOtherAnswer1 = massageOtherAns
            } else if (appointService === 'auto_detailing') {
                appointOtherQuestion1 = 'Do you need cleanup of hair or pet related odors and stains'
                appointOtherAnswer1 = autoDetailingotherans1
                appointOtherQuestion2 = 'Do you want your vehicles disinfected?'
                appointOtherAnswer2 = autoDetailingotherans2
            } else if (appointService === 'physical_therapy') {
                appointOtherQuestion1 = 'How long do you want the session to last?'
                appointOtherAnswer1 = physicalTherapyOtherAns1
                appointOtherQuestion2 = 'How often will you need treatment?'
                appointOtherAnswer2 = physicalTherapyOtherAns2
                appointOtherQuestion3 = 'Do need the business to come to you?'
                appointOtherAnswer3 = physicalTherapyTreatmentNeed
                appointOtherQuestion4 = 'What address or location do you need the service?'
                appointOtherAnswer4 = physicalTherapyTreatmentAddress
            }
            // change date formate in USA format
            const date = new Date(appointDate);
            let UsDate = date.toLocaleDateString("en-US")
            let data = {
                mobileNo: mobileNo,
                customerName: customerName,
                customerEmail: customerEmail,
                businessName: state.businessName,
                businessEmail: state.email,
                businessId: state.businessId,
                clickType: 'bookAppointment',
                appointDate: UsDate,
                appointTime: appointTime,
                paymentMethod:payment,
                contactMode:contactMode,
                homeService:homeService,
                customerAddress:homeService ==='Yes'?customerAddress:'',
                appointService: appointService,
                appointSubService: subService,
                appointOtherInfo: otherInfo,
                appointOtherQuestion1: appointOtherQuestion1,
                appointOtherAnswer1: appointOtherAnswer1,
                appointOtherQuestion2: appointOtherQuestion2,
                appointOtherAnswer2: appointOtherAnswer2,
                appointOtherQuestion3: appointOtherQuestion3,
                appointOtherAnswer3: appointOtherAnswer3,
                appointOtherQuestion4: appointOtherQuestion4,
                appointOtherAnswer4: appointOtherAnswer4,
            }
            clickPerCount(data, toast, navigate)
        }
    }

    return (
        <div className='book-appointment-page section-bg-image pt-4 pb-5' style={{ backgroundImage: `url(${Banner3})` }}>
            <ToastContainer />
            {/* title head */}
            <Helmet>
                <title>Book Your Appointment With {state.businessName}</title>
            </Helmet>
            <div className="container position-relative">
                <div className="form-wrapper mx-auto" style={{ maxWidth: '900px' }}>
                    <form className='w-100 bg-white border rounded py-5 px-3 px-md-5'>
                        <div className="section-label fs-4 bg-light mb-3 py-2" style={{ borderBlock: '1px solid #ccc' }}>Book Appointment</div>
                        <div className="mb-3">
                            <label htmlFor="appointmentDate" className="form-label w-100 fs-6">Preffered date</label>
                            {/* <input type="date" className="form-control" id="appointmentDate" min="01-01-2018" max="12-31-2018" required
                                onChange={(value) => handleDateChange(value)} /> */}
                            <div className='date-picker-wrapper'>
                                <DatePicker showIcon className="form-control w-100" id="appointmentDate" selected={appointDate}
                                    onChange={(value) => handleDateChange(value)} minDate={new Date()}
                                />
                            </div>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="appointmentTime" className="form-label fs-6">Preffered time</label>
                            <input type="time" id="appointmentTime" className="form-control" min='01:00' max='03:00' required
                                onChange={(value) => handleTimeChange(value)} />
                        </div>

                        <div className="mb-3">
                            <label htmlFor="homemobileService" className="form-label fs-6">Do you want home (mobile) service?</label>
                            <div className="d-flex flex-wrap">
                                <div className="form-check me-3">
                                    <input className="form-check-input" type="radio" name="radioGroupForhomeservice" id='homeServiceYes' required
                                        onClick={() => setHomeService('Yes')} />
                                    <label className="form-check-label" htmlFor='homeServiceYes'>Yes</label>
                                </div>
                                <div className="form-check me-3">
                                    <input className="form-check-input" type="radio" name="radioGroupForhomeservice" id='homeServiceNo' required
                                        onClick={() => setHomeService('No')} />
                                    <label className="form-check-label" htmlFor='homeServiceNo'>No</label>
                                </div>
                            </div>

                        </div>

                        {
                            homeService === 'Yes' ?
                                <div className="mb-3">
                                    <label htmlFor="CustomerAddress" className="form-label fs-6 mb-2">Customer address</label>
                                    <Autocomplete
                                        className="form-control"
                                        placeholder='Please enter address...'
                                        onPlaceSelected={(e) => handleLocationSelection(e)}
                                        options={{
                                            fields: ["formatted_address", "geometry", "name"],
                                            strictBounds: false,
                                            types: [],
                                        }}
                                    />
                                </div>
                                : ''
                        }
                        <div className="mb-3">
                            <label htmlFor="choosePayment" className="form-label fs-6 mb-2">What is your payment method for this service?</label>
                            <div className="d-flex flex-wrap g-2">
                                {
                                    PaymentMethod.map((pay) => (
                                        <div className="form-check me-3" key={pay}>
                                            <input className="form-check-input" type="radio" name="radioGroupPay" id={pay} required
                                                onClick={() => handlePaymentMethod(pay)} />
                                            <label className="form-check-label" htmlFor={pay}>{pay}</label>
                                        </div>
                                    ))
                                }
                            </div>
                            {
                                otherPaymentMethod ?
                                    <input type="text" className="form-control mt-2" id="otherPayment" placeholder='Other payment method...' required
                                        onChange={(e) => setPaymentMethod(e.target.value)} />
                                    : ''
                            }
                        </div>
                        <div className="mb-3">
                            <label htmlFor="chooseContactmode" className="form-label fs-6 mb-2">What is the best way to contact you?</label>
                            <div className="d-flex flex-wrap g-2">
                                {
                                    ContactWay.map((cntct) => (
                                        <div className="form-check me-3" key={cntct}>
                                            <input className="form-check-input" type="radio" name="radioGroupForcontact" id={cntct}
                                                onClick={() => handleContactMode(cntct)} />
                                            <label className="form-check-label" htmlFor={cntct}>{cntct}</label>
                                        </div>
                                    ))
                                }
                            </div>
                            {
                                otherContactMode ?
                                    <input type="text" className="form-control mt-2" id="otherContactMode" placeholder='Other contact...' required
                                        onChange={(e) => setContactMode(e.target.value)} />
                                    : ''
                            }
                        </div>


                        <div className="mb-3">
                            <label className="form-label fs-6 mb-2">Select your manner of services</label>
                            <Accordion defaultActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header onClick={() => setAppointService('')}>BEAUTY & HEALTH SERVICE</Accordion.Header>
                                    <Accordion.Body>
                                        <div className="wrapper" >
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="radio" name="radioGroupBHS" id="beauty_salon"
                                                    checked={appointService === 'beauty_salon' ? true : false}
                                                    onClick={() => handleService('beauty_salon')} required />
                                                <label className="form-check-label" htmlFor="beauty_salon">Beauty salon</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="radio" name="radioGroupBHS" id="dentist"
                                                    checked={appointService === 'dentist' ? true : false}
                                                    onClick={() => handleService('dentist')} />
                                                <label className="form-check-label" htmlFor="dentist">Dentist</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="radio" name="radioGroupBHS" id="pet_service"
                                                    checked={appointService === 'pet_service' ? true : false}
                                                    onClick={() => handleService('pet_service')} />
                                                <label className="form-check-label" htmlFor="pet_service">Pet Service</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="radio" name="radioGroupBHS" id="massage"
                                                    checked={appointService === 'massage' ? true : false}
                                                    onClick={() => handleService('massage')} />
                                                <label className="form-check-label" htmlFor="massage">Massage</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="radio" name="radioGroupBHS" id="physical_training"
                                                    checked={appointService === 'physical_training' ? true : false}
                                                    onClick={() => handleService('physical_training')} />
                                                <label className="form-check-label" htmlFor="physical_training">Physical training</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="radio" name="radioGroupBHS" id="chiropractor"
                                                    checked={appointService === 'chiropractor' ? true : false}
                                                    onClick={() => handleService('chiropractor')} />
                                                <label className="form-check-label" htmlFor="chiropractor">Chiropractor</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="radio" name="radioGroupBHS" id="physical_therapy"
                                                    checked={appointService === 'physical_therapy' ? true : false}
                                                    onClick={() => handleService('physical_therapy')} />
                                                <label className="form-check-label" htmlFor="physical_therapy">Physical Therapy</label>
                                            </div>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header onClick={() => setAppointService('')}>AUTOMOTIVE SERVICES</Accordion.Header>
                                    <Accordion.Body>
                                        <div className="wrapper" >
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="radio" name="radioGroup" id="auto_repair"
                                                    checked={appointService === 'auto_repair' ? true : false}
                                                    onClick={() => handleService('auto_repair')} />
                                                <label className="form-check-label" htmlFor="auto_repair">Auto repair</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="radio" name="radioGroup" id="auto_detailing"
                                                    checked={appointService === 'auto_detailing' ? true : false}
                                                    onClick={() => handleService('auto_detailing')} />
                                                <label className="form-check-label" htmlFor="auto_detailing">Auto detailing</label>
                                            </div>

                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header onClick={() => setAppointService('')}>BUSINESS SERVICES</Accordion.Header>
                                    <Accordion.Body>
                                        <div className="wrapper" >
                                            <div className="form-check w-100 pe-2">
                                                <input className="form-check-input" type="radio" name="radioGroup" id="HAREPM"
                                                    checked={appointService === 'housing' ? true : false}
                                                    onClick={() => handleService('housing')} />
                                                <label className="form-check-label" htmlFor="HAREPM">Housing (apartment, real estate or property management)</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="radio" name="radioGroup" id="lawyers"
                                                    checked={appointService === 'lawyers' ? true : false}
                                                    onClick={() => handleService('lawyers')} />
                                                <label className="form-check-label" htmlFor="Lawyers">Lawyers</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="radio" name="radioGroup" id="business_consulting"
                                                    checked={appointService === 'business_consulting' ? true : false}
                                                    onClick={() => handleService('business_consulting')} />
                                                <label className="form-check-label" htmlFor="business_consulting">Business consulting</label>
                                            </div>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3">
                                    <Accordion.Header onClick={() => setAppointService('')}>CLEANING SERVICE</Accordion.Header>
                                    <Accordion.Body>
                                        <div className="wrapper" >
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="radio" name="radioGroup" id="cleaning_services"
                                                    checked={appointService === 'cleaning_services' ? true : false}
                                                    onClick={() => handleService('cleaning_services')} />
                                                <label className="form-check-label" htmlFor="cleaning_services">Cleaning services</label>
                                            </div>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="4">
                                    <Accordion.Header onClick={() => setAppointService('')}>CONSTRUCTION & REMODELING</Accordion.Header>
                                    <Accordion.Body>
                                        <div className="wrapper" >
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="radio" name="radioGroup" id="Handyman"
                                                    checked={appointService === 'handyman' ? true : false}
                                                    onClick={() => handleService('handyman')} />
                                                <label className="form-check-label" htmlFor="Handyman">Handyman</label>
                                            </div>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="5">
                                    <Accordion.Header onClick={() => setAppointService('')}>EVENT & PARTY</Accordion.Header>
                                    <Accordion.Body>
                                        <div className="wrapper" >
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="radio" name="radioGroup" id="wedding_planner"
                                                    checked={appointService === 'wedding_planner' ? true : false}
                                                    onClick={() => handleService('wedding_planner')} />
                                                <label className="form-check-label" htmlFor="wedding_planner">Wedding planner</label>
                                            </div>

                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="6">
                                    <Accordion.Header onClick={() => setAppointService('')}>OTHERS</Accordion.Header>
                                    <Accordion.Body>
                                        <input type="text" className="form-control" id="MOS_OTH" placeholder='Others service you need...' required
                                            value={appointService}
                                            onChange={(e) => handleOtherService(e)}
                                        />
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>

                        <div className="mb-3">
                            {/* <label className="form-label fs-6 mb-2">Select your manner of services</label>
                            <div className="d-flex flex-wrap row-cols-1 row-cols-md-2 row-cols-md-3">
                                <div className="form-check pe-2">
                                    <input className="form-check-input" type="radio" name="radioGroup" id="beauty_salon"
                                        onClick={() => handleService('beauty_salon')} required />
                                    <label className="form-check-label" htmlFor="beauty_salon">Beauty salon</label>
                                </div>
                                <div className="form-check pe-2">
                                    <input className="form-check-input" type="radio" name="radioGroup" id="massage"
                                        onClick={() => handleService('massage')} />
                                    <label className="form-check-label" htmlFor="massage">Massage</label>
                                </div>
                                <div className="form-check pe-2">
                                    <input className="form-check-input" type="radio" name="radioGroup" id="auto_repair"
                                        onClick={() => handleService('auto_repair')} />
                                    <label className="form-check-label" htmlFor="auto_repair">Auto repair</label>
                                </div>
                                <div className="form-check pe-2">
                                    <input className="form-check-input" type="radio" name="radioGroup" id="cleaning_services"
                                        onClick={() => handleService('cleaning_services')} />
                                    <label className="form-check-label" htmlFor="cleaning_services">Cleaning services</label>
                                </div>
                                <div className="form-check pe-2">
                                    <input className="form-check-input" type="radio" name="radioGroup" id="chiropractor"
                                        onClick={() => handleService('chiropractor')} />
                                    <label className="form-check-label" htmlFor="chiropractor">Chiropractor</label>
                                </div>
                                <div className="form-check pe-2">
                                    <input className="form-check-input" type="radio" name="radioGroup" id="dentist"
                                        onClick={() => handleService('dentist')} />
                                    <label className="form-check-label" htmlFor="dentist">Dentist</label>
                                </div>
                                <div className="form-check pe-2">
                                    <input className="form-check-input" type="radio" name="radioGroup" id="Handyman"
                                        onClick={() => handleService('handyman')} />
                                    <label className="form-check-label" htmlFor="Handyman">Handyman</label>
                                </div>
                                <div className="form-check pe-2">
                                    <input className="form-check-input" type="radio" name="radioGroup" id="wedding_planner"
                                        onClick={() => handleService('wedding_planner')} />
                                    <label className="form-check-label" htmlFor="wedding_planner">Wedding planner</label>
                                </div>
                                <div className="form-check pe-2">
                                    <input className="form-check-input" type="radio" name="radioGroup" id="pet_service"
                                        onClick={() => handleService('pet_service')} />
                                    <label className="form-check-label" htmlFor="pet_service">Pet grooming</label>
                                </div>
                                <div className="form-check pe-2">
                                    <input className="form-check-input" type="radio" name="radioGroup" id="auto_detailing"
                                        onClick={() => handleService('auto_detailing')} />
                                    <label className="form-check-label" htmlFor="auto_detailing">Auto detailing</label>
                                </div>
                                <div className="form-check pe-2">
                                    <input className="form-check-input" type="radio" name="radioGroup" id="business_consulting"
                                        onClick={() => handleService('business_consulting')} />
                                    <label className="form-check-label" htmlFor="business_consulting">Business consulting</label>
                                </div>
                                <div className="form-check pe-2">
                                    <input className="form-check-input" type="radio" name="radioGroup" id="physical_training"
                                        onClick={() => handleService('physical_training')} />
                                    <label className="form-check-label" htmlFor="physical_training">Physical training</label>
                                </div>
                                <div className="form-check pe-2">
                                    <input className="form-check-input" type="radio" name="radioGroup" id="lawyers"
                                        onClick={() => handleService('lawyers')} />
                                    <label className="form-check-label" htmlFor="Lawyers">Lawyers</label>
                                </div>
                                <div className="form-check w-100 pe-2">
                                    <input className="form-check-input" type="radio" name="radioGroup" id="HAREPM"
                                        onClick={() => handleService('housing')} />
                                    <label className="form-check-label" htmlFor="HAREPM">Housing (apartment, real estate or property management)</label>
                                </div>
                                <div className="form-check w-100 pe-2">
                                    <input className="form-check-input" type="radio" name="radioGroup" id="PhysicalTS"
                                        onClick={() => handleService('Physical therapy service')} />
                                    <label className="form-check-label" htmlFor="PhysicalTS">Physical therapy service</label>
                                </div>
                                <div className="form-check pe-2">
                                    <input className="form-check-input" type="radio" name="radioGroup" id="MOS_OTH"
                                        onClick={() => handleService('other')} />
                                    <label className="form-check-label" htmlFor="MOS_OTH">Other</label>
                                </div>
                                {isOtherService ?
                                    <input type="text" className="form-control mt-2" id="MOS_OTH" placeholder='Others...' required
                                        onChange={(e) => handleOtherService(e)} />
                                    : ''}
                            </div> */}

                            <div className="element-wrapper mt-3" ref={ref}>
                                {/* If Choosen Beauty Salon */}
                                {
                                    appointService === 'beauty_salon' ?
                                        <div id="Beauty_Salon" className="mb-3">
                                            <div className="form-label fs-6 mb-2">What type of service do you need?</div>
                                            <div className="d-flex flex-wrap row-cols-1 row-cols-md-2">
                                                <div className="form-check pe-2">
                                                    <input className="form-check-input" type="radio" name="BS_radioGroup" id="nail_care" required
                                                        onChange={() => handleSubserviceChange('Nail Care', 'radio')} />
                                                    <label className="form-check-label" htmlFor="nail_care">Nail care</label>
                                                </div>
                                                <div className="form-check pe-2">
                                                    <input className="form-check-input" type="radio" name="BS_radioGroup" id="eye_care"
                                                        onChange={() => handleSubserviceChange('Eye Care', 'radio')} />
                                                    <label className="form-check-label" htmlFor="eye_care">Eye care</label>
                                                </div>
                                                <div className="form-check pe-2">
                                                    <input className="form-check-input" type="radio" name="BS_radioGroup" id="BSAF"
                                                        onChange={() => handleSubserviceChange('Body shaping and fitness', 'radio')} />
                                                    <label className="form-check-label" htmlFor="BSAF">Body shaping and fitness</label>
                                                </div>
                                                <div className="form-check pe-2">
                                                    <input className="form-check-input" type="radio" name="BS_radioGroup" id="hair_care"
                                                        onChange={() => handleSubserviceChange('Hair Care', 'radio')} />
                                                    <label className="form-check-label" htmlFor="hair_care">Hair care</label>
                                                </div>
                                                <div className="form-check pe-2">
                                                    <input className="form-check-input" type="radio" name="BS_radioGroup" id="make_up"
                                                        onChange={() => handleSubserviceChange('Make Up', 'radio')} />
                                                    <label className="form-check-label" htmlFor="make_up">Make up</label>
                                                </div>
                                                <div className="form-check pe-2">
                                                    <input className="form-check-input" type="radio" name="BS_radioGroup" id="facial_treatment"
                                                        onChange={() => handleSubserviceChange('Facial treatment', 'radio')} />
                                                    <label className="form-check-label" htmlFor="facial_treatment">Facial treatment</label>
                                                </div>
                                                <div className="form-check pe-2">
                                                    <input className="form-check-input" type="radio" name="BS_radioGroup" id="skin_care"
                                                        onChange={() => handleSubserviceChange('Skin Care', 'radio')} />
                                                    <label className="form-check-label" htmlFor="skin_care">Skin care</label>
                                                </div>
                                                <div className="form-check pe-2">
                                                    <input className="form-check-input" type="radio" name="BS_radioGroup" id="waxing"
                                                        onChange={() => handleSubserviceChange('Waxing', 'radio')} />
                                                    <label className="form-check-label" htmlFor="waxing">Waxing</label>
                                                </div>
                                                <div className="form-check pe-2">
                                                    <input className="form-check-input" type="radio" name="BS_radioGroup" id="AAT"
                                                        onChange={() => handleSubserviceChange('Anti aging treatment', 'radio')} />
                                                    <label className="form-check-label" htmlFor="AAT">Anti aging treatment </label>
                                                </div>
                                                <div className="form-check pe-2">
                                                    <input className="form-check-input" type="radio" name="BS_radioGroup" id="hair_coloring"
                                                        onChange={() => handleSubserviceChange('Hair Color', 'radio')} />
                                                    <label className="form-check-label" htmlFor="hair_coloring">Hair coloring </label>
                                                </div>
                                                <div className="form-check pe-2">
                                                    <input className="form-check-input" type="radio" name="BS_radioGroup" id="AHB"
                                                        onChange={() => handleSubserviceChange('African hair branding', 'radio')} />
                                                    <label className="form-check-label" htmlFor="AHB">African hair braiding</label>
                                                </div>

                                                <div className="form-check pe-2">
                                                    <input className="form-check-input" type="radio" name="BS_radioGroup" id="barber_shop"
                                                        onChange={() => handleSubserviceChange('Barber shop', 'radio')} />
                                                    <label className="form-check-label" htmlFor="barber_shop">Barber shop</label>
                                                </div>
                                                <div className="form-check pe-2">
                                                    <input className="form-check-input" type="radio" name="BS_radioGroup" id="tattoo_shop"
                                                        onChange={() => handleSubserviceChange('Tattoo shop', 'radio')} />
                                                    <label className="form-check-label" htmlFor="tattoo_shop">Tattoo shop</label>
                                                </div>

                                                <div className="form-check pe-2">
                                                    <input className="form-check-input" type="radio" name="BS_radioGroup" id="BS_OTH"
                                                        onChange={() => handleSubserviceChange('Other')} />
                                                    <label className="form-check-label" htmlFor="BS_OTH">Other</label>
                                                </div>
                                                {isOtherSubService ?
                                                    <input type="text" className="form-control mt-2" id="MOS_OTH" placeholder='Others...' required
                                                        onChange={(e) => handleOtherSubServiceChange(e)} />
                                                    : ''}
                                            </div>
                                            <div className='other-wrapper mt-2'>
                                                <div className='mb-2'>
                                                    <label className='form-label fs-6 mb-1'>Name of your beauty specialist if applicable</label>
                                                    <input type="text" className="form-control" id="BS_OTH" placeholder='beauty specialist if any...' required
                                                        onChange={(e) => setSalonOtherAns(e.target.value)}
                                                    />
                                                </div>
                                                <div className="form-check pe-2">
                                                    <input className="form-check-input" type="radio" name="Notsure_radioGroup" id="BS_NOTSURE"
                                                        onChange={(e) => setSalonOtherAns('Not Sure')} />
                                                    <label className="form-check-label" htmlFor="BS_NOTSURE">Not Sure</label>
                                                </div>
                                            </div>
                                        </div> : ''
                                }

                                {/* If Choosen Massage */}
                                {appointService === 'massage' ?
                                    <div id="Message" className="mb-3">
                                        <div className="form-label fs-6 mb-2">What type of massage do you need?</div>
                                        <div className="d-flex flex-wrap row-cols-1 row-cols-md-2">
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="radio" name="message_radioGroup" id="aromatherapy" required
                                                    onChange={() => handleSubserviceChange('Aromatherapy', 'radio')} />
                                                <label className="form-check-label" htmlFor="aromatherapy">Aromatherapy</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="radio" name="message_radioGroup" id="hot_stone"
                                                    onChange={() => handleSubserviceChange('Hot stone', 'radio')} />
                                                <label className="form-check-label" htmlFor="hot_stone">Hot stone</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="radio" name="message_radioGroup" id="neuromuscular"
                                                    onChange={() => handleSubserviceChange('Neuromuscular', 'radio')} />
                                                <label className="form-check-label" htmlFor="neuromuscular">Neuromuscular</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="radio" name="message_radioGroup" id="deep_tissue"
                                                    onChange={() => handleSubserviceChange('Deep tissue', 'radio')} />
                                                <label className="form-check-label" htmlFor="deep_tissue">Deep tissue</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="radio" name="message_radioGroup" id="lymphatic_drainage"
                                                    onChange={() => handleSubserviceChange('Lymphatic drainage', 'radio')} />
                                                <label className="form-check-label" htmlFor="lymphatic_drainage">Lymphatic drainage </label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="radio" name="message_radioGroup" id="prenatal" required
                                                    onChange={() => handleSubserviceChange('Prenatal', 'radio')} />
                                                <label className="form-check-label" htmlFor="prenatal">Prenatal</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="radio" name="message_radioGroup" id="foot_massage"
                                                    onChange={() => handleSubserviceChange('Foot massage', 'radio')} />
                                                <label className="form-check-label" htmlFor="foot_massage">Foot massage</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="radio" name="message_radioGroup" id="myofascial"
                                                    onChange={() => handleSubserviceChange('Myofascial', 'radio')} />
                                                <label className="form-check-label" htmlFor="myofascial">Myofascial</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="radio" name="message_radioGroup" id="reflexology"
                                                    onChange={() => handleSubserviceChange('Reflexology', 'radio')} />
                                                <label className="form-check-label" htmlFor="reflexology">Reflexology</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="radio" name="message_radioGroup" id="shiatsu"
                                                    onChange={() => handleSubserviceChange('Shiatsu', 'radio')} />
                                                <label className="form-check-label" htmlFor="shiatsu">Shiatsu</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="radio" name="message_radioGroup" id="spots"
                                                    onChange={() => handleSubserviceChange('Spots', 'radio')} />
                                                <label className="form-check-label" htmlFor="spots">Spots</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="radio" name="message_radioGroup" id="Swedish"
                                                    onChange={() => handleSubserviceChange('Swedish', 'radio')} />
                                                <label className="form-check-label" htmlFor="Swedish">Swedish</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="radio" name="message_radioGroup" id="Thai"
                                                    onChange={() => handleSubserviceChange('Thai', 'radio')} />
                                                <label className="form-check-label" htmlFor="Thai">Thai</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="radio" name="message_radioGroup" id="therapeutic"
                                                    onChange={() => handleSubserviceChange('Therapeutic', 'radio')} />
                                                <label className="form-check-label" htmlFor="therapeutic">Therapeutic</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="radio" name="message_radioGroup" id="trigger_points"
                                                    onChange={() => handleSubserviceChange('Trigger_points', 'radio')} />
                                                <label className="form-check-label" htmlFor="trigger_points">Trigger_points</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="radio" name="message_radioGroup" id="MSG_OTH"
                                                    onChange={() => handleSubserviceChange('Other')} />
                                                <label className="form-check-label" htmlFor="MSG_OTH">Other</label>
                                            </div>
                                            {isOtherSubService ?
                                                <input type="text" className="form-control mt-2" id="MSG_OTH" placeholder='Other...' required
                                                    onChange={(e) => handleOtherSubServiceChange(e)} />
                                                : ''}
                                        </div>

                                        <div className='other-wrapper mt-2'>
                                            <div className="form-label fs-6 mb-2">How long do you want the session to last?</div>
                                            <div className="d-flex flex-wrap g-2">
                                                <div className="form-check pe-2">
                                                    <input className="form-check-input" type="radio" name="message_radioGroup1" id="30_min"
                                                        onChange={(e) => handleMassageSalonOtherAns('30 minutes')} />
                                                    <label className="form-check-label" htmlFor="30_min">30 minutes</label>
                                                </div>
                                                <div className="form-check pe-2">
                                                    <input className="form-check-input" type="radio" name="message_radioGroup1" id="45_min"
                                                        onChange={(e) => handleMassageSalonOtherAns('45 minutes')} />
                                                    <label className="form-check-label" htmlFor="45_min">45 minutes</label>
                                                </div>
                                                <div className="form-check pe-2">
                                                    <input className="form-check-input" type="radio" name="message_radioGroup1" id="60_min"
                                                        onChange={(e) => handleMassageSalonOtherAns('60 minutes')} />
                                                    <label className="form-check-label" htmlFor="60_min">60 min</label>
                                                </div>
                                                <div className="form-check pe-2">
                                                    <input className="form-check-input" type="radio" name="message_radioGroup1" id="90_min"
                                                        onChange={(e) => handleMassageSalonOtherAns('90 minutes')} />
                                                    <label className="form-check-label" htmlFor="90_min">90 min</label>
                                                </div>
                                                <div className="form-check pe-2">
                                                    <input className="form-check-input" type="radio" name="message_radioGroup1" id="MSG_NOTSURE"
                                                        onChange={(e) => handleMassageSalonOtherAns('Not Sure')} />
                                                    <label className="form-check-label" htmlFor="MSG_NOTSURE">Not Sure</label>
                                                </div>
                                                <div className="form-check pe-2">
                                                    <input className="form-check-input" type="radio" name="message_radioGroup1" id="MSG_OTH1"
                                                        onChange={(e) => handleMassageSalonOtherAns('Other')} />
                                                    <label className="form-check-label" htmlFor="MSG_OTH1">Other</label>
                                                </div>
                                                {massageSalonOtherAnsOther ?
                                                    <input type="text" className="form-control mt-2" id="MSG_OTH1" placeholder='Other...' required
                                                        onChange={(e) => massageSalonOtherAns(e.target.value)} />
                                                    : ''}
                                            </div>
                                        </div>
                                    </div> : ''
                                }
                                {/* If Choosen Auto Repair */}
                                {appointService === 'auto_repair' ?
                                    <div id="Auto_Repair" className="mb-3">
                                        <div className="form-label fs-6 mb-2">What type of services do you need?</div>
                                        <div className="d-flex flex-wrap row-cols-1 row-cols-md-2">
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="AR_CheckGroup" id="computer_diagnostic" required
                                                    onChange={() => handleSubserviceChange('Computer diagnostic', 'check')} />
                                                <label className="form-check-label" htmlFor="computer_diagnostic">Computer diagnostic</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="AR_CheckGroup" id="LOFC"
                                                    onChange={() => handleSubserviceChange('Lube/oil/filter change', 'check')} />
                                                <label className="form-check-label" htmlFor="LOFC">Lube/oil/filter change</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="AR_CheckGroup" id="RFFS"
                                                    onChange={() => handleSubserviceChange('Radiator flush /fill services', 'check')} />
                                                <label className="form-check-label" htmlFor="RFFS">Radiator flush /fill services </label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="AR_CheckGroup" id="TFS"
                                                    onChange={() => handleSubserviceChange('Transmission fluid service', 'check')} />
                                                <label className="form-check-label" htmlFor="TFS">Transmission fluid service</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="AR_CheckGroup" id="TBR"
                                                    onChange={() => handleSubserviceChange('Timing belt replacement', 'check')} />
                                                <label className="form-check-label" htmlFor="TBR">Timing belt replacement</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="AR_CheckGroup" id="ARDS"
                                                    onChange={() => handleSubserviceChange('Air recharge & diagnostic service', 'check')} />
                                                <label className="form-check-label" htmlFor="ARDS">Air recharge & diagnostic service</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="AR_CheckGroup" id="TRBO"
                                                    onChange={() => handleSubserviceChange('Tire rotation & balance only', 'check')} />
                                                <label className="form-check-label" htmlFor="TRBO">Tire rotation & balance only</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="AR_CheckGroup" id="BR"
                                                    onChange={() => handleSubserviceChange('Battery replacement', 'check')} />
                                                <label className="form-check-label" htmlFor="BR">Battery replacement</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="AR_CheckGroup" id="ALSD"
                                                    onChange={() => handleSubserviceChange('Anti lock system diagnosis', 'check')} />
                                                <label className="form-check-label" htmlFor="ALSD">Anti lock system diagnosis’s</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="AR_CheckGroup" id="AWBS"
                                                    onChange={() => handleSubserviceChange('Axle work bearing/seals', 'check')} />
                                                <label className="form-check-label" htmlFor="AWBS">Axle work bearing/seals</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="AR_CheckGroup" id="SASR"
                                                    onChange={() => handleSubserviceChange('Shock and struct replacement', 'check')} />
                                                <label className="form-check-label" htmlFor="SASR">Shock and struct replacement</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="AR_CheckGroup" id="SAHC"
                                                    onChange={() => handleSubserviceChange('Starter/alternator/heater cores', 'check')} />
                                                <label className="form-check-label" htmlFor="SAHC">Starter/alternator/heater cores</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="AR_CheckGroup" id="SSS"
                                                    onChange={() => handleSubserviceChange('Suspension system services', 'check')} />
                                                <label className="form-check-label" htmlFor="SSS">Suspension system services</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="AR_CheckGroup" id="alignment"
                                                    onChange={() => handleSubserviceChange('Alignment', 'check')} />
                                                <label className="form-check-label" htmlFor="alignment">Alignment</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="AR_CheckGroup" id="ALSD"
                                                    onChange={() => handleSubserviceChange('Anti lock system diagnosis', 'check')} />
                                                <label className="form-check-label" htmlFor="ALSD">Anti lock system diagnosis</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="AR_CheckGroup" id="FSM"
                                                    onChange={() => handleSubserviceChange('Fleet service/maintenance', 'check')} />
                                                <label className="form-check-label" htmlFor="FSM">Fleet service/maintenance</label>
                                            </div>
                                            {/* <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="AR_CheckGroup" id="AR_OTH" />
                                                <label className="form-check-label" htmlFor="AR_OTH">Other</label>
                                            </div> */}
                                        </div>
                                        {/* <input type="text" className="form-control mt-2" id="AR_OTH" placeholder='Other...' required /> */}
                                    </div> : ''
                                }

                                {/* If Choosen Cleaning Service */}
                                {appointService === 'cleaning_services' ?
                                    <div id="Cleaning_Service" className="mb-3">
                                        <div className="form-label fs-6 mb-2">What type of service do you need?</div>
                                        <div className="d-flex flex-wrap row-cols-1 row-cols-md-2">
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="CS_CheckGroup" id="BHC" required
                                                    onChange={() => handleSubserviceChange('Basic house cleaning', 'check')} />
                                                <label className="form-check-label" htmlFor="BHC">Basic house cleaning</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="CS_CheckGroup" id="deep_cleaning"
                                                    onChange={() => handleSubserviceChange('Deep cleaning', 'check')} />
                                                <label className="form-check-label" htmlFor="deep_cleaning">Deep cleaning</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="CS_CheckGroup" id="laundry_service"
                                                    onChange={() => handleSubserviceChange('Laundry service', 'check')} />
                                                <label className="form-check-label" htmlFor="laundry_service">Laundry service</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="CS_CheckGroup" id="green_service"
                                                    onChange={() => handleSubserviceChange('Green service', 'check')} />
                                                <label className="form-check-label" htmlFor="green_service">Green service</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="CS_CheckGroup" id="sensitization_service"
                                                    onChange={() => handleSubserviceChange('Sensitization service', 'check')} />
                                                <label className="form-check-label" htmlFor="sensitization_service">Sensitization service</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="CS_CheckGroup" id="CAWC"
                                                    onChange={() => handleSubserviceChange('Ceiling and wall cleaning', 'check')} />
                                                <label className="form-check-label" htmlFor="CAWC">Ceiling and wall cleaning</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="CS_CheckGroup" id="blind_cleaning"
                                                    onChange={() => handleSubserviceChange('Blind cleaning', 'check')} />
                                                <label className="form-check-label" htmlFor="blind_cleaning">Blind cleaning</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="CS_CheckGroup" id="curtains_cleaning"
                                                    onChange={() => handleSubserviceChange('Curtains cleaning', 'check')} />
                                                <label className="form-check-label" htmlFor="curtains_cleaning">Curtains cleaning</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="CS_CheckGroup" id="carpet_cleaning"
                                                    onChange={() => handleSubserviceChange('Carpet cleaning', 'check')} />
                                                <label className="form-check-label" htmlFor="carpet_cleaning">Carpet cleaning</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="CS_CheckGroup" id="upholstery_cleaning"
                                                    onChange={() => handleSubserviceChange('Upholstery cleaning', 'check')} />
                                                <label className="form-check-label" htmlFor="upholstery_cleaning">Upholstery cleaning</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="CS_CheckGroup" id="chimney_sweeping"
                                                    onChange={() => handleSubserviceChange('Chimney sweeping', 'check')} />
                                                <label className="form-check-label" htmlFor="chimney_sweeping">Chimney sweeping</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="CS_CheckGroup" id="office_cleaning"
                                                    onChange={() => handleSubserviceChange('Office cleaning', 'check')} />
                                                <label className="form-check-label" htmlFor="office_cleaning">Office cleaning</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="CS_CheckGroup" id="DCAR"
                                                    onChange={() => handleSubserviceChange('Disaster cleaning and restoration', 'check')} />
                                                <label className="form-check-label" htmlFor="DCAR">Disaster cleaning and restoration</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="CS_CheckGroup" id="window_cleaning"
                                                    onChange={() => handleSubserviceChange('Window cleaning', 'check')} />
                                                <label className="form-check-label" htmlFor="window_cleaning">Window cleaning</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="CS_CheckGroup" id="pressure_washing"
                                                    onChange={() => handleSubserviceChange('Pressure washing', 'check')} />
                                                <label className="form-check-label" htmlFor="pressure_washing">Pressure washing</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="CS_CheckGroup" id="restroom_cleaning"
                                                    onChange={() => handleSubserviceChange('Restroom cleaning', 'check')} />
                                                <label className="form-check-label" htmlFor="restroom_cleaning">Restroom cleaning</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="CS_CheckGroup" id="janitorial_services"
                                                    onChange={() => handleSubserviceChange('Janitorial services', 'check')} />
                                                <label className="form-check-label" htmlFor="janitorial_services">Janitorial services</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="CS_CheckGroup" id="school_cleaning"
                                                    onChange={() => handleSubserviceChange('School cleaning', 'check')} />
                                                <label className="form-check-label" htmlFor="school_cleaning">School cleaning</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="CS_CheckGroup" id="medical_cleaning"
                                                    onChange={() => handleSubserviceChange('Medical cleaning', 'check')} />
                                                <label className="form-check-label" htmlFor="medical_cleaning">Medical cleaning</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="CS_CheckGroup" id="sports_cleaning"
                                                    onChange={() => handleSubserviceChange('Sports cleaning', 'check')} />
                                                <label className="form-check-label" htmlFor="sports_cleaning">Sports cleaning</label>
                                            </div>
                                            {/* <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="CS_CheckGroup" id="CS_OTH" />
                                                <label className="form-check-label" htmlFor="CS_OTH">Other</label>
                                            </div> */}
                                        </div>
                                        {/* <input type="text" className="form-control mt-2" id="CS_OTH" placeholder='Other...' required /> */}
                                    </div>
                                    : ''
                                }

                                {/* If Choosen Dental Services */}
                                {appointService === 'dentist' ?
                                    <div id="Dental_Services" className="mb-3">
                                        <div className="form-label fs-6 mb-2">What type of service do you need?</div>
                                        <div className="d-flex flex-wrap row-cols-1 row-cols-md-2">
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="radio" name="DS_radioGroup" id="orthodontics" required
                                                    onChange={() => handleSubserviceChange('Orthodontics', 'radio')} />
                                                <label className="form-check-label" htmlFor="orthodontics">Orthodontics</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="radio" name="DS_radioGroup" id="cosmetic_dentistry"
                                                    onChange={() => handleSubserviceChange('Cosmetic dentistr', 'radio')} />
                                                <label className="form-check-label" htmlFor="cosmetic_dentistry">Cosmetic dentistry</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="radio" name="DS_radioGroup" id="dentistry"
                                                    onChange={() => handleSubserviceChange('Dentistry', 'radio')} />
                                                <label className="form-check-label" htmlFor="dentistry">Dentistry</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="radio" name="DS_radioGroup" id="prosthodontics"
                                                    onChange={() => handleSubserviceChange('Prosthodontics', 'radio')} />
                                                <label className="form-check-label" htmlFor="prosthodontics">Prosthodontics</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="radio" name="DS_radioGroup" id="endodontics"
                                                    onChange={() => handleSubserviceChange('Endodontics', 'radio')} />
                                                <label className="form-check-label" htmlFor="endodontics">Endodontics</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="radio" name="DS_radioGroup" id="pediatrics_dentistry"
                                                    onChange={() => handleSubserviceChange('Pediatrics dentistry', 'radio')} />
                                                <label className="form-check-label" htmlFor="pediatrics_dentistry">Pediatrics dentistry</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="radio" name="DS_radioGroup" id="dental_extraction"
                                                    onChange={() => handleSubserviceChange('Dental extraction', 'radio')} />
                                                <label className="form-check-label" htmlFor="dental_extraction">Dental extraction</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="radio" name="DS_radioGroup" id="dental_restoration"
                                                    onChange={() => handleSubserviceChange('Dental restoration', 'radio')} />
                                                <label className="form-check-label" htmlFor="dental_restoration">Dental restoration</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="radio" name="DS_radioGroup" id="dental_surgery"
                                                    onChange={() => handleSubserviceChange('Dental surgery', 'radio')} />
                                                <label className="form-check-label" htmlFor="dental_surgery">Dental surgery</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="radio" name="DS_radioGroup" id="denture"
                                                    onChange={() => handleSubserviceChange('Denture', 'radio')} />
                                                <label className="form-check-label" htmlFor="denture">Denture</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="radio" name="DS_radioGroup" id="dental_implants"
                                                    onChange={() => handleSubserviceChange('Dental implants', 'radio')} />
                                                <label className="form-check-label" htmlFor="dental_implants">Dental implants</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="radio" name="DS_radioGroup" id="teeth_cleaning"
                                                    onChange={() => handleSubserviceChange('Teeth cleaning', 'radio')} />
                                                <label className="form-check-label" htmlFor="teeth_cleaning">Teeth cleaning</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="radio" name="DS_radioGroup" id="restoration_dentistry"
                                                    onChange={() => handleSubserviceChange('Restoration dentistry', 'radio')} />
                                                <label className="form-check-label" htmlFor="restoration_dentistry">Restoration dentistry</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="radio" name="DS_radioGroup" id="crown"
                                                    onChange={() => handleSubserviceChange('Crown', 'radio')} />
                                                <label className="form-check-label" htmlFor="crown">Crown</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="radio" name="DS_radioGroup" id="oral_hygiene"
                                                    onChange={() => handleSubserviceChange('Oral hygiene', 'radio')} />
                                                <label className="form-check-label" htmlFor="oral_hygiene">Oral hygiene</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="radio" name="DS_radioGroup" id="teeth_whitening"
                                                    onChange={() => handleSubserviceChange('Teeth whitening', 'radio')} />
                                                <label className="form-check-label" htmlFor="teeth_whitening">Teeth whitening</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="radio" name="DS_radioGroup" id="periodontics"
                                                    onChange={() => handleSubserviceChange('Periodontics', 'radio')} />
                                                <label className="form-check-label" htmlFor="periodontics">Periodontics</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="radio" name="DS_radioGroup" id="dantal_braces"
                                                    onChange={() => handleSubserviceChange('Dantal braces', 'radio')} />
                                                <label className="form-check-label" htmlFor="dantal_braces">Dantal braces</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="radio" name="DS_radioGroup" id="Invisalign"
                                                    onChange={() => handleSubserviceChange('Invisalign', 'radio')} />
                                                <label className="form-check-label" htmlFor="Invisalign">Invisalign</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="radio" name="DS_radioGroup" id="root_canal"
                                                    onChange={() => handleSubserviceChange('Root canal', 'radio')} />
                                                <label className="form-check-label" htmlFor="root_canal">Root canal</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="radio" name="DS_radioGroup" id="dental_sealant"
                                                    onChange={() => handleSubserviceChange('Dental sealant', 'radio')} />
                                                <label className="form-check-label" htmlFor="dental_sealant">Dental sealant</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="radio" name="DS_radioGroup" id="RCT"
                                                    onChange={() => handleSubserviceChange('Root canal treatment', 'radio')} />
                                                <label className="form-check-label" htmlFor="RCT">Root canal treatment </label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="radio" name="DS_radioGroup" id="veneer"
                                                    onChange={() => handleSubserviceChange('Veneer', 'radio')} />
                                                <label className="form-check-label" htmlFor="veneer">Veneer</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="radio" name="DS_radioGroup" id="bridge"
                                                    onChange={() => handleSubserviceChange('Bridge', 'radio')} />
                                                <label className="form-check-label" htmlFor="bridge">Bridge</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="radio" name="DS_radioGroup" id="DS_OTH"
                                                    onChange={() => handleSubserviceChange('Other')} />
                                                <label className="form-check-label" htmlFor="DS_OTH">Other</label>
                                            </div>
                                        </div>
                                        {isOtherSubService ?
                                            <input type="text" className="form-control mt-2" id="DS_OTH" placeholder='Other...' required
                                                onChange={(e) => handleOtherSubServiceChange(e)} />
                                            : ''}
                                    </div> : ''
                                }

                                {/* If Choosen Chiropractic Services */}
                                {appointService === 'chiropractor' ?
                                    <div id="Chiropractic_Services" className="mb-3">
                                        <div className="form-label fs-6 mb-2">What type of service do you need?</div>
                                        <div className="d-flex flex-wrap row-cols-1 row-cols-md-2">
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="radio" name="CS_radioGroup1" id="therapeutic_exercises" required
                                                    onChange={() => handleSubserviceChange('Therapeutic exercises', 'radio')} />
                                                <label className="form-check-label" htmlFor="therapeutic_exercises">Therapeutic exercises</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="radio" name="CS_radioGroup1" id="therapeutics_stretching"
                                                    onChange={() => handleSubserviceChange('Therapeutics stretching', 'radio')} />
                                                <label className="form-check-label" htmlFor="therapeutics_stretching">Therapeutics stretching</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="radio" name="CS_radioGroup1" id="spinal_traction"
                                                    onChange={() => handleSubserviceChange('Spinal traction', 'radio')} />
                                                <label className="form-check-label" htmlFor="spinal_traction">Spinal traction</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="radio" name="CS_radioGroup1" id="STMT"
                                                    onChange={() => handleSubserviceChange('Soft tissue manual therapy', 'radio')} />
                                                <label className="form-check-label" htmlFor="STMT">Soft tissue manual therapy</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="radio" name="CS_radioGroup1" id="muscle_stimulation"
                                                    onChange={() => handleSubserviceChange('Muscle stimulation', 'radio')} />
                                                <label className="form-check-label" htmlFor="muscle_stimulation">Muscle stimulation</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="radio" name="CS_radioGroup1" id="TENS"
                                                    onChange={() => handleSubserviceChange('Transcutaneous electric nerve stimulation', 'radio')} />
                                                <label className="form-check-label" htmlFor="TENS">Transcutaneous electric nerve stimulation</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="radio" name="CS_radioGroup1" id="ultrasound"
                                                    onChange={() => handleSubserviceChange('Ultrasound', 'radio')} />
                                                <label className="form-check-label" htmlFor="ultrasound">Ultrasound</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="radio" name="CS_radioGroup1" id="IAH"
                                                    onChange={() => handleSubserviceChange('Ice and heat', 'radio')} />
                                                <label className="form-check-label" htmlFor="IAH">Ice and heat</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="radio" name="CS_radioGroup1" id="DANC"
                                                    onChange={() => handleSubserviceChange('Diet and nutritional counseling', 'radio')} />
                                                <label className="form-check-label" htmlFor="DANC">Diet and nutritional counseling</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="radio" name="CS_radioGroup1" id="lifestyle_modification"
                                                    onChange={() => handleSubserviceChange('Lifestyle modification', 'radio')} />
                                                <label className="form-check-label" htmlFor="lifestyle_modification">Lifestyle modification</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="radio" name="CS_radioGroup1" id="chiropractic_care"
                                                    onChange={() => handleSubserviceChange('Chiropractic care', 'radio')} />
                                                <label className="form-check-label" htmlFor="chiropractic_care">Chiropractic care</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="radio" name="CS_radioGroup1" id="hydrotherapy"
                                                    onChange={() => handleSubserviceChange('Hydrotherapy', 'radio')} />
                                                <label className="form-check-label" htmlFor="hydrotherapy">Hydrotherapy</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="radio" name="CS_radioGroup1" id="pelvic_stabilization"
                                                    onChange={() => handleSubserviceChange('Pelvic stabilization', 'radio')} />
                                                <label className="form-check-label" htmlFor="pelvic_stabilization">Pelvic stabilization</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="radio" name="CS_radioGroup1" id="CS_OTH1"
                                                    onChange={() => handleSubserviceChange('Other')} />
                                                <label className="form-check-label" htmlFor="CS_OTH1">Other</label>
                                            </div>
                                        </div>
                                        {isOtherSubService ?
                                            <input type="text" className="form-control mt-2" id="CS_OTH1" placeholder='Other...' required
                                                onChange={(e) => handleOtherSubServiceChange(e)} />
                                            : ''}
                                    </div> : ''
                                }

                                {/* If Choosen Wedding Planner */}
                                {appointService === 'wedding_planner' ?
                                    <div id="Wedding_Planner" className="mb-3">
                                        <div className="form-label fs-6 mb-2">What type of service do you need?</div>
                                        <div className="d-flex flex-wrap row-cols-1 row-cols-md-2">
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="WP_CheckGroup" id="WCDT" required
                                                    onChange={() => handleSubserviceChange('Wedding Concept & Design(ThemeDecoration)', 'check')} />
                                                <label className="form-check-label" htmlFor="WCDT">Wedding Concept & Design(ThemeDecoration)</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="WP_CheckGroup" id="Venue_Selection"
                                                    onChange={() => handleSubserviceChange('Venue Selection', 'check')} />
                                                <label className="form-check-label" htmlFor="Venue_Selection">Venue Selection</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="WP_CheckGroup" id="CAMS"
                                                    onChange={() => handleSubserviceChange('Catering and Menu Selection', 'check')} />
                                                <label className="form-check-label" htmlFor="CAMS">Catering and Menu Selection</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="WP_CheckGroup" id="MAE"
                                                    onChange={() => handleSubserviceChange('Music and Entertainment', 'check')} />
                                                <label className="form-check-label" htmlFor="MAE">Music and Entertainment</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="WP_CheckGroup" id="Officiate"
                                                    onChange={() => handleSubserviceChange('Officiate', 'check')} />
                                                <label className="form-check-label" htmlFor="Officiate">Officiate</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="WP_CheckGroup" id="SAAV"
                                                    onChange={() => handleSubserviceChange('Staging and Audio/Visual', 'check')} />
                                                <label className="form-check-label" htmlFor="SAAV">Staging and Audio/Visual</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="WP_CheckGroup" id="lighting_design"
                                                    onChange={() => handleSubserviceChange('Lighting Design', 'check')} />
                                                <label className="form-check-label" htmlFor="lighting_design">Lighting Design</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="WP_CheckGroup" id="IGM"
                                                    onChange={() => handleSubserviceChange('Invitations & Guest Management', 'check')} />
                                                <label className="form-check-label" htmlFor="IGM">Invitations & Guest Management</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="WP_CheckGroup" id="CDWDI"
                                                    onChange={() => handleSubserviceChange('Comprehensive detailed ‘Wedding Day Itinerary', 'check')} />
                                                <label className="form-check-label" htmlFor="CDWDI">Comprehensive detailed ‘Wedding Day Itinerary</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="WP_CheckGroup" id="WIS"
                                                    onChange={() => handleSubserviceChange('Wedding Invitation & Stationary', 'check')} />
                                                <label className="form-check-label" htmlFor="WIS">Wedding Invitation & Stationary</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="WP_CheckGroup" id="Reception_Management"
                                                    onChange={() => handleSubserviceChange('Reception Management', 'check')} />
                                                <label className="form-check-label" htmlFor="Reception_Management">Reception Management</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="WP_CheckGroup" id="TPS"
                                                    onChange={() => handleSubserviceChange('Trousseau & Personal Shopping', 'check')} />
                                                <label className="form-check-label" htmlFor="TPS">Trousseau & Personal Shopping</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="WP_CheckGroup" id="ASS"
                                                    onChange={() => handleSubserviceChange('Accommodations Search & Selection', 'check')} />
                                                <label className="form-check-label" htmlFor="ASS">Accommodations Search & Selection</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="WP_CheckGroup" id="Transportation"
                                                    onChange={() => handleSubserviceChange('Transportation', 'check')} />
                                                <label className="form-check-label" htmlFor="Transportation">Transportation</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="WP_CheckGroup" id="CRN"
                                                    onChange={() => handleSubserviceChange('Contract Review & Negotiation', 'check')} />
                                                <label className="form-check-label" htmlFor="CRN">Contract Review & Negotiation</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="WP_CheckGroup" id="Rehearsal_Coordination"
                                                    onChange={() => handleSubserviceChange('Rehearsal Coordination', 'check')} />
                                                <label className="form-check-label" htmlFor="Rehearsal_Coordination">Rehearsal Coordination</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="WP_CheckGroup" id="Event_Timeline"
                                                    onChange={() => handleSubserviceChange('Event Timeline', 'check')} />
                                                <label className="form-check-label" htmlFor="Event_Timeline">Event Timeline</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="WP_CheckGroup" id="Budgeting"
                                                    onChange={() => handleSubserviceChange('Budgeting', 'check')} />
                                                <label className="form-check-label" htmlFor="Budgeting">Budgeting</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="WP_CheckGroup" id="Photography/Videography"
                                                    onChange={() => handleSubserviceChange('Photography/Videography', 'check')} />
                                                <label className="form-check-label" htmlFor="Photography/Videography">Photography/Videography</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="WP_CheckGroup" id="Wedding_Cinematography"
                                                    onChange={() => handleSubserviceChange('Wedding Cinematography', 'check')} />
                                                <label className="form-check-label" htmlFor="Wedding_Cinematography">Wedding Cinematography *</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="WP_CheckGroup" id="DASAWDI"
                                                    onChange={() => handleSubserviceChange('Delivery and set-up of all wedding day items', 'check')} />
                                                <label className="form-check-label" htmlFor="DASAWDI">Delivery and set-up of all wedding day items</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="WP_CheckGroup" id="FUWV"
                                                    onChange={() => handleSubserviceChange('Follow up with vendors', 'check')} />
                                                <label className="form-check-label" htmlFor="FUWV">Follow up with vendors</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="WP_CheckGroup" id="PATYWS"
                                                    onChange={() => handleSubserviceChange('Personalized Attention to your wedding shopping (wedding trousseau)', 'check')} />
                                                <label className="form-check-label" htmlFor="PATYWS">Personalized Attention to your wedding shopping (wedding trousseau)</label>
                                            </div>
                                            {/* <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="WP_CheckGroup" id="WP_OTH" />
                                                <label className="form-check-label" htmlFor="WP_OTH">Other</label>
                                            </div> */}
                                        </div>
                                        {/* <input type="text" className="form-control mt-2" id="WP_OTH" placeholder='Other...' required /> */}
                                    </div> : ''}


                                {/* If Choosen Auto Detailing */}
                                {appointService === 'auto_detailing' ?
                                    <div id="Auto_Detailing" className="mb-3">
                                        <div className="form-label fs-6 mb-2">What type of service do you need?</div>
                                        <div className="d-flex flex-wrap row-cols-1 row-cols-md-2">
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="AD_CheckGroup" id="Engine_cleaning"
                                                    onChange={() => handleSubserviceChange('Engine cleaning', 'check')} />
                                                <label className="form-check-label" htmlFor="Engine_cleaning">Engine cleaning</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="AD_CheckGroup" id="Exterior_detailing"
                                                    onChange={() => handleSubserviceChange('Exterior detailing', 'check')} />
                                                <label className="form-check-label" htmlFor="Exterior_detailing">Exterior detailing</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="AD_CheckGroup" id="FSP"
                                                    onChange={() => handleSubserviceChange('Full service package', 'check')} />
                                                <label className="form-check-label" htmlFor="FSP">Full service package</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="AD_CheckGroup" id="Headlight_restoration"
                                                    onChange={() => handleSubserviceChange('Headlight restoration', 'check')} />
                                                <label className="form-check-label" htmlFor="Headlight_restoration">Headlight restoration</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="AD_CheckGroup" id="Interior_detailing"
                                                    onChange={() => handleSubserviceChange('Interior detailing', 'check')} />
                                                <label className="form-check-label" htmlFor="Interior_detailing">Interior detailing</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="AD_CheckGroup" id="UACC"
                                                    onChange={() => handleSubserviceChange('Upholstery and carpet cleaning', 'check')} />
                                                <label className="form-check-label" htmlFor="UACC">Upholstery and carpet cleaning</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="AD_CheckGroup" id="WARD"
                                                    onChange={() => handleSubserviceChange('Wheel and rim detailing', 'check')} />
                                                <label className="form-check-label" htmlFor="WARD">Wheel and rim detailing</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="AD_CheckGroup" id="Window_cleaning"
                                                    onChange={() => handleSubserviceChange('Window cleaning', 'check')} />
                                                <label className="form-check-label" htmlFor="Window_cleaning">Window cleaning</label>
                                            </div>
                                            {/* <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="AD_CheckGroup" id="AD_OTH" />
                                                <label className="form-check-label" htmlFor="AD_OTH">Other</label>
                                            </div> */}

                                            {/* <input type="text" className="form-control mt-2" id="AD_OTH" placeholder='Other...' required /> */}

                                            <div className="w-100 mt-2">
                                                <label className="form-label fs-6 me-3">Do you need cleanup of hair or pet related odors and stains</label>
                                                <div className="d-flex flex-wrap">
                                                    <div className="form-check form-check-inline">
                                                        <input className="form-check-input" type="radio" name="radioGroupYess" id="Yes" required
                                                            onClick={() => setAutoDetailsAns1('Yes')} />
                                                        <label className="form-check-label" htmlFor="Yes">Yes</label>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                        <input className="form-check-input" type="radio" name="radioGroupYess" id="No"
                                                            onClick={() => setAutoDetailsAns1('No')} />
                                                        <label className="form-check-label" htmlFor="No">No</label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="w-100 mt-2">
                                                <label className="form-label fs-6 me-3">Do you want your vehicles disinfected?</label>
                                                <div className="d-flex flex-wrap">
                                                    <div className="form-check form-check-inline">
                                                        <input className="form-check-input" type="radio" name="radioGroupYess1" id="Yes1" required
                                                            onClick={() => setAutoDetailsAns2('Yes')} />
                                                        <label className="form-check-label" htmlFor="Yes1">Yes</label>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                        <input className="form-check-input" type="radio" name="radioGroupYess1" id="No1"
                                                            onClick={() => setAutoDetailsAns2('No')} />
                                                        <label className="form-check-label" htmlFor="No1">No</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> : ''
                                }

                                {/* If Choosen Handyman Service */}
                                {appointService === 'handyman' ?
                                    <div id="Handyman" className="mb-3">
                                        <div className="form-label fs-6 mb-2">What type of service do you need?</div>
                                        <div className="d-flex flex-wrap row-cols-1 row-cols-md-2">
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="radio" name="Handyman_radioGroup" id="Assembly"
                                                    onChange={() => handleSubserviceChange('Assembly', 'radio')} />
                                                <label className="form-check-label" htmlFor="Assembly">Assembly</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="radio" name="Handyman_radioGroup" id="Caulking"
                                                    onChange={() => handleSubserviceChange('Caulking', 'radio')} />
                                                <label className="form-check-label" htmlFor="Caulking">Caulking</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="radio" name="Handyman_radioGroup" id="Cleaning"
                                                    onChange={() => handleSubserviceChange('Cleaning', 'radio')} />
                                                <label className="form-check-label" htmlFor="Cleaning">Cleaning</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="radio" name="Handyman_radioGroup" id="HPS"
                                                    onChange={() => handleSubserviceChange('Home Proofing Services', 'radio')} />
                                                <label className="form-check-label" htmlFor="HPS">Home Proofing Services</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="radio" name="Handyman_radioGroup" id="Installation"
                                                    onChange={() => handleSubserviceChange('Installation', 'radio')} />
                                                <label className="form-check-label" htmlFor="Installation">Installation</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="radio" name="Handyman_radioGroup" id="Painting"
                                                    onChange={() => handleSubserviceChange('Painting', 'radio')} />
                                                <label className="form-check-label" htmlFor="Painting">Painting</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="radio" name="Handyman_radioGroup" id="Repair"
                                                    onChange={() => handleSubserviceChange('Repair', 'radio')} />
                                                <label className="form-check-label" htmlFor="Repair">Repair</label>
                                            </div>
                                        </div>
                                    </div> : ''
                                }

                                {/* If Choosen Physical Training Service */}
                                {appointService === 'physical_training' ?
                                    <div id="Physical_Training" className="mb-3">
                                        <div className="form-label fs-6 mb-2">What type of service do you need?</div>
                                        <div className="d-flex flex-wrap row-cols-1 row-cols-md-2">
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="PT_CheckGroup" id="Cardio_Fitness"
                                                    onChange={() => handleSubserviceChange('Cardio Fitness', 'check')} />
                                                <label className="form-check-label" htmlFor="Cardio_Fitness">Cardio Fitness</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="PT_CheckGroup" id="Group_Training"
                                                    onChange={() => handleSubserviceChange('Group Training', 'check')} />
                                                <label className="form-check-label" htmlFor="Group_Training">Group Training</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="PT_CheckGroup" id="Nutrition_Counseling"
                                                    onChange={() => handleSubserviceChange('Nutrition Counseling', 'check')} />
                                                <label className="form-check-label" htmlFor="Nutrition_Counseling">Nutrition Counseling</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="PT_CheckGroup" id="PPNE"
                                                    onChange={() => handleSubserviceChange('PRE/POST NATAL EXERCISE', 'check')} />
                                                <label className="form-check-label" htmlFor="PPNE">PRE/POST NATAL EXERCISE</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="PT_CheckGroup" id="Senior_Fitness"
                                                    onChange={() => handleSubserviceChange('Senior Fitness', 'check')} />
                                                <label className="form-check-label" htmlFor="Senior_Fitness">Senior Fitness</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="PT_CheckGroup" id="Sports_Conditioning"
                                                    onChange={() => handleSubserviceChange('Sports Conditioning', 'check')} />
                                                <label className="form-check-label" htmlFor="Sports_Conditioning">Sports Conditioning</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="PT_CheckGroup" id="Specialty_Services"
                                                    onChange={() => handleSubserviceChange('Specialty Services', 'check')} />
                                                <label className="form-check-label" htmlFor="Specialty_Services">Specialty Services</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="PT_CheckGroup" id="Strength_Training"
                                                    onChange={() => handleSubserviceChange('Strength Training', 'check')} />
                                                <label className="form-check-label" htmlFor="Strength_Training">Strength Training</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="PT_CheckGroup" id="Stretch"
                                                    onChange={() => handleSubserviceChange('Stretch', 'check')} />
                                                <label className="form-check-label" htmlFor="Stretch">Stretch</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="PT_CheckGroup" id="WLT"
                                                    onChange={() => handleSubserviceChange('Weight Loss & Toning', 'check')} />
                                                <label className="form-check-label" htmlFor="WLT">Weight Loss & Toning</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="PT_CheckGroup" id="WRT"
                                                    onChange={() => handleSubserviceChange('Weight & Resistance Training', 'check')} />
                                                <label className="form-check-label" htmlFor="WRT">Weight & Resistance Training</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="PT_CheckGroup" id="YTF"
                                                    onChange={() => handleSubserviceChange('Youth & Teen Fitness', 'check')} />
                                                <label className="form-check-label" htmlFor="YTF">Youth & Teen Fitness</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="PT_CheckGroup" id="VPT"
                                                    onChange={() => handleSubserviceChange('Virtual Personal Training', 'check')} />
                                                <label className="form-check-label" htmlFor="VPT">Virtual Personal Training</label>
                                            </div>
                                        </div>
                                    </div> : ''
                                }

                                {/* If Choosen Physical therapy Service */}
                                {appointService === 'physical_therapy' ?
                                    <div id="Physical_Therapy" className="mb-3">
                                        <div className="form-label fs-6 mb-2">What do you need a therapy to address?</div>
                                        <div className="d-flex flex-wrap row-cols-1 row-cols-md-2">
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="radio" name="Therapy_radioGroup" id="chronic_pain"
                                                    onChange={() => handleSubserviceChange('Chronic pain', 'radio')} />
                                                <label className="form-check-label" htmlFor="chronic_pain">Chronic pain</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="radio" name="Therapy_radioGroup" id="muscle_strain"
                                                    onChange={() => handleSubserviceChange('Muscle strain', 'radio')} />
                                                <label className="form-check-label" htmlFor="muscle_strain">Muscle strain</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="radio" name="Therapy_radioGroup" id="physical_therapy"
                                                    onChange={() => handleSubserviceChange('Physical therapy', 'radio')} />
                                                <label className="form-check-label" htmlFor="physical_therapy">Physical therapy</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="radio" name="Therapy_radioGroup" id="posture"
                                                    onChange={() => handleSubserviceChange('Posture', 'radio')} />
                                                <label className="form-check-label" htmlFor="posture">Posture</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="radio" name="Therapy_radioGroup" id="work_injuries"
                                                    onChange={() => handleSubserviceChange('Work injuries', 'radio')} />
                                                <label className="form-check-label" htmlFor="work_injuries">Work injuries</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="radio" name="Therapy_radioGroup" id="back_pain"
                                                    onChange={() => handleSubserviceChange('Back pain', 'radio')} />
                                                <label className="form-check-label" htmlFor="back_pain">Back pain</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="radio" name="Therapy_radioGroup" id="home_therapy"
                                                    onChange={() => handleSubserviceChange('Home therapy', 'radio')} />
                                                <label className="form-check-label" htmlFor="home_therapy">Home therapy</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="radio" name="Therapy_radioGroup" id="pediatric_therapy"
                                                    onChange={() => handleSubserviceChange('Pediatric therapy', 'radiio')} />
                                                <label className="form-check-label" htmlFor="pediatric_therapy">Pediatric therapy</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="radio" name="Therapy_radioGroup" id="athletic_training"
                                                    onChange={() => handleSubserviceChange('Athletic training', 'radio')} />
                                                <label className="form-check-label" htmlFor="athletic_training">Athletic training</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="radio" name="Therapy_radioGroup" id="occupational_hand_therapy"
                                                    onChange={() => handleSubserviceChange('Occupational / hand therapy', 'radio')} />
                                                <label className="form-check-label" htmlFor="occupational_hand_therapy">Occupational / hand therapy</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="radio" name="Therapy_radioGroup" id="sports_injury"
                                                    onChange={() => handleSubserviceChange('Sports injury', 'radio')} />
                                                <label className="form-check-label" htmlFor="sports_injury">Sports injury</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="radio" name="Therapy_radioGroup" id="therapy_not_sure"
                                                    onChange={() => handleSubserviceChange('Not sure', 'radio')} />
                                                <label className="form-check-label" htmlFor="therapy_not_sure">Not sure</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="radio" name="Therapy_radioGroup" id="Therapy_OTH"
                                                    onChange={() => handleSubserviceChange('Other')} />
                                                <label className="form-check-label" htmlFor="Therapy_OTH">Other</label>
                                            </div>
                                            {isOtherSubService ?
                                                <input type="text" className="form-control mt-2" id="MOS_OTH" placeholder='Others therapy...' required
                                                    onChange={(e) => handleOtherSubServiceChange(e)} />
                                                : ''}
                                        </div>
                                        <div className='other-wrapper mt-2'>
                                            <div className="form-label fs-6 mb-2">How long do you want the session to last?</div>
                                            <div className="d-flex flex-wrap g-2">
                                                <div className="form-check pe-2">
                                                    <input className="form-check-input" type="radio" name="therapy_radioGroup1" id="30_minT"
                                                        onChange={(e) => handlePhysicalTherapyOtherAns1('30 minutes')} />
                                                    <label className="form-check-label" htmlFor="30_minT">30 minutes</label>
                                                </div>
                                                <div className="form-check pe-2">
                                                    <input className="form-check-input" type="radio" name="therapy_radioGroup1" id="45_minT"
                                                        onChange={(e) => handlePhysicalTherapyOtherAns1('45 minutes')} />
                                                    <label className="form-check-label" htmlFor="45_minT">45 minutes</label>
                                                </div>
                                                <div className="form-check pe-2">
                                                    <input className="form-check-input" type="radio" name="therapy_radioGroup1" id="60_minT"
                                                        onChange={(e) => handlePhysicalTherapyOtherAns1('60 minutes')} />
                                                    <label className="form-check-label" htmlFor="60_minT">60 min</label>
                                                </div>
                                                <div className="form-check pe-2">
                                                    <input className="form-check-input" type="radio" name="therapy_radioGroup1" id="90_minT"
                                                        onChange={(e) => handlePhysicalTherapyOtherAns1('90 minutes')} />
                                                    <label className="form-check-label" htmlFor="90_minT">90 min</label>
                                                </div>
                                                <div className="form-check pe-2">
                                                    <input className="form-check-input" type="radio" name="therapy_radioGroup1" id="therapy_NOTSURE"
                                                        onChange={(e) => handlePhysicalTherapyOtherAns1('Not Sure')} />
                                                    <label className="form-check-label" htmlFor="therapy_NOTSURE">Not Sure</label>
                                                </div>
                                                <div className="form-check pe-2">
                                                    <input className="form-check-input" type="radio" name="therapy_radioGroup1" id="MSG_OTH1"
                                                        onChange={(e) => handlePhysicalTherapyOtherAns1('Other')} />
                                                    <label className="form-check-label" htmlFor="MSG_OTH1">Other</label>
                                                </div>
                                                {physicalTherapyOtherAnsOther1 ?
                                                    <input type="text" className="form-control mt-2" id="therapy_OTH1" placeholder='Other session...' required
                                                        onChange={(e) => setPhysicalTherapyOtherAns1(e.target.value)} />
                                                    : ''}
                                            </div>
                                        </div>
                                        <div className='other-wrapper mt-2'>
                                            <div className="form-label fs-6 mb-2">How often will you need treatment?</div>
                                            <div className="d-flex flex-wrap g-2">
                                                <div className="form-check pe-2">
                                                    <input className="form-check-input" type="radio" name="therapy_radioGroup2" id="oneTimeT"
                                                        onChange={(e) => handlePhysicalTherapyOtherAns2('One time')} />
                                                    <label className="form-check-label" htmlFor="oneTimeT">One time</label>
                                                </div>
                                                <div className="form-check pe-2">
                                                    <input className="form-check-input" type="radio" name="therapy_radioGroup2" id="OAWT"
                                                        onChange={(e) => handlePhysicalTherapyOtherAns2('Once a week')} />
                                                    <label className="form-check-label" htmlFor="OAWT">Once a week</label>
                                                </div>
                                                <div className="form-check pe-2">
                                                    <input className="form-check-input" type="radio" name="therapy_radioGroup2" id="TOMT"
                                                        onChange={(e) => handlePhysicalTherapyOtherAns2('Twice a month')} />
                                                    <label className="form-check-label" htmlFor="TOMT">Twice a month</label>
                                                </div>
                                                <div className="form-check pe-2">
                                                    <input className="form-check-input" type="radio" name="therapy_radioGroup2" id="treat_NOTSURE"
                                                        onChange={(e) => handlePhysicalTherapyOtherAns2('Not Sure')} />
                                                    <label className="form-check-label" htmlFor="treat_NOTSURE">Not Sure</label>
                                                </div>
                                                <div className="form-check pe-2">
                                                    <input className="form-check-input" type="radio" name="therapy_radioGroup2" id="MSG_OTH1"
                                                        onChange={(e) => handlePhysicalTherapyOtherAns2('Other')} />
                                                    <label className="form-check-label" htmlFor="MSG_OTH1">Other</label>
                                                </div>
                                                {physicalTherapyOtherAnsOther2 ?
                                                    <input type="text" className="form-control mt-2" id="therapy_OTH2" placeholder='Other...' required
                                                        onChange={(e) => setPhysicalTherapyOtherAns2(e.target.value)} />
                                                    : ''}
                                            </div>
                                        </div>

                                        <div className='other-wrapper mt-2'>
                                            <div className="form-label fs-6 mb-2">Do need the business to come to you?</div>
                                            <div className="d-flex flex-wrap g-2">
                                                <div className="form-check pe-2">
                                                    <input className="form-check-input" type="radio" name="therapy_radioGroup3" id="treatmentYes"
                                                        onChange={(e) => setPhysicalTherapyTreatmentNeed('yes')} />
                                                    <label className="form-check-label" htmlFor="treatmentYes">Yes</label>
                                                </div>
                                                <div className="form-check pe-2">
                                                    <input className="form-check-input" type="radio" name="therapy_radioGroup3" id="treatmentNo"
                                                        onChange={(e) => setPhysicalTherapyTreatmentNeed('no')} />
                                                    <label className="form-check-label" htmlFor="treatmentNo">No</label>
                                                </div>

                                            </div>
                                            {physicalTherapyTreatmentNeed === 'yes' ?
                                                <div className="d-flex flex-wrap g-2 mt-2">
                                                    <div className="form-label fs-6 mb-1">What address or location do you need the service?</div>
                                                    <input type="text" className="form-control mt-2" id="therapy_OTH2" placeholder='address you need the service...'
                                                        onChange={(e) => setPhysicalTherapyTreatmentAddress(e.target.value)} />
                                                </div>
                                                : ''}
                                        </div>

                                    </div> : ''
                                }

                                {/* If Choosen Business Consulting Service */}
                                {appointService === 'business_consulting' ?
                                    <div id="Business_Consulting" className="mb-3">
                                        <div className="form-label fs-6 mb-2">What type of service do you need?</div>
                                        <div className="d-flex flex-wrap row-cols-1 row-cols-md-2">
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="PH_CheckGroup" id="Strategy_consulting"
                                                    onChange={() => handleSubserviceChange('Strategy consulting', 'check')} />
                                                <label className="form-check-label" htmlFor="Strategy_consulting">Strategy consulting</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="PH_CheckGroup" id="Marketing_consulting"
                                                    onChange={() => handleSubserviceChange('Marketing consulting', 'check')} />
                                                <label className="form-check-label" htmlFor="Marketing_consulting">Marketing consulting</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="PH_CheckGroup" id="Operations_consulting"
                                                    onChange={() => handleSubserviceChange('Operations consulting', 'check')} />
                                                <label className="form-check-label" htmlFor="Operations_consulting">Operations consulting</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="PH_CheckGroup" id="Management_consulting"
                                                    onChange={() => handleSubserviceChange('Management consulting', 'check')} />
                                                <label className="form-check-label" htmlFor="Management_consulting">Management consulting</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="PH_CheckGroup" id="Financial_consulting"
                                                    onChange={() => handleSubserviceChange('Financial consulting', 'check')} />
                                                <label className="form-check-label" htmlFor="Financial_consulting">Financial consulting</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="PH_CheckGroup" id="HR_consulting"
                                                    onChange={() => handleSubserviceChange('HR consulting', 'check')} />
                                                <label className="form-check-label" htmlFor="HR_consulting">HR consulting</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="PH_CheckGroup" id="Compliance_consulting"
                                                    onChange={() => handleSubserviceChange('Compliance consulting', 'check')} />
                                                <label className="form-check-label" htmlFor="Compliance_consulting">Compliance consulting</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="PH_CheckGroup" id="ITTC"
                                                    onChange={() => handleSubserviceChange('IT/Technology consulting', 'check')} />
                                                <label className="form-check-label" htmlFor="ITTC">IT/Technology consulting</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="PH_CheckGroup" id="Legal_consultant"
                                                    onChange={() => handleSubserviceChange('Legal consultant', 'check')} />
                                                <label className="form-check-label" htmlFor="Legal_consultant">Legal consultant</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="PH_CheckGroup" id="SMC"
                                                    onChange={() => handleSubserviceChange('Social media consultant', 'check')} />
                                                <label className="form-check-label" htmlFor="SMC">Social media consultant</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="PH_CheckGroup" id="Sustainability_consultant"
                                                    onChange={() => handleSubserviceChange('Sustainability consultant', 'check')} />
                                                <label className="form-check-label" htmlFor="Sustainability_consultant">Sustainability consultant</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="PH_CheckGroup" id="Sales_consultant"
                                                    onChange={() => handleSubserviceChange('Sales consultant', 'check')} />
                                                <label className="form-check-label" htmlFor="Sales_consultant">Sales consultant</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="PH_CheckGroup" id="Wellness_FC"
                                                    onChange={() => handleSubserviceChange('Wellness/Fitness consultant', 'check')} />
                                                <label className="form-check-label" htmlFor="Wellness_FC">Wellness/Fitness consultant</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="PH_CheckGroup" id="GMC"
                                                    onChange={() => handleSubserviceChange('Growth Marketing consultant', 'check')} />
                                                <label className="form-check-label" htmlFor="GMC">Growth Marketing consultant</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="PH_CheckGroup" id="CC_consultancy"
                                                    onChange={() => handleSubserviceChange('Career coaching consultancy', 'check')} />
                                                <label className="form-check-label" htmlFor="CC_consultancy">Career coaching consultancy</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="PH_CheckGroup" id="PR_consultancy"
                                                    onChange={() => handleSubserviceChange('PR consultancy', 'check')} />
                                                <label className="form-check-label" htmlFor="PR_consultancy">PR consultancy</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="PH_CheckGroup" id="SEO_consulting"
                                                    onChange={() => handleSubserviceChange('SEO consulting', 'check')} />
                                                <label className="form-check-label" htmlFor="SEO_consulting">SEO consulting</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="PH_CheckGroup" id="Leadership_consulting"
                                                    onChange={() => handleSubserviceChange('Leadership consulting', 'check')} />
                                                <label className="form-check-label" htmlFor="Leadership_consulting">Leadership consulting</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="PH_CheckGroup" id="PD_consulting"
                                                    onChange={() => handleSubserviceChange('Product development consulting', 'check')} />
                                                <label className="form-check-label" htmlFor="PD_consulting">Product development consulting</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="PH_CheckGroup" id="Design_consultant"
                                                    onChange={() => handleSubserviceChange('Design consultant', 'check')} />
                                                <label className="form-check-label" htmlFor="Design_consultant">Design consultant</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="PH_CheckGroup" id="Brand_consultant"
                                                    onChange={() => handleSubserviceChange('Brand consultant', 'check')} />
                                                <label className="form-check-label" htmlFor="Brand_consultant">Brand consultant</label>
                                            </div>
                                        </div>
                                    </div> : ''
                                }

                                {/* If Choosen Pet service Service */}
                                {appointService === 'pet_service' ?
                                    <div id="Pet_Service" className="mb-3">
                                        <div className="form-label fs-6 mb-2">What type of service do you need?</div>
                                        <div className="d-flex flex-wrap row-cols-1 row-cols-md-2">
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="PetService_CheckGroup" id="Bath"
                                                    onChange={() => handleSubserviceChange('Bath', 'check')} />
                                                <label className="form-check-label" htmlFor="Bath">Bath</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="PetService_CheckGroup" id="Brushing"
                                                    onChange={() => handleSubserviceChange('Brushing', 'check')} />
                                                <label className="form-check-label" htmlFor="Brushing">Brushing</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="PetService_CheckGroup" id="EAEC"
                                                    onChange={() => handleSubserviceChange('Eye and ear cleaning', 'check')} />
                                                <label className="form-check-label" htmlFor="EAEC">Eye and ear cleaning</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="PetService_CheckGroup" id="Hair_trimming"
                                                    onChange={() => handleSubserviceChange('Hair trimming', 'check')} />
                                                <label className="form-check-label" htmlFor="Hair_trimming">Hair trimming</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="PetService_CheckGroup" id="Nail_trimming"
                                                    onChange={() => handleSubserviceChange('Nail trimming', 'check')} />
                                                <label className="form-check-label" htmlFor="Nail_trimming">Nail trimming</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="PetService_CheckGroup" id="Teeth_brushing"
                                                    onChange={() => handleSubserviceChange('Teeth brushing', 'check')} />
                                                <label className="form-check-label" htmlFor="Teeth_brushing">Teeth brushing</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="PetService_CheckGroup" id="De-shedding_treatments"
                                                    onChange={() => handleSubserviceChange('De-shedding treatments', 'check')} />
                                                <label className="form-check-label" htmlFor="De-shedding_treatments">De-shedding treatments</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="PetService_CheckGroup" id="AG_expression"
                                                    onChange={() => handleSubserviceChange('Anal gland expression', 'check')} />
                                                <label className="form-check-label" htmlFor="AG_expression">Anal gland expression</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="PetService_CheckGroup" id="Dematting_treatments"
                                                    onChange={() => handleSubserviceChange('Dematting treatments', 'check')} />
                                                <label className="form-check-label" htmlFor="Dematting_treatments">Dematting treatments</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="PetService_CheckGroup" id="FADT"
                                                    onChange={() => handleSubserviceChange('Flea and deodorizing treatments', 'check')} />
                                                <label className="form-check-label" htmlFor="FADT">Flea and deodorizing treatments</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="PetService_CheckGroup" id="Deep_conditioning"
                                                    onChange={() => handleSubserviceChange('Deep conditioning', 'check')} />
                                                <label className="form-check-label" htmlFor="Deep_conditioning">Deep conditioning</label>
                                            </div>

                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="PetService_CheckGroup" id="training"
                                                    onChange={() => handleSubserviceChange('Training', 'check')} />
                                                <label className="form-check-label" htmlFor="training">Training</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="PetService_CheckGroup" id="boarding"
                                                    onChange={() => handleSubserviceChange('Boarding', 'check')} />
                                                <label className="form-check-label" htmlFor="boarding">Boarding</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="PetService_CheckGroup" id="veterinary"
                                                    onChange={() => handleSubserviceChange('Veterinary', 'check')} />
                                                <label className="form-check-label" htmlFor="veterinary">Veterinary</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="PetService_CheckGroup" id="daycare"
                                                    onChange={() => handleSubserviceChange('Daycare', 'check')} />
                                                <label className="form-check-label" htmlFor="daycare">Daycare</label>
                                            </div>
                                            {/* <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="PH_CheckGroup" id="PH_OTH" />
                                                <label className="form-check-label" htmlFor="PH_OTH">Other</label>
                                            </div> */}

                                            {/* <input type="text" className="form-control mt-2" id="PH_OTH" placeholder='Other...' required /> */}
                                        </div>
                                    </div> : ''
                                }

                                {/* If Choosen Lawyer Service */}
                                {appointService === 'lawyers' ?
                                    <div id="Lawyer_Services" className="mb-3">
                                        <div className="form-label fs-6 mb-2">What type of service do you need?</div>
                                        <div className="d-flex flex-wrap row-cols-1 row-cols-md-2">
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="radio" name="LS_CheckGroup" id="commercial_service"
                                                    onChange={() => handleSubserviceChange('Commercial service', 'radio')} />
                                                <label className="form-check-label" htmlFor="commercial_service">Commercial service</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="radio" name="LS_CheckGroup" id="employment_service"
                                                    onChange={() => handleSubserviceChange('Employment service', 'radio')} />
                                                <label className="form-check-label" htmlFor="employment_service">Employment service</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="radio" name="LS_CheckGroup" id="DDI"
                                                    onChange={() => handleSubserviceChange('Due diligence investigation', 'radio')} />
                                                <label className="form-check-label" htmlFor="DDI">Due diligence investigation </label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="radio" name="LS_CheckGroup" id="civil_litigation"
                                                    onChange={() => handleSubserviceChange('Civil litigation', 'radio')} />
                                                <label className="form-check-label" htmlFor="civil_litigation">Civil litigation</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="radio" name="LS_CheckGroup" id="family_law"
                                                    onChange={() => handleSubserviceChange('Family law', 'radio')} />
                                                <label className="form-check-label" htmlFor="family_law">Family law</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="radio" name="LS_CheckGroup" id="personal"
                                                    onChange={() => handleSubserviceChange('Personal', 'radio')} />
                                                <label className="form-check-label" htmlFor="personal">Personal</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="radio" name="LS_CheckGroup" id="probates_successors"
                                                    onChange={() => handleSubserviceChange('Probates & successors', 'radio')} />
                                                <label className="form-check-label" htmlFor="probates_successors">Probates & successors </label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="radio" name="LS_CheckGroup" id="criminal_litigation"
                                                    onChange={() => handleSubserviceChange('Criminal litigation', 'radio')} />
                                                <label className="form-check-label" htmlFor="criminal_litigation">Criminal litigation</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="radio" name="LS_CheckGroup" id="SPOP"
                                                    onChange={() => handleSubserviceChange('Sales & purchase of properties', 'radio')} />
                                                <label className="form-check-label" htmlFor="SPOP">Sales & purchase of properties</label>
                                            </div>
                                            <div className="form-check w-100 pe-2">
                                                <input className="form-check-input" type="radio" name="LS_CheckGroup" id="CFLIAA"
                                                    onChange={() => handleSubserviceChange('Consultation for legal information and advice', 'radio')} />
                                                <label className="form-check-label" htmlFor="CFLIAA">Consultation for legal information and advice</label>
                                            </div>
                                            <div className="form-check w-100 pe-2">
                                                <input className="form-check-input" type="radio" name="LS_CheckGroup" id="CRD"
                                                    onChange={() => handleSubserviceChange('Consultation to review documents (such as agreements, leases, wills, corporation documents, do-it-yourself court forms)', 'radio')} />
                                                <label className="form-check-label" htmlFor="CRD">Consultation to review documents (such as agreements, leases, wills, corporation documents, do-it-yourself court forms)</label>
                                            </div>
                                            <div className="form-check w-100 pe-2">
                                                <input className="form-check-input" type="radio" name="LS_CheckGroup" id="MAOTPS"
                                                    onChange={() => handleSubserviceChange('Mediation, arbitration, or other third party services', 'radio')} />
                                                <label className="form-check-label" htmlFor="MAOTPS">Mediation, arbitration, or other third party services</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="radio" name="LS_CheckGroup" id="POD"
                                                    onChange={() => handleSubserviceChange('Preparation of documents', 'radio')} />
                                                <label className="form-check-label" htmlFor="POD">Preparation of documents</label>
                                            </div>
                                            <div className="form-check w-100 pe-2">
                                                <input className="form-check-input" type="radio" name="LS_CheckGroup" id="RCN"
                                                    onChange={() => handleSubserviceChange('Representing clients in negotiations (possibly under “collaborative lawyering” agreement where both attorneys withdraw if either side chooses to litigate)', 'radio')} />
                                                <label className="form-check-label" htmlFor="RCN">Representing clients in negotiations (possibly under “collaborative lawyering” agreement where both attorneys withdraw if either side chooses to litigate)</label>
                                            </div>
                                            <div className="form-check w-100 pe-2">
                                                <input className="form-check-input" type="radio" name="LS_CheckGroup" id="RCCAALH"
                                                    onChange={() => handleSubserviceChange('Representing clients in court, arbitration, administrative and legislative hearings', 'radio')} />
                                                <label className="form-check-label" htmlFor="RCCAALH">Representing clients in court, arbitration, administrative and legislative hearings</label>
                                            </div>
                                            {/* <div className="form-check pe-2">
                                                <input className="form-check-input" type="radio" name="LS_CheckGroup" id="LS_OTH" />
                                                <label className="form-check-label" htmlFor="LS_OTH">Other</label>
                                            </div> */}

                                            {/* <input type="text" className="form-control mt-2" id="LS_OTH" placeholder='Other...' required /> */}
                                        </div>
                                    </div> : ''
                                }
                                {/* If choosen Housing Service */}
                                {appointService === 'housing' ?
                                    <div id="Housing" className="mb-3">
                                        <div className="form-label fs-6 mb-2">What type of amenities do you need?</div>
                                        <div className="d-flex flex-wrap row-cols-1 row-cols-md-2">
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="Housing_CheckGroup" id="Swimming Pool"
                                                    onChange={() => handleSubserviceChange('Swimming Pool', 'check')} />
                                                <label className="form-check-label" htmlFor="Swimming Pool">Swimming Pool</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="Housing_CheckGroup" id="Barbecue_Areas"
                                                    onChange={() => handleSubserviceChange('Barbecue Areas', 'check')} />
                                                <label className="form-check-label" htmlFor="Barbecue_Areas">Barbecue Areas</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="Housing_CheckGroup" id="Firepits"
                                                    onChange={() => handleSubserviceChange('Firepits', 'check')} />
                                                <label className="form-check-label" htmlFor="Firepits">Firepits</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="Housing_CheckGroup" id="Gym_Fitness_Centers"
                                                    onChange={() => handleSubserviceChange('Gym/Fitness Centers', 'check')} />
                                                <label className="form-check-label" htmlFor="Gym_Fitness_Centers">Gym/Fitness Centers</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="Housing_CheckGroup" id="YC_Rooms"
                                                    onChange={() => handleSubserviceChange('Yoga/Cardio Rooms', 'check')} />
                                                <label className="form-check-label" htmlFor="YC_Rooms">Yoga/Cardio Rooms</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="Housing_CheckGroup" id="Community_Clubhouse"
                                                    onChange={() => handleSubserviceChange('Community Clubhouse', 'check')} />
                                                <label className="form-check-label" htmlFor="Community_Clubhouse">Community Clubhouse</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="Housing_CheckGroup" id="De-shedding_treatments"
                                                    onChange={() => handleSubserviceChange('De-shedding treatments', 'check')} />
                                                <label className="form-check-label" htmlFor="De-shedding_treatments">De-shedding treatments</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="Housing_CheckGroup" id="Playground"
                                                    onChange={() => handleSubserviceChange('Playground', 'check')} />
                                                <label className="form-check-label" htmlFor="Playground">Playground</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="Housing_CheckGroup" id="RL_Areas"
                                                    onChange={() => handleSubserviceChange('Rooftop Lounge Areas', 'check')} />
                                                <label className="form-check-label" htmlFor="RL_Areas">Rooftop Lounge Areas</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="Housing_CheckGroup" id="Community_Garden"
                                                    onChange={() => handleSubserviceChange('Community Garden', 'check')} />
                                                <label className="form-check-label" htmlFor="Community_Garden">Community Garden</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="Housing_CheckGroup" id="Media_Rooms"
                                                    onChange={() => handleSubserviceChange('Media Rooms', 'check')} />
                                                <label className="form-check-label" htmlFor="Media_Rooms">Media Rooms</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="Housing_CheckGroup" id="Common_Areas"
                                                    onChange={() => handleSubserviceChange('Common Areas', 'check')} />
                                                <label className="form-check-label" htmlFor="Common_Areas">Common Areas</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="Housing_CheckGroup" id="Pet_friendly_Units"
                                                    onChange={() => handleSubserviceChange('Pet-friendly Units', 'check')} />
                                                <label className="form-check-label" htmlFor="Pet_friendly_Units">Pet-friendly Units</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="Housing_CheckGroup" id="Pet_Washing_Stations"
                                                    onChange={() => handleSubserviceChange('Pet Washing Stations', 'check')} />
                                                <label className="form-check-label" htmlFor="Pet_Washing_Stations">Pet Washing Stations</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="Housing_CheckGroup" id="Dog_Parks"
                                                    onChange={() => handleSubserviceChange('Dog Parks', 'check')} />
                                                <label className="form-check-label" htmlFor="Dog_Parks">Dog Parks</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="Housing_CheckGroup" id="Secured_Garage"
                                                    onChange={() => handleSubserviceChange('Secured Garage', 'check')} />
                                                <label className="form-check-label" htmlFor="Secured_Garage">Secured Garage</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="Housing_CheckGroup" id="APSCCPS"
                                                    onChange={() => handleSubserviceChange('Assigned Parking Spaces, Carport, Covered Parking Spaces', 'check')} />
                                                <label className="form-check-label" htmlFor="APSCCPS">Assigned Parking Spaces, Carport, Covered Parking Spaces</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="Housing_CheckGroup" id="ATP_Transport"
                                                    onChange={() => handleSubserviceChange('Access to Public Transportation', 'check')} />
                                                <label className="form-check-label" htmlFor="ATP_Transport">Access to Public Transportation</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="Housing_CheckGroup" id="EVC_Station"
                                                    onChange={() => handleSubserviceChange('Electric Vehicle Charging Stations', 'check')} />
                                                <label className="form-check-label" htmlFor="EVC_Station">Electric Vehicle Charging Stations</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="Housing_CheckGroup" id="Guest_Parking"
                                                    onChange={() => handleSubserviceChange('Guest Parking', 'check')} />
                                                <label className="form-check-label" htmlFor="Guest_Parking">Guest Parking</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="Housing_CheckGroup" id="Bike_Storage"
                                                    onChange={() => handleSubserviceChange('Bike Storage', 'check')} />
                                                <label className="form-check-label" htmlFor="Bike_Storage">Bike Storage</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="Housing_CheckGroup" id="BR_Centers"
                                                    onChange={() => handleSubserviceChange('Bike Repair Centers', 'check')} />
                                                <label className="form-check-label" htmlFor="BR_Centers">Bike Repair Centers</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="Housing_CheckGroup" id="Laundry_Facilities"
                                                    onChange={() => handleSubserviceChange('Laundry Facilities', 'check')} />
                                                <label className="form-check-label" htmlFor="Laundry_Facilities">Laundry Facilities</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="Housing_CheckGroup" id="Laundry_Services"
                                                    onChange={() => handleSubserviceChange('Laundry Services', 'check')} />
                                                <label className="form-check-label" htmlFor="Laundry_Services">Laundry Services</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="Housing_CheckGroup" id="Package_Lockers"
                                                    onChange={() => handleSubserviceChange('Package Lockers', 'check')} />
                                                <label className="form-check-label" htmlFor="Package_Lockers">Package Lockers</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="Housing_CheckGroup" id="Gated_Community"
                                                    onChange={() => handleSubserviceChange('Gated Community', 'check')} />
                                                <label className="form-check-label" htmlFor="Gated_Community">Gated Community</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="Housing_CheckGroup" id="SGD"
                                                    onChange={() => handleSubserviceChange('Security Guards/Doormen', 'check')} />
                                                <label className="form-check-label" htmlFor="SGD">Security Guards/Doormen</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="Housing_CheckGroup" id="C_E_C"
                                                    onChange={() => handleSubserviceChange('Community Events/Classes', 'check')} />
                                                <label className="form-check-label" htmlFor="C_E_C">Community Events/Classes</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="Housing_CheckGroup" id="ES_Space"
                                                    onChange={() => handleSubserviceChange('Extra Storage Space', 'check')} />
                                                <label className="form-check-label" htmlFor="ES_Space">Extra Storage Space</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="Housing_CheckGroup" id="OR_Payments"
                                                    onChange={() => handleSubserviceChange('Online Rent Payments', 'check')} />
                                                <label className="form-check-label" htmlFor="OR_Payments">Online Rent Payments</label>
                                            </div>
                                            <div className="form-check pe-2">
                                                <input className="form-check-input" type="checkbox" name="Housing_CheckGroup" id="OM_Requests"
                                                    onChange={() => handleSubserviceChange('Online Maintenance Requests', 'check')} />
                                                <label className="form-check-label" htmlFor="OM_Requests">Online Maintenance Requests</label>
                                            </div>
                                        </div>
                                    </div> : ''
                                }
                            </div>
                        </div>

                        <div className="mb-4">
                            <label htmlFor="otherInfo" className="form-label fs-6">Other information</label>
                            <textarea className="form-control" id="otherInfo" rows="4" placeholder='Write your description here...' required
                                onChange={(e) => setOtherInfo(e.target.value)}></textarea>
                        </div>

                        <div className="btn-wrapper">
                            <button type="submit" className="w-100 btn btn-primary" onClick={() => handleSubmit()}>Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}


const mapPropsToState = (state) => {
    return {
        loading: state.business.loading,
        error: state.business.error,
        success: state.business.success,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        clickPerCount: (data, toast, navigate) => dispatch(actions.clickPerCount(data, toast, navigate)),
    }
}

export default connect(
    mapPropsToState,
    mapDispatchToProps
)(withSnackbar(BookAppointment))
