import React, { useState } from 'react'
import { useEffect, useRef } from 'react';
import { Helmet } from "react-helmet";
import { connect } from 'react-redux'
import { withSnackbar } from 'react-simple-snackbar'
import * as actions from '../store/actions/index';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactLoading from 'react-loading';
import DatePicker from "react-datepicker";
import { useNavigate } from 'react-router-dom';
const Services = ['Event Photography', 'Portrait photography', 'Videography', 'Aerial videography', 'Other'];
const Events = ['Ceremony', 'Class reunion', 'Fundraiser', 'Party', 'Product launch', 'Prom or school dance', 'Wedding', 'Other'];
const PortraitPhoto = ['Boudoir', 'Couple', 'Engagement', 'Family portraits', 'Headshots', 'Maternity', 'Newborn', 'Baby', 'Pin ups', 'Senior portraits', "Other"];
const Place = ['In home', 'Outdoors', 'Studio', 'Not sure', 'Other'];
const PreferTime = ['Morning', 'Evening', 'Afternoon', 'Other'];
const Session = ['30 minutes', '45 minutes', '60 minutes', 'Other'];
const GuestList = ['Less than 25 guests', '26-50 guests', '51 -75 guest', '76 - 100 guest', 'Not sure', 'Other'];
const BudgetList = ['$10 - $500', '$501 - $1000', '$1001 - $2000', 'Not sure', 'Other']
const ContactWay = ['Phone Call', 'Video call', 'Text', 'Message', 'Email', 'Chat', 'Other'];
export const Photography = (props) => {
    let customerInfo = JSON.parse(localStorage.getItem('userInfo'));
    let navigate = useNavigate();
    const [service, setService] = useState('')
    const [otherService, setOtherService] = useState(false);
    const [event, setEvent] = useState('');
    const [otherEvent, setOtherEvent] = useState(false);
    const [portaitPhotography, setPortaitPhotography] = useState('');
    const [otherPortaitPhotography, setOtherPortaitPhotography] = useState(false);
    const [photographyPlace, setPhotographyPlace] = useState('');
    const [otherPhotographyPlace, setOtherPhotographyPlace] = useState(false);
    const [preferTime, setPreferTime] = useState('');
    const [otherPreferTime, setOtherPreferTime] = useState(false);
    const [sessionLast, setSessionLast] = useState('');
    const [otherSessionLast, setOtherSessionLast] = useState(false);
    const [guest, setGuest] = useState('');
    const [otherGuest, setOtherGuest] = useState(false);
    const [budget, setBudget] = useState('');
    const [otherBudget, setOtherBudget] = useState(false);
    const [appointDate, setAppointDate] = useState(new Date());
    const [startingTime, setStartingTime] = useState('');
    const [closingTime, setClosingTime] = useState('');
    const [contactMode, setContactMode] = useState('');
    const [otherContactMode, setOtherContactMode] = useState(false);
    const [otherInfo, setOtherInfo] = useState('');

    const handleService = (value) => {
        if (value === 'Other') {
            setOtherService(true)
            setService('')
        } else {
            setOtherService(false)
            setService(value)
        }
    }
    const handleEvents = (value) => {
        if (value === 'Other') {
            setOtherEvent(true)
            setEvent('')
        } else {
            setOtherEvent(false)
            setEvent(value)
        }
    }
    const handlePortaitPhotography = (value) => {
        if (value === 'Other') {
            setOtherPortaitPhotography(true)
            setPortaitPhotography('')
        } else {
            setOtherPortaitPhotography(false)
            setPortaitPhotography(value)
        }
    }
    const handlePlace = (value) => {
        if (value === 'Other') {
            setOtherPhotographyPlace(true)
            setPhotographyPlace('')
        } else {
            setOtherPhotographyPlace(false)
            setPhotographyPlace(value)
        }
    }
    const handlePreferTime = (value) => {
        if (value === 'Other') {
            setOtherPreferTime(true)
            setPreferTime('')
        } else {
            setOtherPreferTime(false)
            setPreferTime(value)
        }
    }
    const handleSessionLast = (value) => {
        if (value === 'Other') {
            setOtherSessionLast(true)
            setSessionLast('')
        } else {
            setOtherSessionLast(false)
            setSessionLast(value)
        }
    }
    const handleGuest = (value) => {
        if (value === 'Other') {
            setOtherGuest(true)
            setGuest('')
        } else {
            setOtherGuest(false)
            setGuest(value)
        }
    }
    const handleBudget = (value) => {
        if (value === 'Other') {
            setOtherBudget(true)
            setBudget('')
        } else {
            setOtherBudget(false)
            setBudget(value)
        }
    }
    const handleContactMode = (value) => {
        if (value === 'Other') {
            setOtherContactMode(true)
            setContactMode('')
        } else {
            setOtherContactMode(false)
            setContactMode(value)
        }
    }
    const handleTimeChange = (event, type) => {
        var timeSplit = event.target.value.split(':'),
            hours,
            minutes,
            hours = timeSplit[0];
        minutes = timeSplit[1];
        // Check whether AM or PM
        let newformat = hours >= 12 ? 'PM' : 'AM';
        // Find current hour in AM-PM Format
        hours = hours % 12;

        // To display "0" as "12"
        hours = hours ? hours : 12;
        // minutes = minutes < 10 ? '0' + minutes : minutes;
        let selectedTime = hours + ':' + minutes + ' ' + newformat
        if (type === 'start') {
            setStartingTime(selectedTime)
        } else {
            setClosingTime(selectedTime)
        }
    }

    //  submit
    const handleSubmit = (e) => {
        e.preventDefault()
        if (service && budget && appointDate && contactMode) {
            let UsDate = new Date(appointDate).toLocaleDateString("en-US")
            let quoteDetails = {
                "Requested Service": "Photography (Videography) Service",
                "What type of service do you want?": service,
            }

            let mobileNo = customerInfo.country_code + customerInfo.mobile_no
            let customerName = customerInfo.name;
            let customerEmail = customerInfo.email;
            let data = {
                mobileNo: mobileNo,
                customerName: customerName,
                customerEmail: customerEmail,
                businessName: props.businessDetails.businessName,
                businessEmail: props.businessDetails.email,
                businessId: props.businessDetails.businessId,
                clickType: 'requestQuote',
                quoteDetails: quoteDetails
            }


            if (service === 'Portrait photography') {
                if (portaitPhotography && photographyPlace && preferTime && sessionLast) {
                    quoteDetails['What type of portrait photography do you need?'] = portaitPhotography
                    quoteDetails['Where will this occurs'] = photographyPlace
                    quoteDetails['What time do you prefer?'] = preferTime
                    quoteDetails['How long do you want the session to last?'] = sessionLast;
                    quoteDetails["What is your budget for this project?"] = budget
                    quoteDetails['What is the best way to contact you?'] = contactMode
                    quoteDetails["When do you need the service?"] = UsDate
                    if (otherInfo) {
                        quoteDetails["Any other details"] = otherInfo
                    }
                    props.clickPerCount(data, toast, navigate)
                } else {
                    toast.warning('Please Select All required field!', {
                        autoClose: 700,
                        hideProgressBar: true,
                    })
                }
            }
            if (service !== 'Portrait photography') {
                if (guest && startingTime && closingTime) {
                    quoteDetails['What type of event is this?'] = event
                    quoteDetails["Numbers of guest attending"] = guest
                    quoteDetails["What is your budget for this project?"] = budget
                    quoteDetails['What is the best way to contact you?'] = contactMode
                    quoteDetails["When do you need the service?"] = UsDate
                    quoteDetails['What is the starting time for the occasion?'] = startingTime
                    quoteDetails['What is the closing time for the occasion?'] = closingTime
                    if (otherInfo) {
                        quoteDetails["Any other details"] = otherInfo
                    }
                    props.clickPerCount(data, toast, navigate)
                } else {
                    toast.warning('Please Select All required field!', {
                        autoClose: 700,
                        hideProgressBar: true,
                    })
                }
            }


        } else {
            toast.warning('Please Select All required field!', {
                autoClose: 700,
                hideProgressBar: true,
            })
        }
    }



    return (
        <div id="photography" className='photography-sec card shadow-sm py-4 py-md-5 px-3 px-md-5 mt-4'>
            <Helmet>
                <title>Request Quote For Photography service</title>
            </Helmet>
            <ToastContainer />
            <div className="section-label fs-3 mb-4">Photography (Videography) Service</div>
            <div className="mb-3">
                <label className="form-label fs-6 mb-2">What type of service do you want?</label>
                <div className="d-flex flex-wrap">
                    {
                        Services.map((service) => (
                            <div className="form-check form-check-inline" key={service}>
                                <input className="form-check-input" type="radio" name="radioGroupForPhotoServices" id={service} required
                                    onClick={() => handleService(service)} />
                                <label className="form-check-label" htmlFor={service}>{service}</label>
                            </div>
                        ))
                    }
                </div>
                {
                    otherService ?
                        <input type="text" className="form-control mt-2" id="otherPhotography" placeholder='Other type of service...' required
                            onChange={(e) => setService(e.target.value)} />
                        : ''
                }
            </div>
            {
                service !== 'Portrait photography' ?
                    <div className="mb-3">
                        <label className="form-label fs-6 mb-2">What type of event is this?</label>
                        <div className="d-flex flex-wrap">
                            {
                                Events.map((event) => (
                                    <div className="form-check form-check-inline" key={event}>
                                        <input className="form-check-input" type="radio" name="radioGroupForEvent" id={event} required
                                            onClick={() => handleEvents(event)} />
                                        <label className="form-check-label" htmlFor={event}>{event}</label>
                                    </div>
                                ))
                            }
                        </div>
                        {
                            otherEvent ?
                                <input type="text" className="form-control mt-2" id="otherPhotography" placeholder='Other type of event...' required
                                    onChange={(e) => setEvent(e.target.value)} />
                                : ''
                        }
                    </div>
                    : ''
            }
            {
                service === 'Portrait photography' ?
                    <div>
                        <div className="mb-3">
                            <label className="form-label fs-6 mb-2">What type of portrait photography do you need?</label>
                            <div className="d-flex flex-wrap">
                                {PortraitPhoto.map((portrait) => (
                                    <div className="form-check form-check-inline" key={portrait}>
                                        <input className="form-check-input" type="radio" name="radioGroupPortrait" id={portrait} required
                                            onClick={() => handlePortaitPhotography(portrait)} />
                                        <label className="form-check-label" htmlFor={portrait}>{portrait}</label>
                                    </div>
                                ))}
                            </div>
                            {
                                otherPortaitPhotography ?
                                    <input type="text" id="otherPortraitPhotograpghy" className="form-control mt-2" placeholder='Enter other portarait photography...' required
                                        onChange={(e) => setPortaitPhotography(e.target.value)}
                                    />
                                    : ''
                            }
                        </div>
                        <div className="mb-3">
                            <label className="form-label fs-6 mb-2">Where will this occurs?</label>
                            <div className="d-flex flex-wrap">
                                {
                                    Place.map((plc) => (
                                        <div className="form-check form-check-inline" key={plc}>
                                            <input className="form-check-input" type="radio" name="radioGroupForPlace" id={plc} required
                                                onClick={() => handlePlace(plc)} />
                                            <label className="form-check-label" htmlFor={plc}>{plc}</label>
                                        </div>
                                    ))
                                }

                                {
                                    otherPhotographyPlace ?
                                        <input type="text" id="other" className="form-control mt-2" placeholder='Other...' required
                                            onChange={(e) => setPhotographyPlace(e.target.value)} />
                                        : ''
                                }
                            </div>
                        </div>
                        <div className="mb-3">
                            <label className="form-label fs-6 mb-2">What time do you prefer?</label>
                            <div className="d-flex flex-wrap">
                                {
                                    PreferTime.map((time) => (
                                        <div className="form-check form-check-inline" key={time}>
                                            <input className="form-check-input" type="radio" name="radioGroupForPreferTime" id={time} required
                                                onClick={() => handlePreferTime(time)} />
                                            <label className="form-check-label" htmlFor={time}>{time}</label>
                                        </div>
                                    ))
                                }
                                {
                                    otherPreferTime ?
                                        <input type="text" id="otherPreferTime" className="form-control mt-2" placeholder='Other...' required
                                            onChange={(e) => setPreferTime(e.target.value)} />
                                        : ''
                                }
                            </div>
                        </div>
                        <div className="mb-3">
                            <label className="form-label fs-6 mb-2">How long do you want the session to last?</label>
                            <div className="d-flex flex-wrap">
                                {
                                    Session.map((ses) => (
                                        <div className="form-check form-check-inline" key={ses}>
                                            <input className="form-check-input" type="radio" name="radioGroupForSession" id={ses} required
                                                onClick={() => handleSessionLast(ses)} />
                                            <label className="form-check-label" htmlFor={ses}>{ses}</label>
                                        </div>
                                    ))
                                }
                                {
                                    otherSessionLast ?
                                        <input type="text" id="otherSession" className="form-control mt-2" placeholder='Other...' required
                                            onChange={(e) => setPreferTime(e.target.value)} />
                                        : ''
                                }
                            </div>
                        </div>
                    </div>
                    : ''
            }
            {
                service !== 'Portrait photography' ?
                    <div className="mb-3">
                        <label className="form-label fs-6 mb-2">How many guests will be available at the occasion?</label>
                        <div className="d-flex flex-wrap">
                            {
                                GuestList.map((guest) => (
                                    <div className="form-check form-check-inline" key={guest}>
                                        <input className="form-check-input" type="radio" name="radioGroupForGuest" id={guest} required
                                            onClick={() => handleGuest(guest)} />
                                        <label className="form-check-label" htmlFor={guest}>{guest}</label>
                                    </div>
                                ))
                            }
                            {
                                otherGuest ?
                                    <input type="text" id="otherGuest" className="form-control mt-2" placeholder='Other...' required
                                        onChange={(e) => setGuest(e.target.value)} />
                                    : ''
                            }
                        </div>
                    </div>
                    : ''
            }
            <div className="mb-3">
                <label className="form-label fs-6 mb-2">What is your budget for this service?</label>
                <div className="d-flex flex-wrap">
                    {
                        BudgetList.map((budget) => (
                            <div className="form-check form-check-inline" key={budget}>
                                <input className="form-check-input" type="radio" name="radioGroupForBudget" id={budget} required
                                    onClick={() => handleBudget(budget)} />
                                <label className="form-check-label" htmlFor={budget}>{budget}</label>
                            </div>
                        ))
                    }
                    {
                        otherBudget ?
                            <input type="text" id="otherBudget" className="form-control mt-2" placeholder='Other...' required
                                onChange={(e) => setBudget(e.target.value)} />
                            : ''
                    }
                </div>
            </div>

            <div className="mb-3">
                <label htmlFor="chooseContactmode" className="form-label fs-6 mb-2">What is the best way to contact you?</label>
                <div className="d-flex flex-wrap g-2">
                    {
                        ContactWay.map((cntct) => (
                            <div className="form-check me-3" key={cntct}>
                                <input className="form-check-input" type="radio" name="radioGroupForcontact" id={cntct}
                                    onClick={() => handleContactMode(cntct)} />
                                <label className="form-check-label" htmlFor={cntct}>{cntct}</label>
                            </div>
                        ))
                    }
                </div>
                {
                    otherContactMode ?
                        <input type="text" className="form-control mt-2" id="otherContactMode" placeholder='Other contact...' required
                            onChange={(e) => setContactMode(e.target.value)} />
                        : ''
                }
            </div>
            <div className="mb-3">
                <label htmlFor="functionDate" className="form-label mb-2">When do you need the service?</label>
                <div className='date-picker-wrapper'>
                    <DatePicker showIcon className="form-control w-100" id="appointmentDate" selected={appointDate}
                        onChange={(value) => setAppointDate(value)} minDate={new Date()}
                    />
                </div>
            </div>
            {
                service !== 'Portrait photography' ?
                    <div className='row'>
                        <div className="col-6 mb-3">
                            <label htmlFor="startingTime" className="form-label fs-6 mb-2">What is the starting time for the occasion?</label>
                            <input type="time" id="startingTime" className="form-control" min="09:00" max="18:00" required
                                onChange={(e) => handleTimeChange(e, 'start')} />
                        </div>
                        <div className="col-6 mb-3">
                            <label htmlFor="closingTime" className="form-label fs-6 mb-2">What is the closing time for the occasion?</label>
                            <input type="time" id="closingTime" className="form-control" min="09:00" max="18:00" required
                                onChange={(e) => handleTimeChange(e, 'close')} />
                        </div>
                    </div> : ''
            }
            <div className="mb-4">
                <label htmlFor="otherInfo" className="form-label mb-2">Any other information</label>
                <textarea className="form-control" id="otherInfo" rows="4" placeholder='Write your other info...' required
                    onChange={(e) => setOtherInfo(e.target.value)}></textarea>
            </div>
            <div className="btn-wrapper mt-2">
                <button type="submit" className="w-100 btn btn-primary" onClick={(e) => handleSubmit(e)}>Submit</button>
            </div>
            {
                props.loading ?
                    <div className='position-fixed top-0 start-0 bottom-0 end-0 d-flex align-items-center justify-content-center w-100 h-100 bg-black bg-opacity-50'
                        style={{ zIndex: 1030 }}>
                        <ReactLoading type={'spin'} color={'#fff'} height={64} width={64} />
                    </div>
                    : ''
            }
        </div>
    )
}


const mapPropsToState = (state) => {
    return {
        loading: state.business.loading,
        error: state.business.error,
        success: state.business.success,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        clickPerCount: (data, toast, navigate) => dispatch(actions.clickPerCount(data, toast, navigate)),
    }
}

export default connect(
    mapPropsToState,
    mapDispatchToProps
)(withSnackbar(Photography))
