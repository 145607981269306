import React, { useState, useEffect } from 'react';
import searchIcon from '../assets/search.svg';
import { connect } from 'react-redux'
import { withSnackbar } from 'react-simple-snackbar'
import * as actions from '../store/actions/index';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Autocomplete from 'react-google-autocomplete';
import Geocode from "react-geocode";
import { useNavigate } from 'react-router-dom';
Geocode.setApiKey("AIzaSyB_KZHG7uYGsaMGdQLDRrV3Qw6xRvLqg_Q");
Geocode.enableDebug();

const SearchAutocomplete = (props) => {
    const navigate = useNavigate()
    // Start: Search Autocomplete Code
    const { getsearchSuggestion, searchSuggestion, loading, getsearchBusinessList } = props;
    const [searchTerm, setSearchTerm] = useState({
        _id: '',
        name: '',
        type: ''
    });
    const [searchingLatitide, setSearchingLatitude] = useState('40.1164204');
    const [searchingLongitude, setSearchingLongitude] = useState('-88.2433829');
    const [suggestions, setSuggestions] = useState([]);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [searchedCity, setSearchedCity] = useState('Champaign');
    const [searchedState, setSearchedState] = useState('IL');
    const [searchedAddress, setSearchedAddress] = useState('Champaign, IL, USA');
    var businessSuggestion = [...searchSuggestion]

    useEffect(() => {
        document.addEventListener('click', handleDocClick);
        return () => {
            document.removeEventListener('click', handleDocClick);
        };
        // searchSuggestion=[]
    }, []);

    const handleInputChange = async event => {
        setSearchTerm({ ...searchTerm, name: event.target.value, _id: '', type: 'genral' });
        let data = {
            searchingKeyword: event.target.value
        }
        await getsearchSuggestion(data)
        setShowSuggestions(true);
    };

    const handleSelectSuggestion = suggestion => {
        setSearchTerm(suggestion);
        setShowSuggestions(false); // Hide suggestions after selection
    };

    // const handleLocationSearch = () => {
    // }
    const getCity = (addressArray) => {
        let city = '';
        for (let i = 0; i < addressArray.length; i++) {
            if (addressArray[i].types[0] && 'locality' === addressArray[i].types[0]) {
                city = addressArray[i].long_name;
                return city;
            }
        }
    };
    const getState = (addressArray) => {
        let state = '';
        for (let i = 0; i < addressArray.length; i++) {
            for (let i = 0; i < addressArray.length; i++) {
                if (addressArray[i].types[0] && 'administrative_area_level_1' === addressArray[i].types[0]) {
                    state = addressArray[i].long_name;
                    return state;
                }
            }
        }
    };
    const handleLocationSelection = (place) => {
        const lat = place.geometry.location.lat();
        const lng = place.geometry.location.lng();
        setSearchingLatitude(lat);
        setSearchingLongitude(lng);
        fetch('https://maps.googleapis.com/maps/api/geocode/json?latlng=' + lat + ',' + lng + '&key=AIzaSyB_KZHG7uYGsaMGdQLDRrV3Qw6xRvLqg_Q', {
            method: 'GET'
        }).then((response) => response.json())
            .then((data) => {
                if (data.results[1]) {
                    const address = data.results[0].formatted_address,
                        addressArray = data.results[0].address_components,
                        city = getCity(addressArray),
                        state = getState(addressArray);
                    setSearchedCity(city);
                    setSearchedState(state);
                    let adrstr = `${city},${state}`
                    setSearchedAddress(adrstr)
                }
            })
            .catch((error) => {
                toast.error(error, {
                    autoClose: 700,
                    hideProgressBar: true,
                })
            })
    };

    const handleInputClick = () => {
        setShowSuggestions(true);
        document.getElementById("LocSearch").classList.remove('d-none');
        document.getElementById("LocSearch").classList.add('d-flex');
    };
    const handleSearchInputClick = () => {
        setShowSuggestions(true);
        document.getElementById("LocSearch").classList.remove('d-none');
        document.getElementById("LocSearch").classList.add('d-flex');
    };

    const handleDocClick = event => {
        if (!event.target.closest('.autocomplete-input')) {
            setShowSuggestions(false);
        }
    };


    const handleSubmit = async () => {
        if (Object.keys(searchTerm).length > 0 && searchingLatitide && searchingLongitude) {
            let data = {
                "searchKeyword": searchTerm.name,
                "latitude": searchingLatitide,
                "longitude": searchingLongitude,
                "type": searchTerm.type,
                "searchedCity": searchedCity,
                "searchedState": searchedState,
                "goingThrough" :'page'
            }
            if (searchTerm.type === 'subcategory') {
                data.subCategoryId = searchTerm._id
            }
            // console.log(data)
            await getsearchBusinessList(data, navigate)
            setSearchTerm({
                _id: '',
                name: '',
                type: ''
            })
            // setSearchingLatitude('')
            // setSearchingLongitude('')

        } else {
            toast.warning('Please select location and business or subcategory', {
                autoClose: 700,
                hideProgressBar: true,
            })
        }
    }
    // End: Search Autocomplete Code
    return (
        <div className="search-wrapper bg-white py-2">
            <div className="container">
                <div className="autocomplete-search d-flex flex-column flex-md-row gap-2 gap-md-0 rounded w-100">
                    <div className="autocomplete-input for-category position-relative flex-fill">
                        <input type="search" id="autocomplete" className="form-control shadow-none py-2" placeholder="Search business by name, handyman, auto repair, restaurant, lawyer, beauty salon, catering etc" value={searchTerm.name} autoComplete='off' onClick={handleInputClick} onChange={handleInputChange} />
                        {showSuggestions && businessSuggestion.length > 0 && (
                            <ul className="suggestion-list list-inline w-100 bg-white position-absolute start-0 border rounded-bottom overflow-auto z-1 mb-0" style={{ maxHeight: '300px' }}>
                                {businessSuggestion.map((suggestion, index) => (
                                    // console.log(suggestion,'hoooo')
                                    suggestion.type === 'subcategory' ?
                                        <li className='pointer fw-semibold px-3 py-2' key={index} onClick={() => handleSelectSuggestion(suggestion)}>
                                            {suggestion.name}
                                        </li>
                                        :
                                        <li className='pointer px-3 py-2' key={index} onClick={() => handleSelectSuggestion(suggestion)}>
                                            {suggestion.name}
                                        </li>
                                ))}
                            </ul>
                        )}
                    </div>

                    <div id="LocSearch" className="d-none d-md-flex flex-column flex-md-row gap-2 gap-md-0 flex-fill">
                        <div className="autocomplete-input for-location position-relative flex-fill">
                            {/* <input type="search" id="autocomplete" className="form-control shadow-none py-2" placeholder="Search your address" value={searchTerm} onClick={handleSearchInputClick} onChange={handleInputChange} /> */}
                            <Autocomplete
                                className="form-control shadow-none py-2"
                                placeholder='Search City,State...'
                                value={searchedAddress}
                                onChange={(e) => setSearchedAddress(e.target.value)}
                                onPlaceSelected={(e) => handleLocationSelection(e)}
                                options={{
                                    fields: ["formatted_address", "geometry", "name"],
                                    strictBounds: false,
                                    types: [],
                                }}
                            />
                        </div>

                        <button type='submit' className="d-flex align-items-center justify-content-center bg-primary border border-primary rounded-end shadow-none px-3 lh-sm" style={{ height: '45px' }}
                            onClick={() => handleSubmit()}>
                            {loading ?
                                <div className="spinner-border text-light spinner-border-sm" role="status" style={{ width: '22px', height: '22px' }}>
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                                :
                                <div>
                                    <img src={searchIcon} alt='Search Icon' className='' style={{ width: '22px', height: '22px' }} />
                                    <span className='d-md-none text-white fw-medium ms-2'>Search...</span>
                                </div>
                            }
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}


const mapPropsToState = (state) => {
    return {
        loading: state.searchedBusiness.loading,
        error: state.searchedBusiness.error,
        success: state.searchedBusiness.success,
        searchSuggestion: state.searchedBusiness.searchSuggestion,
        searchedBusinessList: state.searchedBusiness.searchedBusinessList,
        couponDetails: state.rewardAndCoupon.couponDetails
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getsearchSuggestion: (data) => dispatch(actions.getsearchSuggestion(data)),
        getsearchBusinessList: (data, navigate) => dispatch(actions.getsearchBusinessList(data, navigate)),
        makeRating: (data) => dispatch(actions.makeRating(data)),
        createAndGetCoupon: (data) => dispatch(actions.createAndGetCoupon(data))
    }
}

export default connect(
    mapPropsToState,
    mapDispatchToProps
)(withSnackbar(SearchAutocomplete))