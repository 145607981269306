import React, {useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { connect } from 'react-redux'
import { withSnackbar } from 'react-simple-snackbar'
import * as actions from '../store/actions/index'
import Map from '../Map';

const BusinessSignUp = (props) => {
    const [email, setEmail] = useState('')
    const [name, settName] = useState('')
    const [business_owner_name, setBusinessOwnerName] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [mobile_no, setMobile_no] = useState('')
    const [country_code, setCountry_code] = useState('');
    const [country, setCountry] = useState('');
    const [city, setCity] = useState('');
    const [businessState, setBusinessState] = useState('');
    const [longitude, setLongitude] = useState(23.53);
    const [latitude, setLatitude] = useState(53.65);
    const [address, setAddress] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState(true);
    useEffect(() => {
        document.documentElement.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant", // Optional if you want to skip the scrolling animation
        });
    }, [])


    const handleChange = (type, e) => {
        if (type === 'email') {
            setEmail(e.target.value)
        } else if (type === 'password') {
            setPassword(e.target.value)
        } else if (type === 'confirmPassword') {
            setConfirmPassword(e.target.value)
        }else if (type === 'name') {
            settName(e.target.value)
        } else if (type === 'businessOwnerName') {
            setBusinessOwnerName(e.target.value)
        }else if (type === 'city') {
            setCity(e.target.value)
        }else if (type === 'country') {
            setCountry(e.target.value)
        }else if (type === 'businessState') {
            setBusinessState(e.target.value)
        }
    }
    const phoneChange = (phoneDetails) => {
        let countryCode = phoneDetails.split('-')[0]
        let mobileNo = phoneDetails.split('-')[1]
        setCountry_code(countryCode)
        setMobile_no(mobileNo)
    }

    const handlelatlong = (value) => {
        setLatitude(value.latitude)
        setLongitude(value.longitude)
    }
    const handleAddress = (value) => {
        setAddress(value)
    }
    const handlecityname = (e) => { }
    const handlepincode = (e) => { }


    const handleSubmit = (e) => {
        e.preventDefault()
        if (email && name && business_owner_name && country_code && mobile_no && password
            && businessState && city && country && address && latitude && longitude
        ) {
            if (password !== confirmPassword) {
                setPasswordConfirmation(false)
            } else {
                let data = {
                    email: email, password: password,
                    country_code: country_code, mobile_no: mobile_no,
                    user_type: 'business_user',
                    name: name,
                    business_owner_name: business_owner_name,
                    state: businessState,
                    city: city,
                    country: country,
                    address: address,
                    latitude: latitude,
                    longitude: longitude
                }
                props.register(data)
            }
        }

    }



    return (
        <div className='auth-page d-flex align-items-center py-5'>
            <Helmet>
                <title>CouponRanking | Business Registration</title>
            </Helmet>
            <div className='container'>
                <div className='border border-1 rounded-3 overflow-hidden shadow mx-auto' style={{ maxWidth: '800px' }}>
                    <div className='section-bg-overlay d-flex flex-column justify-content-center bg-primary bg-opacity-50 text-white h-100 px-4 py-3'>
                        <div className='cntnt-wrapper position-relative z-1'>
                            <Link to={'/'} className='d-block logo-title text-uppercase small fw-bold mb-3'>
                                <img className='img-fluid rounded-circle' src={require('../assets/logo.png')} alt='Logo' style={{ width: '100px' }} />
                            </Link>
                            <div className='heading-title fs-3 fw-bold lh-sm mb-3'>Start Your<br /> Journey With Us.</div>
                            <div className='heading-subtitle mb-3'>Make Your Money Go Further with the Latest Deals and Coupons</div>
                            <div className='img-wrapper text-center d-none'>
                                <img className='img-fluid rounded-circle' src={require('../assets/hero-banner1.webp')} alt='Logo' style={{ maxWidth: '300px' }} />
                            </div>
                        </div>
                    </div>
                    <div className="bg-white h-100 px-3 px-sm-5 py-5">
                        <form className='w-100'>
                            <h3 className='fs-5 fw-bold mb-4'>Business Sign Up</h3>
                            {/* Start: Alert message */}
                            {props.error ?
                                <div className="d-flex align-items-center border border-danger text-danger rounded-1 shadow-sm px-3 py-2 mb-3" role="alert">
                                    <i className="bi bi-exclamation-triangle-fill fs-18"></i>
                                    <div className='ms-2 lh-sm'>
                                        {props.error}
                                    </div>
                                </div> : ''
                            }
                            {/* End: Alert message */}
                            <div className='row gx-3'>
                                <div className='col-sm-6'>
                                    <div className="mb-3">
                                        <label htmlFor="bName" className="form-label">Business name</label>
                                        <input type="text" className="form-control" id="bName" placeholder='Enter business name' required
                                            value={name}
                                            onChange={(e) => handleChange('name', e)}
                                        />
                                    </div>
                                </div>
                                <div className='col-sm-6'>
                                    <div className="mb-3">
                                        <label htmlFor="BOName" className="form-label">Business owner name</label>
                                        <input type="text" className="form-control" id="BOName" placeholder='Business owner name' required
                                            value={business_owner_name}
                                            onChange={(e) => handleChange('businessOwnerName', e)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="email" className="form-label">Email</label>
                                <input type="email" className="form-control" id="email" placeholder='Enter email' required
                                    value={email}
                                    onChange={(e) => handleChange('email', e)}
                                    autoComplete='new-password'
                                />
                            </div>
                            <div className='input-cover mb-3'>
                                <label htmlFor="number" className="form-label">Mobile Number</label>
                                <PhoneInput
                                    // inputProps={{
                                    //     name: 'phone',
                                    //     required: true,
                                    // }}
                                    id='number'
                                    country={"us"}
                                    // enableSearch={true}
                                    countryCodeEditable={false}
                                    inputClass="w-100"
                                    required
                                    onChange={(phone, country) => {
                                        const reducedPhone = phone.replace(
                                            country.dialCode, '',);
                                        phoneChange(country.dialCode + '-' + reducedPhone,);
                                    }}
                                />
                            </div>
                            <div className='row gx-3'>
                                <div class="col-lg-5 col-md-12 mb-3">
                                    <label htmlFor="inputCity" class="form-label">City</label>
                                    <input type="text" class="form-control" id="inputCity" placeholder='Enter your city' required
                                        value={city}
                                        onChange={(e) => handleChange('city', e)}
                                    />
                                </div>
                                <div class="col-lg-4 col-md-7 mb-3">
                                    <label htmlFor="inputState" class="form-label">State</label>
                                    <input type="text" class="form-control" id="inputState" placeholder='Enter your State' required
                                        value={businessState}
                                        onChange={(e) => handleChange('businessState', e)}
                                    />
                                </div>
                                <div class="col-lg-3 col-md-5 mb-3">
                                    <label htmlFor="inputCountry" class="form-label">Country</label>
                                    <input type="text" class="form-control" id="inputCountry" placeholder='Enter country' required
                                        value={country}
                                        onChange={(e) => handleChange('country', e)}
                                    />
                                </div>
                            </div>
                            <div className='map-sec'>
                                <div class="mb-2">
                                    <label htmlFor="location" class="form-label">Address</label>
                                    <input type="text" class="form-control" id="location" placeholder="Apartment, studio, or floor" required
                                        value={address}
                                        onChange={(e) => handleChange('address', e)}
                                    />
                                </div>
                                <div class="google-map mb-3 pb-2">
                                    <Map
                                        center={{ lat: latitude, lng: longitude }}
                                        handlelatlong={(e) => handlelatlong(e)}
                                        handleAddress={(e) => handleAddress(e)}
                                        handlepincode={(e) => handlepincode(e)}
                                        handlecityname={(e) => handlecityname(e)}
                                        height='400px'
                                        value={address}
                                        // onChange={(e) => this.onChangeAddName('location', e.target.value)}
                                        zoom={15}
                                    />
                                </div>

                            </div>
                            <div className='row gx-3 mt-5'>
                                <div className='col-sm-6'>
                                    <div className="mb-3">
                                        <label htmlFor="password" className="form-label">Password</label>
                                        <input type="password" className="form-control" id="password" placeholder='Enter password' required
                                            value={password}
                                            onChange={(e) => handleChange('password', e)}
                                        />
                                    </div>
                                </div>
                                <div className='col-sm-6'>
                                    <div className="mb-3">
                                        <label htmlFor="c-password" className="form-label">Confirm Password</label>
                                        <input type="password" className="form-control" id="c-password" placeholder='Confirm password' required
                                            value={confirmPassword}
                                            onChange={(e) => handleChange('confirmPassword', e)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form-check mb-4 pb-2">
                                <input type="checkbox" className="form-check-input" id="exampleCheck1" required />
                                <label className="form-check-label" htmlFor="exampleCheck1">I agree to the <Link to={'#'} className='btn-link'>Terms & Condition</Link></label>
                            </div>
                            <div className="w-100 mb-3">
                                <button type="submit" className="w-100 btn btn-primary" onClick={(e) => handleSubmit(e)}>Register Now</button>
                            </div>
                            <div className='text-center'>Already have account?
                                <Link to={'/login'} className='ms-2 btn-link text-decoration-none'>Login</Link>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapPropsToState = (state) => {
    return {
        loading: state.auth.loading,
        error: state.auth.error,
        success: state.auth.success,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        register: (data) => dispatch(actions.register(data)),
    }
}

export default connect(
    mapPropsToState,
    mapDispatchToProps
)(withSnackbar(BusinessSignUp))
