import React from 'react'

export const OtherServices = () => {
    return (
        <div id="otherServices" className='other-services-sec card shadow-sm py-4 py-md-5 px-3 px-md-5 mt-4'>
            <div className="section-label fs-3 mb-4">Other Services</div>
            <div className="row gx-3">
                <div className="col-sm-6">
                    <div className="mb-3">
                        <label htmlFor="serviceName" className="form-label fs-6">Name your service</label>
                        <input type="text" className="form-control" id="serviceName" placeholder='Enter service' required />
                    </div>
                </div>
                <div className="col-sm-6">
                    <div className="mb-3">
                        <label htmlFor="issueLavel" className="form-label fs-6">Describe the issue at lavel</label>
                        <input type="text" id="issueLavel" className="form-control" placeholder='Enter vehicles' required />
                    </div>
                </div>
                <div className="col-sm-12">
                    <div className="mb-3">
                        <label className="form-label fs-6 me-3">When do you require this service?</label>
                        <div className="d-flex flex-wrap">
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" name="radioGroup" id="asap" required />
                                <label className="form-check-label" htmlFor="asap">As soon as possible</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" name="radioGroup" id="flexible" />
                                <label className="form-check-label" htmlFor="flexible">I am flexible</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" name="radioGroup" id="specificDate" />
                                <label className="form-check-label" htmlFor="specificDate">Specific date</label>
                            </div>
                        </div>
                        <input type="date" id="specificDate" className="form-control mt-3" required />
                    </div>
                </div>
                <div className="col-sm-12">
                    <div className="mb-4">
                        <label htmlFor="otherDetails" className="form-label fs-6 mb-2">Are there any other details you like to share?</label>
                        <textarea className="form-control" id="otherInfomation" rows="4" placeholder='Write your other information here...' required ></textarea>
                    </div>
                </div>
            </div>
            <div className="btn-wrapper">
                <button type="submit" className="w-100 btn btn-primary">Submit</button>
            </div>
        </div>
    )
}
