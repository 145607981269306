import React from 'react';
import catering from '../assets/icons/catering.svg';
import { Helmet } from "react-helmet";
import { connect } from 'react-redux'
import { withSnackbar } from 'react-simple-snackbar'
import * as actions from '../store/actions/index';
import { useEffect } from 'react';
import { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const minutes = [
    "00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20",
    "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31", "32", "33", "34", "35", "36", "37", "38", "39", "40",
    "41", "42", "43", "44", "45", "46", "47", "48", "49", "50", "51", "52", "53", "54", "55", "56", "57", "58", "59"
]
const hours = [
    "00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"
]

const AddBusinessDetails = (props) => {
    const { getCategoryList, getPlanDetailOfUser, success, error } = props
    useEffect(() => {
        getCategoryList()
        getPlanDetailOfUser()
        // for loading page from top after re-render from any section
        document.documentElement.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant", // Optional if you want to skip the scrolling animation
        });
        if (success) {
            toast.success(success, { autoClose: 1000 })
        } else if (error) {
            toast.error(error, { autoClose: 1000 })
        }

    }, [getCategoryList, getPlanDetailOfUser, success, error])

    const [categoryId, setCategory] = useState('')
    const [subCategoryList, setSubCategoryList] = useState([])
    const [subCategory, setSubCategory] = useState([])
    const [productDetails, setProductDetails] = useState([{
        categoryName: '',
        productList: [
            {
                name: '',
                price: 0,
                status: ''
            }
        ]
    }])
    const [business_logo, setBusinessLogo] = useState('');
    const [logoFile, setLogoFile] = useState('')
    const [website, setWebsite] = useState();
    const [perClickLimit, setPerClickLimit] = useState(0)
    const [amenities, setAmenities] = useState([])
    const [businessDescription, setBusinessDescription] = useState('')

    const [business_weekdays, setBusinessWeekDays] = useState({
        // monday details
        monday_open_time_hour: '01',
        monday_open_time_min: '00',
        monday_open_time_am_pm: 'AM',
        monday_close_time_hour: '01',
        monday_close_time_min: '00',
        monday_close_time_am_pm: 'AM',
        is_monday_Open: false,
        // tuesday details
        tuesday_open_time_hour: '01',
        tuesday_open_time_min: '00',
        tuesday_open_time_am_pm: 'AM',
        tuesday_close_time_hour: '01',
        tuesday_close_time_min: '00',
        tuesday_close_time_am_pm: 'AM',
        is_tuesday_Open: false,
        //wednesday details
        wednesday_open_time_hour: '01',
        wednesday_open_time_min: '00',
        wednesday_open_time_am_pm: 'AM',
        wednesday_close_time_hour: '01',
        wednesday_close_time_min: '00',
        wednesday_close_time_am_pm: 'AM',
        is_wednesday_Open: false,
        // thursday details
        thursday_open_time_hour: '01',
        thursday_open_time_min: '00',
        thursday_open_time_am_pm: 'AM',
        thursday_close_time_hour: '01',
        thursday_close_time_min: '00',
        thursday_close_time_am_pm: 'AM',
        is_thursday_Open: false,
        // friday details
        friday_open_time_hour: '01',
        friday_open_time_min: '00',
        friday_open_time_am_pm: 'AM',
        friday_close_time_hour: '01',
        friday_close_time_min: '00',
        friday_close_time_am_pm: 'AM',
        is_friday_Open: false,
        //saturday details
        saturday_open_time_hour: '01',
        saturday_open_time_min: '00',
        saturday_open_time_am_pm: 'AM',
        saturday_close_time_hour: '01',
        saturday_close_time_min: '00',
        saturday_close_time_am_pm: 'AM',
        is_saturday_Open: false,
        //sunday details
        sunday_open_time_hour: '01',
        sunday_open_time_min: '00',
        sunday_open_time_am_pm: 'AM',
        sunday_close_time_hour: '01',
        sunday_close_time_min: '00',
        sunday_close_time_am_pm: 'AM',
        is_sunday_Open: false,
    })

    const [couponTextDetail, setCouponDetailsText] = useState({
        percentageOff: '',
        minOrder: '',
        expiryDate: ''
    })
    const [dealTextDetail, setdealTextDetail] = useState({
        percentageOff: '',
        minOrder: '',
    })


    // handle hours of operation data
    const handleWeekdays = (key, value) => {
        setBusinessWeekDays(previousInputs => ({ ...previousInputs, [key]: value }))
    }

    // handle category selection and setting subcategory for that category
    const OnChangeValue = (Id) => {
        setCategory(Id)
        let filterSubCategory = categoryList.filter(item => item._id === Id)
        setSubCategoryList(filterSubCategory[0].subcategoryInfo)
    }
    // handle change of product category
    const handleCategoryChange = (e, i) => {
        let prodsD = [...productDetails]
        if (i !== undefined) {
            prodsD[i].categoryName = e.target.value;
        } else {
            prodsD[i].categoryName = e.target.value;
        }
        setProductDetails(prodsD)
    }
    // for handle change in name price and status
    const handleChange = ({ index, element, i }) => {
        element.preventDefault()
        let prodsD = [...productDetails]
        if (i !== undefined) {
            prodsD[i]["productList"][index][element.target.name] = element.target.value;
        } else {
            prodsD[i][element.target.name] = element.target.value;
        }
        setProductDetails(prodsD)
    }

    // handle click event of the Add button
    const handleAddClick = (index, i) => {
        let proD = [...productDetails]
        let prodsD = [...productDetails[i].productList, { name: '', price: 0, status: '' }]
        proD[i].productList = prodsD;
        proD[i].productList[index].name === '' ? toast.error('Please select product/service', { autoClose: 1000 }) : setProductDetails(proD);
    };

    // handle click event of the Remove button
    const handleRemoveClick = (index, i) => {
        let prodsD = [...productDetails]
        const list = [...prodsD[i].productList];
        list.splice(index, 1);
        prodsD[i].productList = list;
        setProductDetails(prodsD);
    };

    // handle category click event of the Add category button
    const handleCategoryAddClick = (i) => {
        let proD = [...productDetails]
        let prodsD = [...productDetails, {
            categoryName: '',
            productList: [
                {
                    name: '',
                    price: 0,
                    status: ''
                }
            ]
        }]
        proD = prodsD;
        proD[i].categoryName === '' ? toast.error('Please select category  name', { autoClose: 1000 }) : setProductDetails(proD);
    };

    // handle category click event of the remove category button
    const handleCategoryRemoveClick = (i) => {
        let prodsD = [...productDetails]
        prodsD.splice(i, 1);
        setProductDetails(prodsD);
    };

    // helper function for removing amenities from array after deselect
    function removeItems(arr) {
        var what, a = arguments, L = a.length, ax;
        while (L > 1 && arr.length) {
            what = a[--L];
            while ((ax = arr.indexOf(what)) !== -1) {
                arr.splice(ax, 1);
            }
        }
        return arr;
    }
    //handle Amenities
    const handleAmenities = (value) => {
        let amenitisList = [...amenities]
        if (amenitisList.includes(value)) {
            let updatedlist = removeItems(amenitisList, value)
            setAmenities(updatedlist)
        } else {
            amenitisList.push(value)
            setAmenities(amenitisList)
        }
    }
    // handle logo and logofile to show after choosing picture to show 
    const handleUploadFile = async (event) => {
        await setLogoFile(URL.createObjectURL(event.target.files[0]));
        await setBusinessLogo(event.target.files[0])
    }
    let { categoryList } = props;

    let dd = [{
        day: 'monday', status: business_weekdays.is_monday_Open,
        openHrs: business_weekdays.monday_open_time_hour, openMin: business_weekdays.monday_open_time_min, open_am_pm: business_weekdays.monday_open_time_am_pm,
        closeHrs: business_weekdays.monday_close_time_hour, closeMin: business_weekdays.monday_close_time_min, close_am_pm: business_weekdays.monday_close_time_am_pm
    },
    {
        day: 'tuesday', status: business_weekdays.is_tuesday_Open,
        openHrs: business_weekdays.tuesday_open_time_hour, openMin: business_weekdays.tuesday_open_time_min, open_am_pm: business_weekdays.tuesday_open_time_am_pm,
        closeHrs: business_weekdays.tuesday_close_time_hour, closeMin: business_weekdays.tuesday_close_time_min, close_am_pm: business_weekdays.tuesday_close_time_am_pm
    },
    {
        day: 'wednesday', status: business_weekdays.is_wednesday_Open,
        openHrs: business_weekdays.wednesday_open_time_hour, openMin: business_weekdays.wednesday_open_time_min, open_am_pm: business_weekdays.wednesday_open_time_am_pm,
        closeHrs: business_weekdays.wednesday_close_time_hour, closeMin: business_weekdays.wednesday_close_time_min, close_am_pm: business_weekdays.wednesday_close_time_am_pm
    },
    {
        day: 'thursday', status: business_weekdays.is_thursday_Open,
        openHrs: business_weekdays.thursday_open_time_hour, openMin: business_weekdays.thursday_open_time_min, open_am_pm: business_weekdays.thursday_open_time_am_pm,
        closeHrs: business_weekdays.thursday_close_time_hour, closeMin: business_weekdays.thursday_close_time_min, close_am_pm: business_weekdays.thursday_close_time_am_pm
    },
    {
        day: 'friday', status: business_weekdays.is_friday_Open,
        openHrs: business_weekdays.friday_open_time_hour, openMin: business_weekdays.friday_open_time_min, open_am_pm: business_weekdays.friday_open_time_am_pm,
        closeHrs: business_weekdays.friday_close_time_hour, closeMin: business_weekdays.friday_close_time_min, close_am_pm: business_weekdays.friday_close_time_am_pm
    },
    {
        day: 'saturday', status: business_weekdays.is_saturday_Open,
        openHrs: business_weekdays.saturday_open_time_hour, openMin: business_weekdays.saturday_open_time_min, open_am_pm: business_weekdays.saturday_open_time_am_pm,
        closeHrs: business_weekdays.saturday_close_time_hour, closeMin: business_weekdays.saturday_close_time_min, close_am_pm: business_weekdays.saturday_close_time_am_pm
    },
    {
        day: 'sunday', status: business_weekdays.is_sunday_Open,
        openHrs: business_weekdays.sunday_open_time_hour, openMin: business_weekdays.sunday_open_time_min, open_am_pm: business_weekdays.sunday_open_time_am_pm,
        closeHrs: business_weekdays.sunday_close_time_hour, closeMin: business_weekdays.sunday_close_time_min, close_am_pm: business_weekdays.sunday_close_time_am_pm
    }
    ]
    //handle subcategory select
    const handleSubcategory = (Id) => {
        let filterSubcategory = subCategoryList.filter(subValue => subValue._id === Id)
        const { _id: subCategoryId, name: subCategoryName } = filterSubcategory[0];
        setSubCategory([{ subCategoryId: subCategoryId, subCategoryName: subCategoryName }])
    }
    //submit form details
    const handleSubmit = (event) => {
        event.preventDefault()
        if (productDetails[0].categoryName !== '' || categoryId !== '' || subCategory.length !== 0 || perClickLimit !== 0 ||
            website !== '' || businessDescription !== '') {
            const data = new FormData();
            // data.append('businessDescription', description);
            data.append('productDetails', JSON.stringify(productDetails));
            data.append('amenities', JSON.stringify(amenities))
            data.append('categoryId', categoryId)
            data.append('subCategory', JSON.stringify(subCategory))
            data.append('business_logo', business_logo);
            data.append('website', website);
            data.append('business_weekdays', JSON.stringify({
                    monday: {
                        open_time_hour: business_weekdays.monday_open_time_hour,
                        open_time_min: business_weekdays.monday_open_time_min,
                        open_time_am_pm: business_weekdays.monday_open_time_am_pm,
                        close_time_hour: business_weekdays.monday_close_time_hour,
                        close_time_min: business_weekdays.monday_close_time_min,
                        close_time_am_pm: business_weekdays.monday_close_time_am_pm,
                    },
                    is_monday_Open: business_weekdays.is_monday_Open,
                    tuesday: {
                        open_time_hour: business_weekdays.tuesday_open_time_hour,
                        open_time_min: business_weekdays.tuesday_open_time_min,
                        open_time_am_pm: business_weekdays.tuesday_open_time_am_pm,
                        close_time_hour: business_weekdays.tuesday_close_time_hour,
                        close_time_min: business_weekdays.tuesday_close_time_min,
                        close_time_am_pm: business_weekdays.tuesday_close_time_am_pm,
                    },
                    is_tuesday_Open: business_weekdays.is_tuesday_Open,
                    wednesday: {
                        open_time_hour: business_weekdays.wednesday_open_time_hour,
                        open_time_min: business_weekdays.wednesday_open_time_min,
                        open_time_am_pm: business_weekdays.wednesday_open_time_am_pm,
                        close_time_hour: business_weekdays.wednesday_close_time_hour,
                        close_time_min: business_weekdays.wednesday_close_time_min,
                        close_time_am_pm: business_weekdays.wednesday_close_time_am_pm,
                    },
                    is_wednesday_Open: business_weekdays.is_wednesday_Open,
                    thursday: {
                        open_time_hour: business_weekdays.thursday_open_time_hour,
                        open_time_min: business_weekdays.thursday_open_time_min,
                        open_time_am_pm: business_weekdays.thursday_open_time_am_pm,
                        close_time_hour: business_weekdays.thursday_close_time_hour,
                        close_time_min: business_weekdays.thursday_close_time_min,
                        close_time_am_pm: business_weekdays.thursday_close_time_am_pm,
                    },
                    is_thursday_Open: business_weekdays.is_thursday_Open,
                    friday: {
                        open_time_hour: business_weekdays.friday_open_time_hour,
                        open_time_min: business_weekdays.friday_open_time_min,
                        open_time_am_pm: business_weekdays.friday_open_time_am_pm,
                        close_time_hour: business_weekdays.friday_close_time_hour,
                        close_time_min: business_weekdays.friday_close_time_min,
                        close_time_am_pm: business_weekdays.friday_close_time_am_pm,
                    },
                    is_friday_Open: business_weekdays.is_friday_Open,
    
                    saturday: {
                        open_time_hour: business_weekdays.saturday_open_time_hour,
                        open_time_min: business_weekdays.saturday_open_time_min,
                        open_time_am_pm: business_weekdays.saturday_open_time_am_pm,
                        close_time_hour: business_weekdays.saturday_close_time_hour,
                        close_time_min: business_weekdays.saturday_close_time_min,
                        close_time_am_pm: business_weekdays.saturday_close_time_am_pm,
                    },
                    is_saturday_Open: business_weekdays.is_saturday_Open,
                    sunday: {
                        open_time_hour: business_weekdays.sunday_open_time_hour,
                        open_time_min: business_weekdays.sunday_open_time_min,
                        open_time_am_pm: business_weekdays.sunday_open_time_am_pm,
                        close_time_hour: business_weekdays.sunday_close_time_hour,
                        close_time_min: business_weekdays.sunday_close_time_min,
                        close_time_am_pm: business_weekdays.sunday_close_time_am_pm,
                    },
                    is_sunday_Open: business_weekdays.is_sunday_Open,
                }
            ));
            data.append('perClickLimit', perClickLimit)
            data.append('businessDescription', businessDescription)
            if (couponTextDetail.percentageOff !== '') {
                data.append('couponText', `${couponTextDetail.percentageOff}% off, Click here for a promo code ($${couponTextDetail.minOrder} minimum booking),promo code expire on ${couponTextDetail.expiryDate}`)
            } else if (dealTextDetail.percentageOff !== '') {
                data.append('dealText', `${dealTextDetail.percentageOff}% off, Click here for deal ($${dealTextDetail.minOrder} minimum booking)`)
            }
            props.AddBusinessDetails(data)
        } else {
            toast.error('Please select all field', { autoClose: 1000 })
        }
    }
    
    return (
        <div className='business-details-page py-4 py-xl-5'>
            {/* title head */}
            <Helmet>
                <title>CouponRanking | Add Business Details</title>
            </Helmet>
            <ToastContainer />
            <div className='container'>
                <div className='section-label fs-1 mb-4'>Add Business Details</div>
                <form className='bg-light px-3 px-md-5 py-4 py-md-5'>
                    {/* Service section */}
                    <div className="srvc-provider-sec mb-4">
                        <h5 className="sec-title fs-5 mb-3">List of services provider</h5>
                        {
                            productDetails.map((e, i) => (
                                <div className="repeat-with-category" key={i}>
                                    <div className="mb-3">
                                        <label htmlFor="serviceName" className="form-label">Product/Service Category Name</label>
                                        <input type="text" className="form-control" id="serviceName" placeholder="Enter service or product category" name="categoryName" required
                                            value={e.categoryName}
                                            onChange={(e) => handleCategoryChange(e, i)}
                                        />
                                    </div>
                                    {e.productList.length > 0 && e.productList.map((element, index) => (
                                        <div className="row gx-2 gx-md-3" key={index}>
                                            <div className="col-lg-4 col-sm-6 mb-3">
                                                <label htmlFor="serviceName" className="form-label">Name</label>
                                                <input type="text" className="form-control" id="serviceName" placeholder="Enter service or product name" name="name" required
                                                    value={element.name}
                                                    onChange={element => handleChange({ index, element, i })}
                                                />
                                            </div>
                                            <div className="col-lg-3 col-sm-3 col-6 mb-3">
                                                <label htmlFor="servicePrice" className="form-label">Price ($)</label>
                                                <input type="number" min="0" className="form-control" id="servicePrice" placeholder="Enter price" name="price" required
                                                    value={element.price}
                                                    onChange={element => handleChange({ index, element, i })}
                                                />
                                            </div>
                                            <div className="col-lg-3 col-sm-3 col-6 mb-3">
                                                <label htmlFor="status" className="form-label">Status</label>
                                                <select id="status" className="form-select" name="status" type="select" required
                                                    value={element.status}
                                                    onChange={element => handleChange({ index, element, i })}
                                                >
                                                    <option value="" hidden>Choose here</option>
                                                    <option value="no_price">No Price</option>
                                                    <option value="show_price">Show Price</option>
                                                    <option value="per_hour">Per Hour</option>
                                                    <option value="per_day">Per Day</option>
                                                    <option value="none">None</option>
                                                    <option value="call_for_quotes">Call for Quotes</option>
                                                </select>
                                            </div>
                                            {
                                                e.productList.length - 1 === index ?
                                                    <div className="col-lg-2 mb-3">
                                                        <label htmlFor="servicePrice" className="form-label d-none d-lg-block">&nbsp;</label>
                                                        <div className="add-more">
                                                            <button type="button" className="btn btn-primary" onClick={() => handleAddClick(index, i)}><i className="bi bi-plus-lg"></i> Add</button>
                                                        </div>
                                                    </div> :
                                                    <div className="col-lg-2 mb-3">
                                                        <label htmlFor="servicePrice" className="form-label d-none d-lg-block">&nbsp;</label>
                                                        <div className="add-more">
                                                            <button type="button" className="btn btn-danger" onClick={(index) => handleRemoveClick(index, i)}><i className="bi bi-minus-lg"></i> Remove</button>
                                                        </div>
                                                    </div>
                                            }

                                        </div>
                                    ))}
                                    {
                                        productDetails.length - 1 === i ?
                                            <div className="btn-wrapper mt-2">
                                                <button className="btn btn-primary" onClick={() => handleCategoryAddClick(i)}><i className="bi bi-plus-lg"></i> Add with category</button>
                                            </div> :
                                            <div className="btn-wrapper mt-2">
                                                <button className="btn btn-danger" onClick={() => handleCategoryRemoveClick(i)}><i className="bi bi-minus-lg"></i> Remove with category</button>
                                            </div>
                                    }

                                </div>
                            ))
                        }
                    </div>

                    {/* Website and click section */}
                    <div className="row gx-3">
                        <div className="col-sm-6">
                            <div className="mb-3">
                                <label className="form-label" htmlFor="bWebsite">Business Website</label>
                                <input type="url" id="bWebsite" className="form-control" placeholder="Enter website" required
                                    value={website}
                                    onChange={(event) => setWebsite(event.target.value)}
                                />
                                <div className='invalid-feedback'></div>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="mb-3">
                                <label className="form-label" htmlFor="perClick">Per Click Limit</label>
                                <input type="number" min="0" id="perClick" className="form-control" placeholder="Per click Number" required
                                    value={perClickLimit}
                                    onChange={(event) => setPerClickLimit(event.target.value)}
                                />
                                <div className='invalid-feedback'></div>
                            </div>
                        </div>
                    </div>

                    {/* Category and subcategory click section */}
                    <div className="row gx-3">
                        <div className="col-sm-6">
                            <div className="mb-3">
                                <label className="form-label" htmlFor="bWebsite"> Category </label>
                                <select id="category" className="form-select" type="select" required onChange={(event) => OnChangeValue(event.target.value)}>
                                    {
                                        categoryList.map((ele) => (
                                            <option value={ele._id} >{ele.name}</option>
                                        ))
                                    }
                                </select>
                                <div className='invalid-feedback'></div>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="mb-3">
                                <label className="form-label" htmlFor="perClick">Subcategory</label>
                                <select id="subcategory" className="form-select" type="select" required
                                    onChange={(event) => handleSubcategory(event.target.value)}
                                >
                                    {
                                        subCategoryList.map((ele) => (
                                            <option value={ele._id}>{ele.name}</option>
                                        ))
                                    }
                                </select>
                                <div className='invalid-feedback'></div>
                            </div>
                        </div>
                    </div>

                    {/* Business description section*/}
                    <div className='business-description mb-4'>
                        <label className="form-label" htmlFor="businessDescription">Business Description</label>
                        <textarea className='form-control' rows={4} id='businessDescription' placeholder='Write your business description...'
                            value={businessDescription}
                            onChange={(event) => setBusinessDescription(event.target.value)}
                        >
                        </textarea>
                    </div>

                    {/* Hour's of operation section */}
                    <div className="hour-operation-sec mb-3">
                        <h5 className="sec-title fs-5 mb-3">Hour's of operation</h5>

                        {/* Days Status */}
                        {dd.map((name, index) => (
                            <div className='operation-day' key={name.name}>
                                <div className="form-check form-switch">
                                    <input className="form-check-input" type="checkbox" role="switch" id={`customSwitch${index}`} checked={name.status}
                                        onChange={() => handleWeekdays(`is_${name.day}_Open`,
                                            name.status ? false : true)}
                                    />
                                    <label className="form-check-label text-primary" htmlFor={`customSwitch${index}`} style={{ fontWeight: '600' }}>{name.day}</label>
                                </div>
                                <div className="row gx-2 gx-md-3" key={index}>
                                    <div className="col-lg-2 col-4">
                                        <div className="mb-3">
                                            <label htmlFor="openHour" className="form-label">Open hour</label>
                                            <select id="openHour" className="form-select" type="select" required
                                                value={name.openHrs}
                                                onChange={(e) =>
                                                    handleWeekdays(
                                                        `${name.day}_open_time_hour`,
                                                        e.target.value
                                                    )
                                                }
                                            >
                                                {hours.map((option) => (
                                                    <option value={option}>{option}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-lg-2 col-4">
                                        <div className="mb-3">
                                            <label htmlFor="openMin" className="form-label">Open min</label>
                                            <select id="openMin" className="form-select" type="select" required
                                                value={name.openMin}
                                                onChange={(e) =>
                                                    handleWeekdays(
                                                        `${name.day}_open_time_min`,
                                                        e.target.value
                                                    )
                                                }
                                            >
                                                {minutes.map((option) => (
                                                    <option value={option}>{option}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-lg-2 col-4">
                                        <div className="mb-3">
                                            <label htmlFor="openStatus" className="form-label">Open am/pm</label>
                                            <select id="openStatus" className="form-select" type="select" required
                                                value={name.open_am_pm}
                                                onChange={(e) =>
                                                    handleWeekdays(
                                                        `${name.day}_open_time_am_pm`,
                                                        e.target.value
                                                    )
                                                }
                                            >
                                                <option value="AM">AM</option>
                                                <option value="PM">PM</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-lg-2 col-4">
                                        <div className="mb-3">
                                            <label htmlFor="closeHour" className="form-label">Close hour</label>
                                            <select id="closeHour" className="form-select" type="select" required
                                                value={name.closeHrs}
                                                onChange={(e) =>
                                                    handleWeekdays(
                                                        `${name.day}_close_time_hour`,
                                                        e.target.value
                                                    )
                                                }
                                            >
                                                {hours.map((option) => (
                                                    <option value={option}>{option}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-lg-2 col-4">
                                        <div className="mb-3">
                                            <label htmlFor="closeMin" className="form-label">Close min</label>
                                            <select id="closeMin" className="form-select" type="select" required
                                                value={name.closeMin}
                                                onChange={(e) =>
                                                    handleWeekdays(
                                                        `${name.day}_close_time_min`,
                                                        e.target.value
                                                    )
                                                }
                                            >
                                                {minutes.map((option) => (
                                                    <option value={option}>{option}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-lg-2 col-4">
                                        <div className="mb-3">
                                            <label htmlFor="closeStatus" className="form-label">Close am/pm</label>
                                            <select id="closeStatus" className="form-select" type="select" required
                                                value={name.close_am_pm}
                                                onChange={(e) =>
                                                    handleWeekdays(
                                                        `${name.day}_close_time_am_pm`,
                                                        e.target.value
                                                    )
                                                }
                                            >
                                                <option value="AM">AM</option>
                                                <option value="PM">PM</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                    {/* Amenities & more section */}
                    <div className='amenities-sec mb-4'>
                        <h5 className="sec-title fs-5">Amenities & more</h5>
                        <div className="checkbox-wrapper mb-3">
                            <div className="form-check mb-2">
                                <input className="form-check-input" type="checkbox" id="inlineCheckbox1" value={amenities.includes('Offer Takeout') ? true : false}
                                    onChange={() => handleAmenities('Offer Takeout')}
                                />
                                <label className="form-check-label" htmlFor="inlineCheckbox1"><i class="bi bi-box2-fill"></i> Offer Takeout</label>
                            </div>
                            <div className="form-check mb-2">
                                <input className="form-check-input" type="checkbox" id="inlineCheckbox2"
                                    value={amenities.includes('Offer Catering') ? true : false}
                                    onChange={() => handleAmenities('Offer Catering')}
                                />
                                <label className="form-check-label" htmlFor="inlineCheckbox2"><img src={catering} alt='Icon' style={{ width: '16px', marginTop: '-5px' }} /> Offer Catering</label>
                            </div>
                            <div className="form-check mb-2">
                                <input className="form-check-input" type="checkbox" id="inlineCheckbox3"
                                    value={amenities.includes('Good & Kids') ? true : false}
                                    onChange={() => handleAmenities('Good & Kids')}
                                />
                                <label className="form-check-label" htmlFor="inlineCheckbox3"><img src={require('../assets/icons/good-for-kids.png')} alt='Icon' style={{ width: '16px', marginTop: '-7px' }} /> Good & Kids</label>
                            </div>
                            <div className="form-check mb-2">
                                <input className="form-check-input" type="checkbox" id="inlineCheckbox4"
                                    value={amenities.includes('Free wi-fi') ? true : false}
                                    onChange={() => handleAmenities('Free wi-fi')}
                                />
                                <label className="form-check-label" htmlFor="inlineCheckbox4"><i class="bi bi-router-fill"></i> Free Wi-Fi</label>
                            </div>
                            <div className="form-check mb-2">
                                <input className="form-check-input" type="checkbox" id="inlineCheckbox5"
                                    value={amenities.includes('TV') ? true : false}
                                    onChange={() => handleAmenities('TV')}
                                />
                                <label className="form-check-label" htmlFor="inlineCheckbox5"><i class="bi bi-display"></i> TV</label>
                            </div>
                            <div className="form-check mb-2">
                                <input className="form-check-input" type="checkbox" id="inlineCheckbox6" value={amenities.includes('Accept Credit Card') ? true : false}
                                    onChange={() => handleAmenities('Accept Credit Card')}
                                />
                                <label className="form-check-label" htmlFor="inlineCheckbox6"><i class="bi bi-credit-card"></i> Accept Credit Card</label>
                            </div>
                            <div className="form-check mb-2">
                                <input className="form-check-input" type="checkbox" id="inlineCheckbox7"
                                    value={amenities.includes('Waiter Service') ? true : false}
                                    onChange={() => handleAmenities('Waiter Service')}
                                />
                                <label className="form-check-label" htmlFor="inlineCheckbox7"><img src={require('../assets/icons/waiter.png')} alt='Icon' style={{ width: '15px', marginTop: '-7px' }} /> Waiter Service</label>
                            </div>
                            <div className="form-check mb-2">
                                <input className="form-check-input" type="checkbox" id="inlineCheckbox8"
                                    value={amenities.includes('Healt Score Green') ? true : false}
                                    onChange={() => handleAmenities('Healt Score Green')}
                                />
                                <label className="form-check-label" htmlFor="inlineCheckbox8"><i class="bi bi-clipboard2-plus-fill"></i> Health Score Green</label>
                            </div>
                            <div className="form-check mb-2">
                                <input className="form-check-input" type="checkbox" id="inlineCheckbox9"
                                    value={amenities.includes('No Alchohal') ? true : false}
                                    onChange={() => handleAmenities('No Alchohal')}
                                />
                                <label className="form-check-label" htmlFor="inlineCheckbox9"><img src={require('../assets/icons/no-alcohol.png')} alt='Icon' style={{ width: '15px', marginTop: '-7px' }} /> No Alcohal</label>
                            </div>
                            <div className="form-check mb-2">
                                <input className="form-check-input" type="checkbox" id="inlineCheckbox10"
                                    value={amenities.includes('Offer Delivery') ? true : false}
                                    onChange={() => handleAmenities('Offer Delivery')}
                                />
                                <label className="form-check-label" htmlFor="inlineCheckbox10"><i class="bi bi-truck"></i> Offer Delivery</label>
                            </div>
                            <div className="form-check mb-2">
                                <input className="form-check-input" type="checkbox" id="inlineCheckbox11"
                                    value={amenities.includes('Accept Apple Play') ? true : false}
                                    onChange={() => handleAmenities('Accept Apple Play')}
                                />
                                <label className="form-check-label" htmlFor="inlineCheckbox11"><i class="bi bi-apple"></i> Accept Apple Play</label>
                            </div>
                            <div className="form-check mb-2">
                                <input className="form-check-input" type="checkbox" id="inlineCheckbox12"
                                    value={amenities.includes('Wheel Chair Accessible') ? true : false}
                                    onChange={() => handleAmenities('Wheel Chair Accessible')}
                                />
                                <label className="form-check-label" htmlFor="inlineCheckbox12"><img src={require('../assets/icons/wheelchair.png')} alt='Icon' style={{ width: '16px', marginTop: '-5px' }} /> Wheel chair Accesible</label>
                            </div>
                            <div className="form-check mb-2">
                                <input className="form-check-input" type="checkbox" id="inlineCheckbox13"
                                    value={amenities.includes('Private lot Parking') ? true : false}
                                    onChange={() => handleAmenities('Private lot Parking')}
                                />
                                <label className="form-check-label" htmlFor="inlineCheckbox13"><i class="bi bi-p-square-fill"></i> Private Lot Parking</label>
                            </div>
                            <div className="form-check mb-2">
                                <input className="form-check-input" type="checkbox" id="inlineCheckbox14"
                                    value={amenities.includes('Bike Parking') ? true : false}
                                    onChange={() => handleAmenities('Bike Parking')}
                                />
                                <label className="form-check-label" htmlFor="inlineCheckbox14"><img src={require('../assets/icons/bike.png')} alt='Icon' style={{ width: '27px', marginTop: '-5px' }} /> Bike Parking</label>
                            </div>
                            <div className="form-check mb-2">
                                <input className="form-check-input" type="checkbox" id="inlineCheckbox15"
                                    value={amenities.includes('Virtual Constituation') ? true : false}
                                    onChange={() => handleAmenities('Virtual Constituation')}
                                />
                                <label className="form-check-label" htmlFor="inlineCheckbox15" style={{ marginTop: '-1px' }}><i class="bi bi-badge-vr-fill fs-18"></i> Virtual Constituation</label>
                            </div>
                            <div className="form-check mb-2">
                                <input className="form-check-input" type="checkbox" id="inlineCheckbox16"
                                    value={amenities.includes('Staff wear Mask') ? true : false}
                                    onChange={() => handleAmenities('Staff wear Mask')}
                                />
                                <label className="form-check-label" htmlFor="inlineCheckbox16"><i class="bi bi-mask"></i> Staff Wear Mask</label>
                            </div>
                            <div className="form-check mb-2">
                                <input className="form-check-input" type="checkbox" id="inlineCheckbox17"
                                    value={amenities.includes('Good for Kids') ? true : false}
                                    onChange={() => handleAmenities('Good for Kids')}
                                />
                                <label className="form-check-label" htmlFor="inlineCheckbox17"><img src={require('../assets/icons/good-for-kids.png')} alt='Icon' style={{ width: '16px', marginTop: '-5px' }} /> Good for Kids</label>
                            </div>
                            <div className="form-check mb-2">
                                <input className="form-check-input" type="checkbox" id="inlineCheckbox18"
                                    value={amenities.includes('Moderate Noise') ? true : false}
                                    onChange={() => handleAmenities('Moderate Noise')}
                                />
                                <label className="form-check-label" htmlFor="inlineCheckbox18"><i class="bi bi-volume-down-fill"></i> Moderate Noise</label>
                            </div>
                            <div className="form-check mb-2">
                                <input className="form-check-input" type="checkbox" id="inlineCheckbox19"
                                    value={amenities.includes('Reservation Available') ? true : false}
                                    onChange={() => handleAmenities('Reservation Available')}
                                />
                                <label className="form-check-label" htmlFor="inlineCheckbox19"><img src={require('../assets/icons/reservation-available.png')} alt='Icon' style={{ width: '20px', marginTop: '-5px' }} /> Reservation Available</label>
                            </div>
                        </div>
                    </div>

                    {/* Coupon message section */}
                    {(props.planDetails.PlanName && props.planDetails.PlanName.includes('Coupon')) ||
                        (props.planDetails.PlanName && props.planDetails.PlanName.includes('coupon')) ?
                        <div className='coupon-message mb-4'>
                            <h5 className="sec-title fs-5">Coupon message</h5>
                            <div className='row gx-3'>
                                <div className='col-md-4'>
                                    <div className="mb-3">
                                        <label className="form-label" htmlFor="offAmount">% Off or Amount off</label>
                                        <input type="text" id="offAmount" className="form-control" placeholder="Amount off" required
                                            onChange={(event) => setCouponDetailsText((prevInput) => ({ ...prevInput, percentageOff: event.target.value }))}
                                        />
                                        <div className='invalid-feedback'></div>
                                    </div>
                                </div>
                                <div className='col-md-4'>
                                    <div className="mb-3">
                                        <label className="form-label" htmlFor="minPurchase">Minimum Order or Booking</label>
                                        <input type="text" id="minPurchase" className="form-control" placeholder="Minimum purchase" required
                                            onChange={(event) => setCouponDetailsText((prevInput) => ({ ...prevInput, minOrder: event.target.value }))}
                                        />
                                        <div className='invalid-feedback'></div>
                                    </div>
                                </div>
                                <div className='col-md-4'>
                                    <div className="mb-3">
                                        <label className="form-label" htmlFor="offAmount">Expiration Date</label>
                                        <input type="date" id="offAmount" className="form-control" placeholder="MM/DD/YYYY" required
                                            onChange={(event) => setCouponDetailsText((prevInput) => ({ ...prevInput, expiryDate: event.target.value }))}
                                        />
                                        <div className='invalid-feedback'></div>
                                    </div>
                                </div>
                            </div>
                        </div> : ''
                    }

                    {/* Deal message section */}
                    {(props.planDetails.PlanName && props.planDetails.PlanName.includes('Deals')) ||
                        (props.planDetails.PlanName && props.planDetails.PlanName.includes('deals')) ?
                        <div className='deals-message mb-4'>
                            <h5 className="sec-title fs-5">Deal message</h5>
                            <div className='row gx-3'>
                                <div className='col-md-6'>
                                    <div className="mb-3">
                                        <label className="form-label" htmlFor="offAmount">% Off or Amount off</label>
                                        <input type="text" id="offAmount" className="form-control" placeholder="Amount off" required
                                            onChange={(event) => setdealTextDetail((prevInput) => ({ ...prevInput, percentageOff: event.target.value }))}
                                        />
                                        <div className='invalid-feedback'></div>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className="mb-3">
                                        <label className="form-label" htmlFor="minPurchase">Minimum Order or Booking</label>
                                        <input type="text" id="minPurchase" className="form-control" placeholder="Minimum purchase" required
                                            onChange={(event) => setdealTextDetail((prevInput) => ({ ...prevInput, minOrder: event.target.value }))}
                                        />
                                        <div className='invalid-feedback'></div>
                                    </div>
                                </div>
                            </div>
                        </div> : ''
                    }

                    {/* Upload logo section */}
                    <div className="logo-photo-sec mb-4 pb-2">
                        <h5 className="sec-title fs-5">Upload logo</h5>
                        <div className="row g-3">
                            <div className="col-sm-6 col-md-3">
                                <div className="uploaded-img-wrapper">
                                    <img className='border rounded img-fluid' src={business_logo === '' ? require('../assets/bg-banner4.jpg') : logoFile} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="upload-wrapper mt-4">
                            <label htmlFor="uploadFile" className='btn btn-sm btn-primary d-inline-flex align-items-center justify-content-center' type="button"><i className="bi bi-upload me-2"></i>Upload</label>
                            <input id="uploadFile" accept="image/*" multiple="" type="file" style={{ display: 'none' }} onChange={(event) => handleUploadFile(event)} />
                        </div>
                    </div>

                    <div className='btn-wrapper mt-4'>
                        <button type='submit' className='btn btn-primary px-5' onClick={(event) => handleSubmit(event)}>Submit</button>
                    </div>
                </form>
            </div>
        </div>
    )
}

const mapPropsToState = (state) => {
    return {
        loading: state.business.loading,
        error: state.business.error,
        success: state.business.success,
        categoryList: state.business.categoryList,
        planDetails: state.subscriptionPlan.planDetails
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getCategoryList: () => dispatch(actions.getCategoryList()),
        getPlanDetailOfUser: () => dispatch(actions.getPlanDetailOfUser()),
        AddBusinessDetails: (data) => dispatch(actions.AddBusinessDetails(data))
    }
}

export default connect(
    mapPropsToState,
    mapDispatchToProps
)(withSnackbar(AddBusinessDetails))
