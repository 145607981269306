import React, { useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { connect } from 'react-redux'
import { withSnackbar } from 'react-simple-snackbar'
import * as actions from '../store/actions/index'

const Register = (props) => {
    const [email, setEmail] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [mobile_no, setMobile_no] = useState('')
    const [country_code, setCountry_code] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState(true)

    const handleChange = (type, e) => {
        if (type === 'email') {
            setEmail(e.target.value)
        } else if (type === 'password') {
            setPassword(e.target.value)
        } else if (type === 'confirmPassword') {
            setConfirmPassword(e.target.value)
        }
        else if (type === 'firstName') {
            setFirstName(e.target.value)
        } else if (type === 'lastName') {
            setLastName(e.target.value)
        }
    }
    const phoneChange = (phoneDetails) => {
        let countryCode = phoneDetails.split('-')[0]
        let mobileNo = phoneDetails.split('-')[1]
        setCountry_code(countryCode)
        setMobile_no(mobileNo)
    }

    const handleSubmit = (e) => {

        e.preventDefault()
        if (email && firstName && lastName && country_code && mobile_no && password) {
            if (password !== confirmPassword) {
                setPasswordConfirmation(false)
            } else {
                let data = {
                    email: email, password: password,
                    country_code: country_code, mobile_no: mobile_no,
                    user_type: 'user',
                    name: firstName + '' + lastName
                }
                props.register(data)
            }
        }

    }

    return (
        <div className='auth-page d-flex align-items-center py-5'>
            {/* title head */}
            <Helmet>
                <title>CouponRanking | User Registration</title>
            </Helmet>
            <div className='container'>
                <div className='border border-1 rounded-3 overflow-hidden shadow mx-auto' style={{ maxWidth: '1000px' }}>
                    <div className='row g-0'>
                        <div className='col-md-5'>
                            <div className='left-section section-bg-overlay d-flex flex-column justify-content-center bg-primary bg-opacity-50 text-white h-100 px-4 py-5'>
                                <div className='cntnt-wrapper position-relative z-1'>
                                    <Link to={'/'} className='d-block logo-title text-uppercase small fw-bold mb-3'>
                                        <img className='img-fluid rounded-circle' src={require('../assets/logo.png')} alt='Logo' style={{ width: '100px' }} />
                                    </Link>
                                    <div className='heading-title fs-2 fw-bold lh-sm mb-3'>Start Your<br /> Journey With Us.</div>
                                    <div className='heading-subtitle mb-3'>Make Your Money Go Further with the Latest Deals and Coupons</div>
                                    <div className='img-wrapper text-center d-none d-md-block'>
                                        <img className='img-fluid rounded-circle' src={require('../assets/hero-banner1.webp')} alt='Logo' style={{ width: '300px' }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-7'>
                            <div className="right-section d-flex align-items-center bg-white h-100 px-3 px-sm-5 py-5">
                                <form className='w-100'>
                                    <h3 className='fs-5 fw-bold mb-4'>Sign Up</h3>
                                    {/* Start: Alert message */}
                                    {props.error ?
                                        <div className="d-flex align-items-center border border-danger text-danger rounded-1 shadow-sm px-3 py-2 mb-3" role="alert">
                                            <i className="bi bi-exclamation-triangle-fill fs-18"></i>
                                            <div className='ms-2 lh-sm'>
                                                {props.error}
                                            </div>
                                        </div> : ''
                                    }
                                    {/* End: Alert message */}
                                    <div className='row gx-3'>
                                        <div className='col-sm-6'>
                                            <div className="mb-3">
                                                <label htmlFor="fName" className="form-label">First Name</label>
                                                <input type="text" className="form-control" id="fName" placeholder='Enter first name' required
                                                    value={firstName}
                                                    onChange={(e) => handleChange('firstName', e)}
                                                />
                                            </div>
                                        </div>
                                        <div className='col-sm-6'>
                                            <div className="mb-3">
                                                <label htmlFor="lName" className="form-label">Last Name</label>
                                                <input type="text" className="form-control" id="lName" placeholder='Enter last name' required
                                                    value={lastName}
                                                    onChange={(e) => handleChange('lastName', e)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="email" className="form-label">Your Email</label>
                                        <input type="email" className="form-control" id="email" placeholder='Enter email' required
                                            value={email}
                                            onChange={(e) => handleChange('email', e)}
                                        />
                                    </div>
                                    <div className='input-cover mb-3'>
                                        <label htmlFor="number" className="form-label">Mobile Number</label>
                                        <PhoneInput required
                                            // inputProps={{
                                            //     name: 'phone',
                                            //     required: true,
                                            // }}
                                            country={"us"}
                                            // enableSearch={true}
                                            countryCodeEditable={false}
                                            onChange={(phone, country) => {
                                                const reducedPhone = phone.replace(
                                                    country.dialCode, '',);
                                                phoneChange(country.dialCode + '-' + reducedPhone,);
                                            }}
                                            inputClass="w-100"
                                        />
                                    </div>
                                    <div className='row g-3'>
                                        <div className='col-sm-6'>
                                            <div className="mb-3">
                                                <label htmlFor="password" className="form-label">Password</label>
                                                <input type="password" className="form-control" id="password" placeholder='Enter password' required
                                                    value={password}
                                                    onChange={(e) => handleChange('password', e)}
                                                />
                                            </div>
                                        </div>
                                        <div className='col-sm-6'>
                                            <div className="mb-3">
                                                <label htmlFor="c-password" className="form-label">Confirm Password</label>
                                                <input type="password" className="form-control" id="c-password" placeholder='Confirm password' required
                                                    value={confirmPassword}
                                                    onChange={(e) => handleChange('confirmPassword', e)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="form-check mb-4 pb-2">
                                        <input type="checkbox" className="form-check-input" id="exampleCheck1" required />
                                        <label className="form-check-label" htmlFor="exampleCheck1">I agree to the <Link to={'#'} className='btn-link'>Terms & Condition</Link></label>
                                    </div> */}
                                    <div className="w-100 mb-3">
                                        <button type="submit" className="w-100 btn btn-primary" onClick={(e) => handleSubmit(e)}>Register Now</button>
                                    </div>
                                    <div className='text-center'>Already have account?
                                        <Link to={'/login'} className='ms-2 btn-link text-decoration-none'>Login</Link>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


const mapPropsToState = (state) => {
    return {
        loading: state.auth.loading,
        error: state.auth.error,
        success: state.auth.success,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        register: (data) => dispatch(actions.register(data)),
    }
}

export default connect(
    mapPropsToState,
    mapDispatchToProps
)(withSnackbar(Register))