import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Helmet } from "react-helmet";
import { withSnackbar } from 'react-simple-snackbar'
import * as actions from '../store/actions/index';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactLoading from 'react-loading';
import DatePicker from "react-datepicker";
import Autocomplete from 'react-google-autocomplete';
import { useNavigate } from 'react-router-dom';
import Geocode from "react-geocode";
import { getDistance } from 'geolib';
Geocode.setApiKey("AIzaSyB_KZHG7uYGsaMGdQLDRrV3Qw6xRvLqg_Q");
Geocode.enableDebug();
const Budget = ["$1.99 -$4.99", "$5.00 - $9.99", "$50 - $99.99", "$100 - $149.99", "$300 -$399.99", "$400 - $499.99", "Not Sure", "Other"];
const ServiceTime = ['3 hours - 12 hours', '1 day - 3 days', '1 week-2 weeks', "Not Sure", "Other"];
const ServiceFrequency = ['One time only', 'Once a week', 'Bi- weekly', 'once a monthly', "Not Sure", "Other"];
const VehicleProvider = ['The customer', 'The driver', "Not Sure", "Other"];
const Tip = ["$0.00", "$5.00", "$10.00", "$15.00", "$20.00", "Not Sure", "Other"];
const PaymentMethod = ["Cash app", "Zelle", "Cash", "Debit card", "Credit Card", "Not Sure", "Other"];
const WaitingTime = ["No waiting time", "15 minutes", "30 minutes", "45 minutes", "60 minutes", "Not Sure", "Other"];
const RoundTrip = ["Round trip", "One way", "Not Sure", "Other"];

const RideTaxi = (props) => {
    let customerInfo = JSON.parse(localStorage.getItem('userInfo'));
    let navigate = useNavigate()
    const [rideServiceType, setRideServiceType] = useState('')
    const [vehicleType, setVehicleType] = useState();
    const [otherVehicleType, setOtherVehicleType] = useState(false);
    const [passengerCount, setPassengerCount] = useState();
    const [otherPassengerCount, setOtherPassengerCount] = useState(false);
    const [LuggageCount, setLuggageCount] = useState();
    const [otherLuggageCount, setOtherLuggageCount] = useState(false);
    const [petStatus, setPetStatus] = useState('')
    const [ridePurpose, setRidePurpose] = useState();
    const [otherRidePurpose, setOtherRidePurpose] = useState(false);
    const [budgetForService, setBudget] = useState('');
    const [otherBudget, setOtherBudget] = useState(false);
    const [serviceTime, setServiceTime] = useState('');
    const [otherServiceTime, setOtherServiceTime] = useState(false);
    const [driverNeed, setDriverNeed] = useState('');
    const [otherDriverNeed, setOtherDriverNeed] = useState(false);
    const [serviceFrequency, setServiceFrequency] = useState('');
    const [otherServiceFrequency, setOtherServiceFrequency] = useState(false);
    const [vehicleProvider, setVehicleProvider] = useState('');
    const [otherVehicleProvider, setOtherVehicleProvider] = useState(false);
    const [tipForService, setTip] = useState('');
    const [otherTip, setOtherTip] = useState(false);
    const [roundTrip, setRoundTrip] = useState('');
    const [otherRoundTrip, setOtherRoundTrip] = useState(false);
    const [waitingTime, setWaitingTime] = useState('');
    const [otherWaitingTime, setOtherWaitingTime] = useState(false);
    const [payment, setPaymentMethod] = useState('');
    const [otherPaymentMethod, setOtherPaymentMethod] = useState(false);
    const [pickupLocation, setPickupLocation] = useState('');
    const [pickupLat, setPickupLat] = useState('');
    const [pickupLong, setPickupLong] = useState('');
    const [dropOffLocation, setDropOffLocation] = useState('');
    const [dropLat, setDropLat] = useState('');
    const [dropLong, setDropLong] = useState('');
    const [pickUpDate, setPickUpDate] = useState(new Date());
    const [pickupTime, setPickUpTime] = useState('');
    const [otherInfo, setOtherInfo] = useState('');
    const [contactMode, setContactMode] = useState('');
    const [otherContactMode, setOtherContactMode] = useState(false);

    const handleVehicleType = (value) => {
        if (value === 'other') {
            setOtherVehicleType(true)
            setVehicleType('')
        } else {
            setOtherVehicleType(false)
            setVehicleType(value)
        }
    }
    const handlePassengerCount = (value) => {
        if (value === 'other') {
            setOtherPassengerCount(true)
            setPassengerCount('')
        } else {
            setOtherPassengerCount(false)
            setPassengerCount(value)
        }
    }
    const handleLuggageCount = (value) => {
        if (value === 'other') {
            setOtherLuggageCount(true)
            setLuggageCount('')
        } else {
            setOtherLuggageCount(false)
            setLuggageCount(value)
        }
    }
    //for pickup time in am/pm format
    const handleTimeChange = (event) => {
        var timeSplit = event.target.value.split(':'),
            hours,
            minutes,
            hours = timeSplit[0];
        minutes = timeSplit[1];
        // Check whether AM or PM
        let newformat = hours >= 12 ? 'PM' : 'AM';
        // Find current hour in AM-PM Format
        hours = hours % 12;

        // To display "0" as "12"
        hours = hours ? hours : 12;
        // minutes = minutes < 10 ? '0' + minutes : minutes;
        let selectedTime = hours + ':' + minutes + ' ' + newformat
        setPickUpTime(selectedTime)
    }
    const handleRidePurpose = (value) => {
        if (value === 'other') {
            setOtherRidePurpose(true)
            setRidePurpose('')
        } else {
            setOtherRidePurpose(false)
            setRidePurpose(value)
        }
    }
    const handleBudget = (value) => {
        if (value === 'Other') {
            setOtherBudget(true)
            setBudget('')
        } else {
            setOtherBudget(false)
            setBudget(value)
        }
    }
    const handleDriverNeed = (value) => {
        if (value === 'Other') {
            setOtherDriverNeed(true)
            setDriverNeed('')
        } else {
            setOtherDriverNeed(false)
            setDriverNeed(value)
        }
    }
    const handleServiceTime = (value) => {
        if (value === 'Other') {
            setOtherServiceTime(true)
            setServiceTime('')
        } else {
            setOtherServiceTime(false)
            setServiceTime(value)
        }
    }
    const handleServiceFrequency = (value) => {
        if (value === 'Other') {
            setOtherServiceFrequency(true)
            setServiceFrequency('')
        } else {
            setOtherServiceFrequency(false)
            setServiceFrequency(value)
        }
    }
    const handleVehicleProvider = (value) => {
        if (value === 'Other') {
            setOtherVehicleProvider(true)
            setVehicleProvider('')
        } else {
            setOtherVehicleProvider(false)
            setVehicleProvider(value)
        }
    }
    const handleTip = (value) => {
        if (value === 'Other') {
            setOtherTip(true)
            setTip('')
        } else {
            setOtherTip(false)
            setTip(value)
        }
    }
    const handleRoundTrip = (value) => {
        if (value === 'Other') {
            setOtherRoundTrip(true)
            setRoundTrip('')
        } else {
            setOtherRoundTrip(false)
            setRoundTrip(value)
        }
    }
    const handleWaitingTime = (value) => {
        if (value === 'Other') {
            setOtherWaitingTime(true)
            setWaitingTime('')
        } else {
            setOtherWaitingTime(false)
            setWaitingTime(value)
        }
    }
    const handlePaymentMethod = (value) => {
        if (value === 'Other') {
            setOtherPaymentMethod(true)
            setPaymentMethod('')
        } else {
            setOtherPaymentMethod(false)
            setPaymentMethod(value)
        }
    }

    const handleContactMode = (value) => {
        if (value === 'other') {
            setOtherContactMode(true)
            setContactMode('')
        } else {
            setOtherContactMode(false)
            setContactMode(value)
        }
    }
    const handleLocationSelection = (place, type) => {
        const lat = place.geometry.location.lat();
        const lng = place.geometry.location.lng();
        fetch('https://maps.googleapis.com/maps/api/geocode/json?latlng=' + lat + ',' + lng + '&key=AIzaSyB_KZHG7uYGsaMGdQLDRrV3Qw6xRvLqg_Q', {
            method: 'GET'
        }).then((response) => response.json())
            .then((data) => {
                if (data.results[1]) {
                    const address = data.results[0].formatted_address
                    if (type === 'pickup') {
                        setPickupLocation(address)
                        setPickupLat(lat)
                        setPickupLong(lng)
                    } else {
                        setDropOffLocation(address)
                        setDropLat(lat)
                        setDropLong(lng)
                    }
                }
            })
            .catch((error) => {
                toast.error(error, {
                    autoClose: 700,
                    hideProgressBar: true,
                })
            })
    };
    //  submit
    const handleSubmit = (e) => {
        e.preventDefault()
        if (rideServiceType && ridePurpose && payment && pickupLocation && dropOffLocation &&
            pickUpDate && pickupTime && contactMode && budgetForService) {
            let UsPickUpDate = new Date(pickUpDate).toLocaleDateString("en-US");
            let distanceForRideInMeter = getDistance(
                { latitude: pickupLat, longitude: pickupLong },
                { latitude: dropLat, longitude: dropLong }
            );
            let distanceForRide = distanceForRideInMeter ? (distanceForRideInMeter * 0.000621371).toFixed(2) : ''
            let quoteDetails = {
                "Requested Service": "Ride(Taxi) Service",
                "What type of ride services do you want?": rideServiceType,
                "What is your budget for this service?": budgetForService,
                "Purpose of the ride?": ridePurpose,
                "What is the best way to contact you?": contactMode,
                "What is your payment method for this service?": payment,
                "Pickup Location": pickupLocation,
                "Drop Off Location": dropOffLocation,
                "Pickup Date": UsPickUpDate,
                "Pickup Time": pickupTime,
                "Any other details": otherInfo
            }
            if (distanceForRide) {
                quoteDetails['Distance betweeen location'] = `${distanceForRide} miles`
            }
            let mobileNo = customerInfo.country_code + customerInfo.mobile_no
            let customerName = customerInfo.name;
            let customerEmail = customerInfo.email;
            let data = {
                mobileNo: mobileNo,
                customerName: customerName,
                customerEmail: customerEmail,
                businessName: props.businessDetails.businessName,
                businessEmail: props.businessDetails.email,
                businessId: props.businessDetails.businessId,
                clickType: 'requestQuote',
                quoteDetails: quoteDetails
            }
            if (rideServiceType === 'Within city taxi services') {
                if (waitingTime && vehicleType && passengerCount && LuggageCount && petStatus) {
                    quoteDetails["What type of vehicle do you need for the ride?"] = vehicleType
                    quoteDetails["How many passengers will be on the ride?"] = passengerCount
                    quoteDetails["Any pet carrier?"] = petStatus
                    quoteDetails["How many bag of luggage(s) will be on the ride?"] = LuggageCount
                    quoteDetails['Any waiting time on this trip?'] = waitingTime;
                    props.clickPerCount(data, toast, navigate)
                } else {
                    toast.warning('Please Select All required field!', {
                        autoClose: 700,
                        hideProgressBar: true,
                    })
                }

            }
            if (rideServiceType === 'Commute (city to city ride)') {
                if (vehicleType && passengerCount && LuggageCount && petStatus && tipForService && roundTrip && waitingTime) {
                    quoteDetails["What type of vehicle do you need for the ride?"] = vehicleType
                    quoteDetails["How many passengers will be on the ride?"] = passengerCount
                    quoteDetails["Any pet carrier?"] = petStatus
                    quoteDetails["How many bag of luggage(s) will be on the ride?"] = LuggageCount
                    quoteDetails['Tip for this service'] = tipForService;
                    quoteDetails['Is it a round trip or one way?'] = roundTrip;
                    quoteDetails['Any waiting time on this trip?'] = waitingTime;
                    props.clickPerCount(data, toast, navigate)
                } else {
                    toast.warning('Please Select All required field!', {
                        autoClose: 700,
                        hideProgressBar: true,
                    })
                }
            }
            if (rideServiceType === 'Hire a personal driver') {
                if (serviceTime && serviceFrequency && vehicleProvider && driverNeed && roundTrip) {
                    quoteDetails['How many drivers do you need?'] = driverNeed;
                    quoteDetails['How long will the services take?'] = serviceTime;
                    quoteDetails['How often do you need the service?'] = serviceFrequency;
                    quoteDetails['Who will provide the vehicle?'] = vehicleProvider;
                    quoteDetails['Is it a round trip or one way?'] = roundTrip;
                    if (vehicleProvider === 'The driver') {
                        if (vehicleType && passengerCount && LuggageCount) {
                            quoteDetails["What type of vehicle do you need for the ride?"] = vehicleType
                            quoteDetails["How many passengers will be on the ride?"] = passengerCount
                            quoteDetails["How many bag of luggage(s) will be on the ride?"] = LuggageCount
                            props.clickPerCount(data, toast, navigate)
                        } else {
                            toast.warning('Please Select All required field!', {
                                autoClose: 700,
                                hideProgressBar: true,
                            })
                        }
                    } else {
                        props.clickPerCount(data, toast, navigate)
                    }
                } else {
                    toast.warning('Please Select All required field!', {
                        autoClose: 700,
                        hideProgressBar: true,
                    })
                }
            }
        } else {
            toast.warning('Please Select All required field!', {
                autoClose: 700,
                hideProgressBar: true,
            })
        }
    }

    return (
        <div id="rideTaxi" className='ride-delivery-sec card shadow-sm py-4 py-md-5 px-3 px-md-5 mt-4'>
            <Helmet>
                <title>Request Quote For Ride(Taxi) Service</title>
            </Helmet>
            <ToastContainer />
            <div className="section-label fs-3 mb-4">Ride(Taxi) Service</div>
            <div className="mb-3">
                <label htmlFor="chooseRide" className="form-label fs-6 mb-2">What type of ride services do you want?</label>
                <div className="d-flex flex-wrap g-2">
                    <div className="form-check me-3">
                        <input className="form-check-input" type="radio" name="radioGroupForRideService" id="WCTS" required
                            onClick={() => setRideServiceType('Within city taxi services')} />
                        <label className="form-check-label" htmlFor="WCTS">Within city taxi services</label>
                    </div>
                    <div className="form-check me-3">
                        <input className="form-check-input" type="radio" name="radioGroupForRideService" id="commute"
                            onClick={() => setRideServiceType('Commute (city to city ride)')} />
                        <label className="form-check-label" htmlFor="commute">Commute (city to city ride)</label>
                    </div>
                    <div className="form-check me-3">
                        <input className="form-check-input" type="radio" name="radioGroupForRideService" id="commute"
                            onClick={() => setRideServiceType('Hire a personal driver')} />
                        <label className="form-check-label" htmlFor="commute">Hire a personal driver</label>
                    </div>
                </div>
            </div>
            {rideServiceType === 'Hire a personal driver' ?
                <div>
                    <div className="mb-3">
                        <label htmlFor="chooseDriverNeed" className="form-label fs-6 mb-2">How many drivers do you need?</label>
                        <div className="d-flex flex-wrap g-2">
                            {
                                ['1', '2', '3', '4', '5', 'Not sure', 'Other'].map((driver) => (
                                    <div className="form-check me-3" key={driver}>
                                        <input className="form-check-input" type="radio" name="radioGroupDriverNeed" id={driver} required
                                            onClick={() => handleDriverNeed(driver)} />
                                        <label className="form-check-label" htmlFor={driver}>{driver}</label>
                                    </div>
                                ))
                            }

                        </div>
                        {
                            otherDriverNeed ?
                                <input type="text" className="form-control mt-2" id="otherDriverNeed" placeholder='Other driver...' required
                                    onChange={(e) => setDriverNeed(e.target.value)} />
                                : ''
                        }
                    </div>
                    <div className="mb-3">
                        <label htmlFor="chooseServiceTime" className="form-label fs-6 mb-2">How long will the services take?</label>
                        <div className="d-flex flex-wrap g-2">
                            {
                                ServiceTime.map((service) => (
                                    <div className="form-check me-3" key={service}>
                                        <input className="form-check-input" type="radio" name="radioGroupServiceTime" id={service} required
                                            onClick={() => handleServiceTime(service)} />
                                        <label className="form-check-label" htmlFor={service}>{service}</label>
                                    </div>
                                ))
                            }

                        </div>
                        {
                            otherServiceTime ?
                                <input type="text" className="form-control mt-2" id="otherServiceTime" placeholder='Other service time...' required
                                    onChange={(e) => setServiceTime(e.target.value)} />
                                : ''
                        }
                    </div>
                    <div className="mb-3">
                        <label htmlFor="chooseServiceTime" className="form-label fs-6 mb-2">How often do you need the service?</label>
                        <div className="d-flex flex-wrap g-2">
                            {
                                ServiceFrequency.map((freq) => (
                                    <div className="form-check me-3" key={freq}>
                                        <input className="form-check-input" type="radio" name="radioGroupServiceFrequency" id={freq} required
                                            onClick={() => handleServiceFrequency(freq)} />
                                        <label className="form-check-label" htmlFor={freq}>{freq}</label>
                                    </div>
                                ))
                            }

                        </div>
                        {
                            otherServiceFrequency ?
                                <input type="text" className="form-control mt-2" id="otherServiceFrequency" placeholder='Other service frequency...' required
                                    onChange={(e) => setServiceFrequency(e.target.value)} />
                                : ''
                        }
                    </div>
                    <div className="mb-3">
                        <label htmlFor="chooseServiceTime" className="form-label fs-6 mb-2">Who will provide the vehicle?</label>
                        <div className="d-flex flex-wrap g-2">
                            {
                                VehicleProvider.map((provider) => (
                                    <div className="form-check me-3" key={provider}>
                                        <input className="form-check-input" type="radio" name="radioGroupVehicleProvider" id={provider} required
                                            onClick={() => handleVehicleProvider(provider)} />
                                        <label className="form-check-label" htmlFor={provider}>{provider}</label>
                                    </div>
                                ))
                            }

                        </div>
                        {
                            otherVehicleProvider ?
                                <input type="text" className="form-control mt-2" id="otherVehicleProvider" placeholder='Other vehicle provider...' required
                                    onChange={(e) => setServiceFrequency(e.target.value)} />
                                : ''
                        }
                    </div>
                </div> :
                ''}
            {(rideServiceType === 'Within city taxi services' || rideServiceType === 'Commute (city to city ride)' || vehicleProvider === 'The driver') ?
                <div>
                    <div className="mb-3">
                        <label htmlFor="chooseVehicleType" className="form-label fs-6 mb-2">What type of vehicle do you need for the ride?</label>
                        <div className="d-flex flex-wrap g-2">
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupForVehicleType" id="SUV" required
                                    onClick={() => handleVehicleType('SUV')} />
                                <label className="form-check-label" htmlFor="SUV">SUV</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupForVehicleType" id="CAR"
                                    onClick={() => handleVehicleType('Car')} />
                                <label className="form-check-label" htmlFor="CAR">Car</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupForVehicleType" id="VAN"
                                    onClick={() => handleVehicleType('Van')} />
                                <label className="form-check-label" htmlFor="VAN">Van</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupForVehicleType" id="Limousine"
                                    onClick={() => handleVehicleType('Limousine')} />
                                <label className="form-check-label" htmlFor="Limousine">Limousine</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupForVehicleType" id="Bus"
                                    onClick={() => handleVehicleType('Bus')} />
                                <label className="form-check-label" htmlFor="Bus">Bus</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupForVehicleType" id="events"
                                    onClick={() => handleVehicleType('Wheelchairs transportation')} />
                                <label className="form-check-label" htmlFor="Wheelchairs">Wheelchairs transportation</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupForVehicleType" id="Non-Medical"
                                    onClick={() => handleVehicleType('Non-medical transportation')} />
                                <label className="form-check-label" htmlFor="Non-Medical">Non-medical transportation</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupForVehicleType" id="otherVehicleType"
                                    onClick={() => handleVehicleType('other')} />
                                <label className="form-check-label" htmlFor="otherVehicleType">Other</label>
                            </div>
                        </div>
                        {
                            otherVehicleType ?
                                <input type="text" className="form-control mt-2" id="OtherVehicleType" placeholder='Other Vehicle...' required
                                    onChange={(e) => setVehicleType(e.target.value)} />
                                : ''
                        }
                    </div>
                    <div className="mb-3">
                        <label htmlFor="choosePassenger" className="form-label fs-6 mb-2">How many passengers will be on the ride?</label>
                        <div className="d-flex flex-wrap g-2">
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupForPassengerCount" id="VCount1" required
                                    onClick={() => handlePassengerCount('1')} />
                                <label className="form-check-label" htmlFor="VCount1">1</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupForPassengerCount" id="VCount23"
                                    onClick={() => handlePassengerCount('2-3')} />
                                <label className="form-check-label" htmlFor="VCount23">2-3</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupForPassengerCount" id="VCount45"
                                    onClick={() => handlePassengerCount('4-5')} />
                                <label className="form-check-label" htmlFor="VCount45">4-5</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupForPassengerCount" id="otherPassengerCount"
                                    onClick={() => handlePassengerCount('other')} />
                                <label className="form-check-label" htmlFor="otherContracting">Other</label>
                            </div>
                        </div>
                        {
                            otherPassengerCount ?
                                <input type="text" className="form-control mt-2" id="OtherPassengerCount" placeholder='Other passenger count...' required
                                    onChange={(e) => setPassengerCount(e.target.value)} />
                                : ''
                        }
                    </div>
                    <div className="mb-3">
                        <label htmlFor="chooseLuggage" className="form-label fs-6 mb-2">How many bag of luggage(s) will be on the ride?</label>
                        <div className="d-flex flex-wrap g-2">
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupForLuaggageCount" id="LCount1" required
                                    onClick={() => handleLuggageCount('1')} />
                                <label className="form-check-label" htmlFor="LCount1">1</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupForLuaggageCount" id="LCount23"
                                    onClick={() => handleLuggageCount('2-3')} />
                                <label className="form-check-label" htmlFor="LCount23">2-3</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupForLuaggageCount" id="LCount45"
                                    onClick={() => handleLuggageCount('4-5')} />
                                <label className="form-check-label" htmlFor="LCount45">4-5</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupForLuaggageCount" id="otherLuggageCount"
                                    onClick={() => handleLuggageCount('other')} />
                                <label className="form-check-label" htmlFor="otherContracting">Other</label>
                            </div>
                        </div>
                        {
                            otherLuggageCount ?
                                <input type="text" className="form-control mt-2" id="OtherLuggageCount" placeholder='Other luggage count...' required
                                    onChange={(e) => setLuggageCount(e.target.value)} />
                                : ''
                        }
                    </div>
                </div>
                : ''}
            {
                (rideServiceType === 'Within city taxi services' || rideServiceType === 'Commute (city to city ride)') ?
                    <div className="mb-3">
                        <label htmlFor="petStatus" className="form-label fs-6 mb-2">Any pet carrier?</label>
                        <div className="d-flex flex-wrap g-2">
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupForPet" id="petYes" required
                                    onClick={() => setPetStatus('Yes')} />
                                <label className="form-check-label" htmlFor="petYes">Yes</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="radioGroupForPet" id="petNo"
                                    onClick={() => setPetStatus('No')} />
                                <label className="form-check-label" htmlFor="petNo">No</label>
                            </div>
                        </div>
                    </div>
                    : ''
            }
            <div className="mb-3">
                <label htmlFor="ride_purpose" className="form-label fs-6 mb-2">Purpose of the ride?</label>
                <div className="d-flex flex-wrap g-2">
                    <div className="form-check me-3">
                        <input className="form-check-input" type="radio" name="radioGroupForRidePurpose" id="airport" required
                            onClick={() => handleRidePurpose('Airport')} />
                        <label className="form-check-label" htmlFor="airport">Airport</label>
                    </div>
                    <div className="form-check me-3">
                        <input className="form-check-input" type="radio" name="radioGroupForRidePurpose" id="business_meeting"
                            onClick={() => handleRidePurpose('Business meeting')} />
                        <label className="form-check-label" htmlFor="business_meeting">Business meeting</label>
                    </div>
                    <div className="form-check me-3">
                        <input className="form-check-input" type="radio" name="radioGroupForRidePurpose" id="hotel"
                            onClick={() => handleRidePurpose('Hotel')} />
                        <label className="form-check-label" htmlFor="hotel">Hotel</label>
                    </div>
                    <div className="form-check me-3">
                        <input className="form-check-input" type="radio" name="radioGroupForRidePurpose" id="vacation"
                            onClick={() => handleRidePurpose('Vacation')} />
                        <label className="form-check-label" htmlFor="vacation">Vacation</label>
                    </div>
                    <div className="form-check me-3">
                        <input className="form-check-input" type="radio" name="radioGroupForRidePurpose" id="home"
                            onClick={() => handleRidePurpose('Home')} />
                        <label className="form-check-label" htmlFor="home">Home</label>
                    </div>
                    <div className="form-check me-3">
                        <input className="form-check-input" type="radio" name="radioGroupForRidePurpose" id="events"
                            onClick={() => handleRidePurpose('Events')} />
                        <label className="form-check-label" htmlFor="events">Events</label>
                    </div>
                    <div className="form-check me-3">
                        <input className="form-check-input" type="radio" name="radioGroupForRidePurpose" id="office"
                            onClick={() => handleRidePurpose('Office')} />
                        <label className="form-check-label" htmlFor="office">Office</label>
                    </div>
                    <div className="form-check me-3">
                        <input className="form-check-input" type="radio" name="radioGroupForRidePurpose" id="not_sure"
                            onClick={() => handleRidePurpose('Not Sure')} />
                        <label className="form-check-label" htmlFor="not_sure">Not Sure</label>
                    </div>
                    <div className="form-check me-3">
                        <input className="form-check-input" type="radio" name="radioGroupForRidePurpose" id="otherVehicleType"
                            onClick={() => handleRidePurpose('other')} />
                        <label className="form-check-label" htmlFor="otherContracting">Other</label>
                    </div>
                </div>
                {
                    otherRidePurpose ?
                        <input type="text" className="form-control mt-2" id="OtherRidePurpose" placeholder='Other purpose...' required
                            onChange={(e) => setRidePurpose(e.target.value)} />
                        : ''
                }
            </div>
            <div className="mb-3">
                <label htmlFor="chooseBudget" className="form-label fs-6 mb-2">What is your budget for this service?</label>
                <div className="d-flex flex-wrap g-2">
                    {
                        Budget.map((budge) => (
                            <div className="form-check me-3" key={budge}>
                                <input className="form-check-input" type="radio" name="radioGroupBudget" id={budge} required
                                    onClick={() => handleBudget(budge)} />
                                <label className="form-check-label" htmlFor={budge}>{budge}</label>
                            </div>
                        ))
                    }

                </div>
                {
                    otherBudget ?
                        <input type="text" className="form-control mt-2" id="otherBudget" placeholder='Other budget...' required
                            onChange={(e) => setBudget(e.target.value)} />
                        : ''
                }
            </div>
            {
                rideServiceType === 'Commute (city to city ride)' ?
                    <div className="mb-3">
                        <label htmlFor="chooseTip" className="form-label fs-6 mb-2">Select a tip for this service?</label>
                        <div className="d-flex flex-wrap g-2">
                            {
                                Tip.map((tip) => (
                                    <div className="form-check me-3" key={tip}>
                                        <input className="form-check-input" type="radio" name="radioGroupTip" id={tip} required
                                            onClick={() => handleTip(tip)} />
                                        <label className="form-check-label" htmlFor={tip}>{tip}</label>
                                    </div>
                                ))
                            }

                        </div>
                        {
                            otherTip ?
                                <input type="text" className="form-control mt-2" id="otherTip" placeholder='Other tip...' required
                                    onChange={(e) => setTip(e.target.value)} />
                                : ''
                        }
                    </div> : ''
            }
            {
                (rideServiceType === 'Commute (city to city ride)' || rideServiceType === 'Hire a personal driver') ?
                    <div className="mb-3">
                        <label htmlFor="roundtrip" className="form-label fs-6 mb-2">Is it a round trip or one way?</label>
                        <div className="d-flex flex-wrap g-2">
                            {
                                RoundTrip.map((trip) => (
                                    <div className="form-check me-3" key={trip}>
                                        <input className="form-check-input" type="radio" name="radioGroupRoundTrip" id={trip} required
                                            onClick={() => handleRoundTrip(trip)} />
                                        <label className="form-check-label" htmlFor={trip}>{trip}</label>
                                    </div>
                                ))
                            }

                        </div>
                        {
                            otherRoundTrip ?
                                <input type="text" className="form-control mt-2" id="otherRoundTrip" placeholder='Other round trip...' required
                                    onChange={(e) => setRoundTrip(e.target.value)} />
                                : ''
                        }
                    </div> : ''
            }

            {
                (rideServiceType === 'Commute (city to city ride)' || rideServiceType === 'Within city taxi services') ?
                    <div className="mb-3">
                        <label htmlFor="waitingTime" className="form-label fs-6 mb-2">Any waiting time on this trip?</label>
                        <div className="d-flex flex-wrap g-2">
                            {
                                WaitingTime.map((wait) => (
                                    <div className="form-check me-3" key={wait}>
                                        <input className="form-check-input" type="radio" name="radioGroupWaitingTime" id={wait} required
                                            onClick={() => handleWaitingTime(wait)} />
                                        <label className="form-check-label" htmlFor={wait}>{wait}</label>
                                    </div>
                                ))
                            }

                        </div>
                        {
                            otherWaitingTime ?
                                <input type="text" className="form-control mt-2" id="otherWaitingTime" placeholder='Other waiting time...' required
                                    onChange={(e) => setWaitingTime(e.target.value)} />
                                : ''
                        }
                    </div>
                    : ''
            }

            <div className="mb-3">
                <label htmlFor="choosePayment" className="form-label fs-6 mb-2">What is your payment method for this service?</label>
                <div className="d-flex flex-wrap g-2">
                    {
                        PaymentMethod.map((pay) => (
                            <div className="form-check me-3" key={pay}>
                                <input className="form-check-input" type="radio" name="radioGroupPay" id={pay} required
                                    onClick={() => handlePaymentMethod(pay)} />
                                <label className="form-check-label" htmlFor={pay}>{pay}</label>
                            </div>
                        ))
                    }

                </div>
                {
                    otherPaymentMethod ?
                        <input type="text" className="form-control mt-2" id="otherPayment" placeholder='Other payment method...' required
                            onChange={(e) => setPaymentMethod(e.target.value)} />
                        : ''
                }
            </div>
            <div className="mb-3">
                <label htmlFor="choosePassenger" className="form-label fs-6 mb-2">What is the best way to contact you?</label>
                <div className="d-flex flex-wrap g-2">
                    <div className="form-check me-3">
                        <input className="form-check-input" type="radio" name="radioGroupContact" id="contact_call" required
                            onClick={() => handleContactMode('Phone call')} />
                        <label className="form-check-label" htmlFor="contact_call">Phone call</label>
                    </div>
                    <div className="form-check me-3">
                        <input className="form-check-input" type="radio" name="radioGroupContact" id="contact_email"
                            onClick={() => handleContactMode('Email')} />
                        <label className="form-check-label" htmlFor="contact_email">Email</label>
                    </div>
                    <div className="form-check me-3">
                        <input className="form-check-input" type="radio" name="radioGroupContact" id="contact_text"
                            onClick={() => handleContactMode('Text Message')} />
                        <label className="form-check-label" htmlFor="contact_text">Text Message</label>
                    </div>
                    <div className="form-check me-3">
                        <input className="form-check-input" type="radio" name="radioGroupContact" id="contact_other"
                            onClick={() => handleContactMode('other')} />
                        <label className="form-check-label" htmlFor="contact_other">Other</label>
                    </div>
                </div>
                {
                    otherContactMode ?
                        <input type="text" className="form-control mt-2" id="otherContactMode" placeholder='Other contact...' required
                            onChange={(e) => setContactMode(e.target.value)} />
                        : ''
                }
            </div>
            <div className="mb-3">
                <label htmlFor="pickupLocation" className="form-label fs-6 mb-2">Pickup Location</label>
                <Autocomplete
                    className="form-control"
                    placeholder='Please enter pickup location...'
                    onPlaceSelected={(e) => handleLocationSelection(e, 'pickup')}
                    options={{
                        fields: ["formatted_address", "geometry", "name"],
                        strictBounds: false,
                        types: [],
                    }}
                />
            </div>
            <div className="mb-3">
                <label htmlFor="DropLocation" className="form-label fs-6 mb-2">Drop Off Location</label>
                <Autocomplete
                    className="form-control"
                    placeholder='Please enter dropoff location...'
                    onPlaceSelected={(e) => handleLocationSelection(e, 'dropOff')}
                    options={{
                        fields: ["formatted_address", "geometry", "name"],
                        strictBounds: false,
                        types: [],
                    }}
                />
            </div>
            <div className="mb-3">
                <label htmlFor="pickupDate" className="form-label fs-6 mb-2">Pickup Date</label>
                <div className='date-picker-wrapper'>
                    <DatePicker showIcon className="form-control w-100" id="appointmentDate" selected={pickUpDate}
                        onChange={(value) => setPickUpDate(value)} minDate={new Date()}
                    />
                </div>
            </div>
            <div className="mb-3">
                <label htmlFor="pickupTime" className="form-label fs-6 mb-2">Pickup Time</label>
                <input type="time" id="pickupTime" className="form-control" min="09:00" max="18:00" required
                    onChange={(e) => handleTimeChange(e)} />
            </div>
            <div className="mb-4">
                <label htmlFor="otherInfo" className="form-label fs-6 mb-2">Any other information</label>
                <textarea className="form-control" id="otherInfo" rows="4" placeholder='Write your description here...' required
                    onChange={(e) => setOtherInfo(e.target.value)}></textarea>
            </div>
            <div className="btn-wrapper">
                <button type="submit" className="w-100 btn btn-primary" onClick={(e) => handleSubmit(e)}>Submit</button>
            </div>
            {props.loading ?
                <div className='position-fixed top-0 start-0 bottom-0 end-0 d-flex align-items-center justify-content-center w-100 h-100 bg-black bg-opacity-50'
                    style={{ zIndex: 1030 }}>
                    <ReactLoading type={'spin'} color={'#fff'} height={64} width={64} />
                </div>
                : ''
            }
        </div>
    )
}

const mapPropsToState = (state) => {
    return {
        loading: state.business.loading,
        error: state.business.error,
        success: state.business.success,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        clickPerCount: (data, toast, navigate) => dispatch(actions.clickPerCount(data, toast, navigate)),
    }
}

export default connect(
    mapPropsToState,
    mapDispatchToProps
)(withSnackbar(RideTaxi))